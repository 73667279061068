/*Search Styles*/

/* Suggestions */
#searchForm {
    position: relative;
}
@media (min-width: @desktop-min) {

    #header-search-bar {
        display: flex;
        justify-content: flex-end;
        padding-left: 20px;
        position: relative;
        width: 180px;

        
        input[type=text] {
            border: unset;
            padding-right: 0px;
            width: 74px;
            
            &:focus-visible {
                border: 1px solid #dfdfdf;
                outline: unset;
            }
        }
        .search-submit {
            position: absolute;
            left: 111px;

            &:focus-visible {
                outline: 1px dotted #dfdfdf;
            }
        }

        &.hover,
        &.active {
            justify-content: flex-start;

            input[type=text],
            input[type=text]:focus-visible,
            input[type=text]:active {
                border: 1px solid #dfdfdf;
                width: 100%;
            }
            .search-submit {
                position: absolute;
                left: auto;
                right: 5px;
            }
        }


        .tablet({
            position: relative;
            width: 137px;
            
            &:before {
                right: 17px;
            }
            input[type=submit] {
                right: 10px;
            }
        });
    }

}

#search-suggestion-container {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    border: 1px solid #555;
    background: #fff;
    z-index: 1000;
    opacity: 0;
    height: 0;
    transition: opacity .7s, height .2s;
    line-height: 1.5;
    overflow: auto;
    padding-top: 10px;

    &.open {
        opacity: 1;
        height: 93%;
    }
    a {
        display: block;
        color: #333;
        text-decoration: none;
        line-height: 1.4;
        padding: .4em 0 .4em 5px;
    }
    h4 {
        margin: 2px 0 5px;
    }
    section {
        padding: 10px;
        border-bottom: 1px solid rgb(190, 190, 190);
    }
    section:last-of-type {
        border-bottom: none;
    }
    #close-suggestions {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;
        font-size: 25px;
        text-align: center;
    }
    #phrase-suggest-container,
    #category-suggest-container {
        a {
            width: 80%;
        }
    }
    #product-suggest-container {
        a {
            line-height: 1.5;
            margin: 0 0 5px 0;
            padding: 0 0 0 5px;
        }
        .product-suggestion {
            display: grid;
            grid-template-columns: 75px auto;
        }
        .product-image img {
            display: block;
        }
        .product-details {
            align-content: center;
            display: inline-grid;
            padding-left: 10px;
        }
        .product-price {
            .price-standard {
                text-decoration: line-through;
            }
            .price-sales {
                color: @red;
            }
        }
    }

    

    .desktop({
        position: absolute;
        top: 28px;
        left: 20px;
        width: auto;
        height: auto;
        line-height: normal;
        padding-top: 0;
        width: 320px;
        
        &.open {
            height: auto;
        }
        a {
            padding-left: 5px;
            &:hover {
                font-weight: bold;
            }
        }
        #close-suggestions {
            display: none;
        }
    });

}
