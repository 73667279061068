/*PDP Styles*/
.promotional-callout-pdp {
	float: left;
	width: 100%;
	margin: 0;
}
.pdp-main {
    .pdp-quote {
        .pdp-quote-content {
            padding: 0 50px;
        }
    }
    
    .product-detail {
        .product-add-to-cart {
            .pdp-dimensions-label {
                display: none;
                
                .label {
                    padding-top: 0;
                }
            }
        }
    }
    #BVReviewsContainer {
        width: 1000px;

        .BVDI_QT {
            background: url('../images/pdp-white-divider.png') no-repeat left bottom;
            background-size: 92% 12px;
            background-position-x: 50%;
            margin: 0;
        }
        .BVRRDisplayContentReview {
            background-position: bottom;
        }
        .BVRRPageBasedPager {
            background: none;
        }
        .BVRRRatingsOnlySummary {
            background-color: transparent;
        }
    }
}


.product-details-accordion {
    margin-block-start: 2em;

    details {
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
        margin-block-start: -1px;
        padding-block: 1em;

        summary {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: 1rem;
            font-weight: normal;
            justify-content: space-between;

            &::-webkit-details-marker {
                display: none;
            }

            &:after {
                content: "+";
                font-size: 130%;
                font-weight: bold;
                margin-right: .5em;
            }

        }

        &[open] {
            summary {
                &:after {
                    content: "-";
                }
            }
        }
    }
}

select.variation-select {
    margin-left: 0;
    width: -moz-fit-content;
    width: fit-content;
}


/* Desktop only */
@media screen and (min-width: @tablet-max) {
    .js .zoomPad,
    .product-thumbnails {
        position: sticky;
        top: 1em;
    }
}


/*Tablet Only*/
@media screen and (min-width: @tablet-min) and (max-width: @tablet-max) {
    .pdp-main {
        padding: 0 10px;

        .pdp-top {
            width: 100%;
        }
        .breadcrumb {
            display: none;
        }
        .product-image-container,
        .product-set {

            .hide-on-web {
                display: none;
            }
        }
        .product-set,
        .product-add-to-cart {
            .inventory {
                width: 50%;
                label {
                    margin-top: 0;
                }
            }
            .pdp-dimensions-label {
                padding-top: 10px;
            }
        }
        .product-set {
            .product-add-to-cart {
                .product-price {
                    width: 25%;
                    text-align: left;

                    div {
                        padding-left: 0;
                        float: none;
                    }
                }
            }
        }
        .product-set-list {
            .product-set-details,
            .block-add-to-cart {
                width: 65%;
                float: right;
                padding: 0 0 0 20px;
            }
            .block-add-to-cart {
                margin-right: 10px;

                label {
                    margin-left: 0;
                }
                .input-text {
                    margin-top: 1em;
                }
            }
        }
        .amazon-wishlist-btn,
        .facebook,
        .twitter,
        .google-plus,
        .pinterestShare {
            img {
                width: 30px;
                height: 30px;
            }
        }
        .amazon-wishlist-btn {
            a {
                text-align: right;

                img {
                    margin-top: 4px;
                }
            }
        }
        .pdp-row-third,
        .pdp-row-fourth {
            background-position: 50% 0;
        }
        .pdp-row-first {
            .pdp-column-right {
                width: 48%;

                .content-asset {
                    div {
                        width: 320px;
                        float: right;

                        div {
                            padding: 0 20px !important; // override inline css in all product content assets
                            width: 250px;
                        }
                        img {
                            width: 35px;
                        }
                        p {
                            width: 250px;
                        }
                    }
                }
            }
            .pdp-slider {
                .slide {
                    img {
                        width: 100%;
                        height: auto;
                        padding: 0;
                    }
                }
            }
        }
        
        .pdp-row-third {
            padding-bottom: 30px;

            .pdp-column-right,
            .pdp-column-left {
                width: 50%;
                box-sizing: border-box;
            }
            .pdp-column-right {
                .recommendations {
                    width: 100%;
                    padding-left: 10px;
                    box-sizing: border-box;

                    #recommendations-carousel {
                        .product-tile {
                            width: 104px;
                            padding: 0 15px 0 0;

                            &:last-child {
                                padding: 0;
                            }
                        }
                    }
                }
            }
            .pdp-column-left {
                padding-right: 10px;
                img {
                    width: 100%;
                }
            }
        }
        #BVReviewsContainer {
            width: 93vw;
        }
    }
}

/*Tablet Down*/
@media (max-width: @tablet-max) {
    .pdp-main {
        .product-detail {
            .product-set,
            .product-add-to-cart {
                .inventory {
                    padding-top: 10px;
                    label {
                        margin: 0 0.25em 0 0;
                    }
                }
                .pdp-dimensions-label {
                    padding-left: 0;
                    width: 100%;
                }
            }
        }
    }
}

/*Mobile Only*/
@media (max-width: @mobile-max) {
    .pdp-main {
        #product-content,
        .product-primary-image {
            padding: 0;

            h1.product-name {
                margin-left: 0;
            }
        }
        .product-col-2 {
            #product-content {
                padding: 0 10px;
            }
        }
        .product-detail {
            .swatches {
                li {
                    a {
                        margin-left: 0;
                        margin-right: 8px;
                    }
                }
            }
            .product-add-to-cart {
                margin-top: 20px;

                .product-variations {
                    padding-top: 0;

                    .attribute {
                        margin: 0;
                    }

                    .value {
                        width: auto;
                    }
                }
                .pdp-dimensions-label {
                    width: 100%;
                }
                .pdp-dimensions-label,
                .inventory,
                .product-number,
                .availability {
                    padding-top: 14px;
                    margin-top: 0;
                }
                .product-price {
                    font-size: 1.4em;
                    padding: 40px 0px 10px 0;
                    text-align: center;
                }
            }
        }
        .product-add-to-cart,
        .product-set-item {
            span.label,
            .attribute label,
            .inventory label {
                width: 100%;
                display: block;
                margin: 20px 0 12px;
                padding: 0;
                font-weight: bold;
                text-transform: uppercase;
            }
            .inventory {
                input {
                    margin-left: 0;
                    float: left;
                }
            }
            .inventory label {
                width: fit-content;
                margin-right: 1.5em;
            }
        }
        .pricing-button-block {

            .add-to-cart {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .BVRRRatingSummaryNoReviews {
            padding: 0 !important; // override Bazaarvoice styles
        }
        .pdp-top {
            width: 100%;

            .product-description {
                display: inline-block;
                padding-top: 20px;
            }
            .product-description,
            .promotion,
            .attribute .label,
            .attribute label,
            .pdp-dimensions-label,
            .product-add-to-cart .inventory label,
            .product-add-to-cart .availability,
            .product-number {
                font-size: 14px;
            }
            .attribute label {
                margin-left: 0;
            }
            .BVRROverallRatingContainer {
                float: left;
                width: 100% !important; // override Bazaarvoice CSS
            }
            .BVRRRatingSummaryLinks {
                padding-left: 0;
            }
            .product-actions {
                padding: 20px 0 25px 0;
                text-align: center;

                .lists {
                    display: flex;
                    margin: 10px auto 0 auto;

                    li {
                        float: left;
                        margin-right: 10px;
                        line-height: 3em;

                        a {
                            font-size: 12px;
                        }
                        &:last-child {
                            margin-right: 0;
                            margin-left: 10px;
                        }
                    }
                }
                .social-actions {
                    margin: 15px auto 10px auto;
                    float: none;
                    width: 210px;

                    .amazon-wishlist-btn,
                    .facebook,
                    .twitter,
                    .google-plus,
                    .pinterestShare {
                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                        }
                    }
                    .pinterestShare {
                        img {
                            margin-right: 0;
                        }
                    }
                    .amazon-wishlist-btn {
                        a {
                            text-align: right;

                            img {
                                margin-top: 4px;
                            }
                        }
                    }
                }
            }
        }
        .product-set {
            .product-set-list {
                margin: 0;

                .attribute {
                    margin: 0;

                    label {
                        padding-top: 0;
                    }
                }
                .product-set-item {
                    background: url("../images/pdp-white-divider.png") no-repeat;
                    background-size: 100%;
                    padding-bottom: 1em;
                }
                .product-set-image {
                    position: relative;
                    width: 100%;

                    img {
                        max-width: none;
                    }
                }
                .product-set-details {
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    .product-name {
                        margin-left: 0;
                        margin-top: 10px;
                        padding-bottom: 0;
                        width: 100%;
                    }
                    .product-variations {
                        padding-top: 15px;
                    }
                }
                .block-add-to-cart {
                     .inventory {
                         label {
                             font-size: 14px;
                         }
                     }
                     .inventory,
                     .product-number,
                     .availability {
                         padding-left: 0;
                         margin-left: 0;
                     }
                }
                .block-add-to-cart {
                    .product-price {
                        padding-top: 20px;
                        padding-bottom: 15px;
                        font-size: 1.4em;
                    }
                    .button-price-box {
                        float: none;
                    }
                    button.button-product-set-single {
                        float: none;
                        display: block;
                        margin: 0 auto;
                    }
                }
                .wlar-block {
                    display: inline-block;
                    margin-top: 10px;
                    margin-left: 0;
                    text-align: center;

                    .lists {
                        display: inline;
                        li {
                            padding-left: 0;
                            text-align: left;
                            margin-right: 20px;

                            a {
                                font-size: 12px;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                padding-bottom: 30px;

                .product-price {
                    font-size: 1.4em;
                    margin-top: 30px;
                    margin-bottom: 10px;

                    .standardprice,
                    .salesprice {
                        float: none;
                        display: inline-block;
                        width: auto;
                    }
                    .standardprice {
                        margin-right: 20px;
                    }
                }
            }
        }
        
        .pdp-row-fourth:visible {
            background: url("../images/pdp-white-divider.png") no-repeat;
            background-size: 85%;
            background-position-x: 50%;
            margin-bottom: 30px;
        }
        #BVReviewsContainer {
            width: 85vw;
            margin-left: auto;
            margin-right: auto;
        }
    }
}


@media (max-width: 380px) {
    .pdp-main .product-detail .swatches li a {
        font-size: 12px;
    }
}

.pdp-top .product-col-1.product-image-container {
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: @tablet-max) {
        flex-direction: column;
        .product-thumbnails {
            ul {
                display: flex;
                padding-top: 10px;
            }
        }
    }
}

.pdp-top .product-col-1.product-set {
    display: flex;
    flex-direction: row-reverse;
    max-height: 470px;
    @media (max-width: @tablet-max) {
        flex-direction: column;
        max-height: 100%;
    }
}

.product-description-image img {
    max-width: 100%;
}

.pdp-main .attribute .label.size {
    text-transform: capitalize;
    width: 100%;
    padding: 6px 0 6px 0;
}

.pdp-main .attribute .value.size {
    width: 100%;
}

.pdp-main .attribute .pdp-dimensions-label {
    width: 100%;
}

.pdp-main #product-content {
    height: 100%;
    position: relative;
}
