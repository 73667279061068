@media screen and (min-width: @desktop-min) {
    #pdpMain .pdp-top .product-col-1 .product-primary-image .hide-on-web {
        display: none;
    }
}
@media screen and (min-width: 1010px) {
    .pt_storefront #comfortguarantee-mobile {
        position: absolute;
        bottom: 80px;
        left: 345px;
        color: #ACACAC;
        text-align: center;
        font: bold 30pt 'Arrow';
    }
    .pt_storefront #learnMoreComfort {
        position: absolute;
        bottom: 50px;
        left: 505px;
        color: #ACACAC;
        font-size: 24px;
    }
    .pt_storefront #learnMoreComfort a {
        color: #ACACAC;
    }
    .pt_storefront #freeShippingText-mobile {
        color: #C2CEE4;
        font: bold 40pt 'Arrow';
        letter-spacing: 2px;
        position: absolute;
        left: 105px;
        top: 10px;
        text-transform: uppercase;
    }
    .pt_storefront #ordersFreeShipping-mobile {
        color: #C2CEE4;
        font: 17pt regular century-gothic;
        letter-spacing: 2px;
        position: absolute;
        left: 550px;
        top: 10px;
    }
    .pt_storefront #learnMoreFreeShipping-mobile {
        color: #C2CEE4;
        letter-spacing: 2px;
        position: absolute;
        left: 550px;
        top: 40px;
        font-size: 20px;
    }
    .pt_storefront #learnMoreFreeShipping-mobile a {
        color: #C2CEE4;
    }
    .pt_storefront #learnMoreFreeShipping-mobile a:hover {
        text-decoration: underline;
    }
    .pt_storefront #downAroundText-mobile {
        top: 10px;
        left: 110px;
        font: bold 40pt 'Arrow';
        color: #C2CEE4;
        position: absolute;
    }
    .pt_storefront #trademarkSymbol-mobile {
        top: 10px;
        left: 425px;
        font: bold 20pt 'Arrow';
        color: #C2CEE4;
        position: absolute;
    }
    .pt_storefront #pillowText-mobile {
        top: 10px;
        left: 460px;
        font: bold 40pt 'Arrow';
        color: #C2CEE4;
        position: absolute;
    }
    #shopNowImage-mobile {
        top: 20px;
        left: 650px;
        position: absolute;
    }
}
@media screen and (min-width: @mobile-max) and (max-device-width: @desktop-min) and (orientation: landscape) {
    #homepage-slider-container img {
        max-width: none;
        width: auto;
        display: block;
    }
    .pt_product-search-result .mobile-cgp-header {
        text-align: center;
    }
    .wishlist-page img.contentframeImage {
        width: 125%;
        height: auto;
        top: -12px;
        margin: 0 auto;
    }
    .pt_wish-list .find-fieldset .input-text {
        width: 70% !important;
    }
    .pt_wish-list .wishlist-page .item-list {
        width: 90%;
    }
    .pt_wish-list .wishlist-page table.item-list .item-details {
        width: 30%;
    }
    .pt_wish-list .wishlist-page table.item-list .item-availability {
        width: auto;
    }
    .pt_wish-list .wishlist-page table.item-list td.item-dashboard {
        width: 40%;
        float: left;
    }
    .wishlist-page .item-list .item-add-to-cart {
        width: 100%;
        margin: 10px 0px;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container {
        width: 350px;
        float: right;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container .quantity {
        width: 30%;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container .submit {
        padding-top: 0px !important;
    }
    .pt_wish-list .find-fieldset .input-text {
        width: 60%;
    }
    .pt_wish-list .wishlist-page .sku {
        margin-top: 0px;
    }
    .wishlist-page table.item-list .item-dashboard {
        width: 58%;
    }
    .pt_gift-registry .registry-page-header .contentframeImage {
        height: auto;
        top: -12px;
    }
    .pt_gift-registry .registry-main .item-container .item-availability {
        width: 10% !important;
    }
    .pt_gift-registry .registry-main .item-container .item-dashboard {
        width: 25% !important;
        float: right !important;
    }
    .pt_gift-registry .registry-main .item-container td.item-details {
        width: 35%;
    }
    .pt_gift-registry .registry-main .item-container .item-add-to-cart {
        width: 100%;
    }
    .pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity {
        width: 40% !important;
        float: left;
    }
    .pt_gift-registry .registry-main .item-container .item-add-to-cart .option-add-to-cart {
        width: 40%;
    }
    .pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity label {
        margin-top: 0px;
        margin-right: 5px !important;
    }
    .pt_gift-registry .registry-main .item-availability .notavailable {
        white-space: pre-line;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD span.error {
        margin-left: 2%;
    }
    .pt_checkout .checkout-billing .payment-method .ccnumber .form-caption.error-message {
        margin-left: 2%;
    }
    .pt_checkout span.error {
        margin-left: 2%;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error {
        margin-left: 2%;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error-message {
        margin-left: 2%;
    }
}
.mobileMenu-Homepage ul li a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
}
@media screen and (max-width: @tablet-max) {
    /* Adjust the forms to be more touch-friendly */
    /* ------------------------------------- */

    input[type="text"],
    input[type="password"],
    textarea,
    select {
        width: 90%;
    }
    .form-row-button {
        margin-left: 0;
    }
    .cvn span.error {
        position: static;
        margin-left: 34%;
    }
    #searchForm {
        padding: 0px;
    }
    #searchForm #searchFormMobile .leftNav-toggleButton {
        display: block;
    }
    #searchForm #searchFormMobile #contactUsButton {
        display: block;
    }
    #searchForm #searchFormMobile #miniCartButton {
        display: block;
    }
    #searchForm #searchFormMobile #mini-cart {
        display: block;
        position: absolute;
        top: 8px;
        right: 0;
        width: 66px;
        text-align: center;
    }
    #mini-cart .mini-cart-quantity {
        font: bold 20px century-gothic;
        color: #676767;
    }
    #searchForm #searchFormMobile #mini-cart .mini-cart-empty {
        font: bold 20px century-gothic;
        color: #676767;
        display: none;
    }
    #searchForm #searchFormMobile #mini-cart .mini-cart-label {
        display: none;
    }
    .leftNav-toggleButton .login-box-content label,
    label,
    .form-caption,
    .form-label-text {
        margin: 10px 12px;
    }
    .login-box-content label,
    label,
    .form-caption,
    .form-label-text {
        float: none;
        margin: 2% 0;
        text-align: left;
        width: auto;
    }
    .login-box-content input[type="text"],
    .login-box-content input[type="password"],
    .form-inline .form-row {
        width: 90%;
    }
    .form-inline label {
        padding: 0;
    }
    .form-inline button {
        width: 50%;
    }
    .form-indent {
        margin-left: 2%;
    }
    legend {
        padding: 14px 30% 10px 2%;
        width: 68%;
    }
    .label-inline input {
        height: auto;
        margin: 0;
        padding: 0;
    }
    .label-inline label {
        margin: 0;
        padding: .5em 0 0 1em;
    }
    .form-field-tooltip {
        display: none;
    }
    .page-wrapper {
        min-width: 0px;
    }
    /* Header elements need to be re-positioned */
    /* ------------------------------------- */

    #top-navWrapper {
        display: none;
        &.checkout {
        	display: block;
        }
    }
    #header {
        height: auto;
        padding: 1% 1% 30px;
    }
    #header #gray-bar {
        height: 30px;
        background-color: #868686;
        color: white;
	}
    #header .primary-logo {
        float: right;
    }
    #header .primary-logo img {
        max-width: 100%;
    }
    #header .menu-utility,
    .menu-utility-user {
        clear: both;
        float: none;
        letter-spacing: 0;
        margin: 10px 0;
    }
    #header .menu-utility li,
    .menu-utility-user li {
        padding: 0 5px;
        color: white;
    }
    .header-banner {
        float: none;
        font-size: .8em;
        margin: 10px 5px;
    }
    #searchFormMobile {
        display: block;
        height: 50px;
        line-height: 50px;
        background-color: var(--color-accent);
        box-shadow: 0 8px 6px -6px black
    }
    #searchForm {
        padding: 0px;
    }
    #searchForm #searchFormMobile .leftNav-toggleButton {
        display: block;
    }
    #searchForm #searchFormMobile #contactUsButton {
        display: block;
    }
    #searchForm #searchFormMobile #miniCartButton {
        display: block;
    }
    .mini-cart-products {
        max-height: 100%;
    }
    .mini-cart-content {
        max-width: 220px;
        position: absolute;
        top: 0;
        line-height: normal;
        text-align: left;
        z-index: 100;
    }
    .mini-cart-label {
        margin-right: 0;
    }
    .mini-cart-total,
    .mini-cart-extender {
        display: none;
    }
    .header-search {
        left: 0;
        width: 100%;
    }
    .header-search fieldset {
        padding-right: 20px;
    }
    .header-search input[type="text"] {
        background-color: transparent;
        border-left: 0;
        border: none;
        box-sizing: border-box;
        color: @white;
        display: block;
        float: left;
        font-family: 'century-gothic';
        font-size: 21px;
        height: auto;
        left: 50px;
        padding: 12px 32px 10px 17px;
        position: static;
        top: 0px;
        width: 100%;
    }
    .header-search input[type="text"]::placeholder,
    .header-search input[type="text"]:focus::placeholder {
        color: @white;
    }
    .header-search input[type="submit"] {
        height: 37px;
        width: 40px;
        display: block;
        background: none;
    }
    #navigation #leftNav,
    #navigation #rightNav {
        display: none;
    }
    #navigation .line {
        left: 0;
        width: 100%;
        margin-left: 0;
        background-size: 100%;
        top: 135px;
    }
    .js #suggestions {
        background-color: #919294;
        width: 100% !important;
        color: #e0e0e0;
        cursor: pointer;
        padding: 0;
        display: none;
        position: absolute;
        z-index: 8184;
        font-size: 1.2em;
    }
    .js #suggestions div {
        clear: both;
        height: 45px;
        padding: 5px;
        overflow: hidden;
        white-space: nowrap;
        font-size: 25px;
    }
    .js #suggestions div:hover {
        background-color: #cccccc;
        color: #333333;
    }
    .js #suggestions div div.suggestionterm {
        float: left;
        overflow: hidden;
        width: auto;
    }
    .js #suggestions div span.hits {
        float: right;
        line-height: 1.8em;
        text-align: right;
        margin-left: 3px;
    }
    #searchForm {
        padding: 0px;
    }
    #searchForm .leftNav-toggleButton {
        display: block;
        float: left;
        width: 25px;
        padding: 0 10px;
        border-right: 1px solid #d2d2d2;
    }
    #searchForm #searchFormMobile #contactUsButton {
        display: block;
        float: right;
    }
    #searchForm #searchFormMobile #miniCartButton {
        display: grid;
        grid-template-areas: "stack";
        float: right;
        place-content: center;
    }
    #searchForm #searchFormMobile #miniCartButton > * {
        align-self: center;
        grid-area: stack;
        justify-self: center;
    }
    #searchForm #searchFormMobile #miniCartButton .cart-quantity {
        color: var(--color-cta);
        font-weight: bold;
        position: relative;
            top: 3px;
        text-decoration: none;
    }
    #searchForm #searchFormMobile #mini-cart {
        display: block;
        top: -41px;
    }
    .leftNav-toggleButton .login-box-content label,
    label,
    .form-caption,
    .form-label-text {
        margin: 10px 12px;
    }
    #header #header-banner {
        display: none;
    }
    #header .menu-utility {
        display: none;
    }
    .header-search:before {
        display: none;
    }
    .mobile-categories-filter {
        display: block;
        max-width: 130px;
        width: 33%;
        border: 1px solid #AAA;
        float: left;
        margin: 3px 0px 3px 8px;
        padding: 4px 30px 4px 5px;
        position: relative;
    }
    .mobile-categories-filter a,
    .mobile-categories-filter a:hover {
        text-decoration: none;
    }
    .mobile-categories-filter a {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        width: 100%;
    }
    .mobile-categories-filter .arrow {
        position: absolute;
        right: 9px;
        top: 40%;
        border-top: 4px solid #303e48;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }
    #secondary .refinement {
        margin: 0px
    }
    .refinement ~ .refinement,
    .refinement:nth-of-type(1) {
        border-bottom: 1px solid #fff;
        background-image: none;
    }
    #secondary .refinement ul {
        margin: 0 7px;
        padding: 8px 7px 5px
    }
    #secondary .secondaryNavFeather {
        display: none;
    }
    .pt_product-search-result #secondary {
        display: none;
        border: 1px solid;
        float: none;
        font-size: 1.1em;
        position: absolute;
        top: 37%;
        background-color: @white;
        background-image: none;
        width: 88%;
        -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
        z-index: 10;
    }
    .form-filters {
        border: 0px;
        width: auto;
    }
    .search-result-options {
        margin: 0 0 1.7em 0;
        padding-top: 22px;
    }
    .search-result-options .sort-by {
        margin: 0;
        padding: 3px 2px;
    }
    .search-result-options .sort-by select {
        width: 120px;
        margin: 0px;
    }
    .search-result-options .items-per-page {
        display: none;
    }
    .search-result-options .pagination {
        margin: 0;
    }
    .search-result-options .pagination .results-hits {
        display: none;
    }
    #sortingOptionsContainer {
        float: left;
    }
    #sortingOptionsContainer label {
        display: none;
    }
    #checkout-header-main {
        display: block;
    }
    .pt_cart #checkout-header {
        display: none;
	}
    #cart-footer {
        padding: 0 0.6em;
    }
    #cart-footer-mobile {
        display: block;
    }
    #cart-footer-desktop {
        display: none;
    }
    /*To overwrite inline style*/

    .ui-dialog .ui-dialog-content {
        height: auto !important;
        overflow: visible;
    }
    .ui-dialog .ui-dialog-content {
        padding: .25em;
    }
    .content-asset .tooltip-container .tooltip,
    .content-asset .hotspot,
    .categoryLandingContent .hotspot {
        display: none;
    }
}
@media screen and (max-width: @mobile-max) {
    #homepage-slider-container img {
        max-width: none;
        width: auto;
        display: block;
    }
    #homepage-slider div.slide-mobile {
        display: block;
    }
    #homepage-slider div.slide-desktop {
        display: none;
    }
    #homepage-slider p.slide-text-small {
        display: none;
    }
    #homepage-slider p.slide-text-header {
        font-size: 5.36em;
    }
    #homepage-slider p.slide-text-large {
        font-size: 4.29em;
        padding: 0;
        padding-left: 0.2em;
    }
    #homepage-slider p.slide-text-medium {
        font-size: 3.93em;
    }
    #homepage-slider div.slide-align-left img {
        margin-left: -25%;
    }
    #homepage-slider div.slide-align-center img {
        margin-left: -34.645%;
    }
    #homepage-slider div.slide-align-right img {
        margin-left: -40%;
    }
    #homepage-slider div.slide-align-right div.slide-text-white,
    #homepage-slider > div div.slide-align-right div.slide-text-gray {
        right: 1.7%;
        top: 5%;
    }
    #homepage-slider div.slide-align-right p.slide-text-medium {
        text-align: right;
        margin-left: 25%;
    }
    #homepage-slider div.slide-align-right p.slide-text-header,
    #homepage-slider > div div.slide-align-right p.slide-text-large {
        display: inline-block;
    }
    #homepage-slider div.slide-align-left div.slide-text-white,
    #homepage-slider > div div.slide-align-left div.slide-text-gray {
        left: 1.7%;
        top: 5%;
    }
    #homepage-slider div.slide-align-left p.slide-text-header,
    #homepage-slider > div div.slide-align-left p.slide-text-large {
        display: inline-block;
    }
    #homepage-slider div.slide-align-center div.slide-text-white,
    #homepage-slider > div div.slide-align-center div.slide-text-gray {
        left: 15%;
        top: 20%;
    }
    #homepage-slider div.slide-align-center a.button-base {
        margin: 0;
    }
    a.heroPrev,
    a.heroNext {
        display: none;
    }
    .caroufredsel_wrapper {
        max-width: none;
    }
    .pt_gift-registry .require-dialog {
        top: 0;
        left: 0;
        position: relative;
    }
    .pt_gift-registry .event-info .form-row-button {
        width: 36%;
        margin-left: 0;
        float: right;
    }
    .pt_gift-registry#wrapper {
        background: url("../images/gray-bg.png");
        background-size: contain;
    }
    .pt_gift-registry #main {
        background: none;
        width: initial;
    }
    .pt_gift-registry .registry-page-header .contentframeImage {
        width: 130%;
    }
    .pt_gift-registry .primary-content h1 {
        border-bottom: 0px;
        padding-bottom: 0px;
    }
    .dialog-required {
        margin-right: 14%;
    }
    #navigation {
        background: none;
    }
    /* Stop mobile font-size adjustments */
    /* ------------------------------------- */

    body {
        -webkit-text-size-adjust: none;
    }
    /* Ensure no content extends beyond page wrapper */
    /* ------------------------------------- */

    div,
    img {
        max-width: 100%
    }
    /* Display/Hide stuff for mobile */
    /* ------------------------------------- */

    .mobile-hide {
        display: none;
    }
    .mobile-show {
        display: block;
    }
    .mobile {
        display: block;
    }
    /* Emphasize some styles that get overridden due to specificity */

    .pdp-main .pdp-column-left.mobile-hide {
        display: none;
    }
    /* Primary page regions: remove padding, update width */
    /* ------------------------------------- */

    .print-page,
    .size-chart-link,
    a.tooltip,
    #tooltip {
        display: none !important;
    }
    .tooltip {
        font-size: .9em;
    }
    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }
    #wrapper,
    #wrapper.pt_cart,
    #wrapper.pt_checkout,
    #wrapper.pt_order-confirmation {
        border: 0;
        font-size: 14px;
        max-width: 100%;
        min-width: 320px;
        width: 100%;
        margin-top: 0;
    }
    #wrapper.pt_cart {
    	margin-top: 57px;
    }
    #wrapper.pt_order {
        margin-top: 0;
    }
    #wrapper.pt_order #main {
        margin-top: 80px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .order-history-table span.email {
        word-break: break-all;
    }
    #wrapper.pt_cart {
        font-size: 20px;
    }
    #main {
        padding: 0;
    }
    #wrapper.pt_storefront #main {
        padding: 5px 1.7% 0 1.7%;
    }
    .pdp-main .pdp-top {
        margin: 0 0 0 -6%;
        padding: 0 6%;
        background-image: none;
    }
    .pdp-main .product-set-list .block-add-to-cart {
        width: 100%;
    }
    .pdp-main .product-set-list label {
        float: left;
        letter-spacing: 1px;
    }
    #primary {
        background: none;
        border: 0;
        float: none;
        clear: both;
        margin: 0;
        width: auto;
        padding: 0;
    }
    #secondary {
        display: none;
        border: 1px solid;
        float: none;
        font-size: 1.1em;
        width: 80%;
        position: absolute;
        top: 325px;
        background-color: @white;
    }
    .pt_product-search-result #primary {
        padding-top: 0;
    }
    .pdp-main .product-set .product-primary-image-frame {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: none;
        margin: 3px;
    }
    .pt_customer-service #main,
    .pt_blog #main {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .pt_customer-service #main {
        padding-top: 0;
    }
    .pt_customer-service #main .breadcrumb,
    .pt_blog #main .breadcrumb {
        padding-top: 0;
    }
    .pt_customer-service #secondary,
    .pt_blog #secondary {
        top: 55px;
        background-color: @white;
        padding: 1em;
    }
    .pt_customer-service .mobile-contentnav,
    .pt_blog .mobile-contentnav {
        display: block;
        cursor: pointer;
        float: left;
    }
    .pt_customer-service #secondary .secondary-navigation h3 {
        margin-top: 0;
    }
    .pt_customer-service #secondary .secondary-navigation ul.left-navigation li {
        margin-left: 1em;
    }
    .pt_customer-service #main {
        width: initial;
        padding-right: 5%;
    }
    .pt_blog .left-navigation {
        display: blog;
    }
    .primary-focus #secondary {
        display: none !important;
    }
    .centerContent {
        width: 100% !important;
    }
    .col-1,
    .col-2 {
        float: none;
        width: 100%;
    }
    .scrollable {
        height: auto;
    }
    .scrollable li {
        float: left;
    }
    .scrollable li a {
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        display: block;
        padding: .2em .8em;
    }
    .search-results-content ol.folder-content-list li {
        width: 100%;
    }
    .search-results-content ol.folder-content-list li:nth-child(even) {
        clear: left;
        float: left;
    }
    .product-tile .product-description {
        margin: 0px;
    }
    .page-wrapper {
        overflow: hidden;
    }
    .contentframeImage {
        z-index: 0;
    }
    .slot-grid-header .contentframeImage {
        z-index: 0;
        left: -25%;
        max-width: 130% !important;
    }
    .wishlist-page .contentframeImage,
    .registry-page-header .contentframeImage {
        left: -25%;
        width: 130%;
    }
    .pt_account #myaccount-content-slot .contentframeImage {
        width: 130%;
        margin-top: 0;
        left: -25%;
        top: -14px;
    }
    .pt_account .primary-content h1 {
        margin-top: 0px;
        padding-top: 0px;
        font-size: 26px;
        max-width: 90%;
    }
    /* blog */

    .pt_blog#wrapper {
        background-size: contain;
        padding-top: 0px;
    }
    .pt_blog #main {
        width: initial;
    }
    .blog-landing-assets .container .text {
        margin-left: 0;
        padding-top: 20px;
    }
    /* Navigation adjusted, stacked so it's easier to use */
    /* ------------------------------------- */

    #navigation {
        width: 100%;
        background-position: center 0px;
    }
    #navigationSpacing {
        padding-top: 105px;
        width: 100%;
    }
    #navigationSpacing #rightNav #mini-cart {
        display: none;
    }
    .body-trigger {
        position: absolute;
        top: 0;
        right: -17.5em;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 1;
    }
    ul.level-1 {
        padding: 5px 0;
    }
    ul.level-1 a {
        font-size: 2em;
    }
    ul.level-1 a span {
        float: right;
        font-size: .8em;
    }
    ul.level-1 .last a.level-1 {
        border: 0;
    }
    ul.level-1 li {
        float: none;
        margin: 0px;
    }
    ul.level-1 li:hover a.level-1 {
        border-color: #efefef;
    }
    .mobileMenu-Homepage {
        display: block;
        margin-top: 40px;
    }
    .mobileMenu-Homepage ul.level-1 {
        padding: 5px 0;
        list-style-type: none;
    }
    .mobileMenu-Homepage ul.level-1 a {
        font-size: 3.75em;
        text-decoration: none;
        font-family: 'century-gothic', sans-serif;
    }
    .mobileMenu-Homepage ul.level-1 a span {
        float: right;
        font-size: .8em;
    }
    .mobileMenu-Homepage ul.level-1 .last a.level-1 {
        border: 0;
    }
    .mobileMenu-Homepage ul.level-1 li {
        border-bottom: 1px solid #999999;
        float: none;
        margin: 0px;
        padding: 8px 4%;
        background: transparent url("../images/homeNavResponsiveArrow.png") no-repeat 96% 50%;
        background-size: 10px;
    }
    .mobileMenu-Homepage ul.level-1 li:hover a.level-1 {
        border-color: #efefef;
    }
    .mobileMenu-Homepage ul.level-1 li:hover a#communityText {
        background-color: #979797;
        font: bold italic 13pt 'calisto';
        color: white;
    }
    .js ul.level-1 li:hover div.level-2 {
        display: none;
    }
    div.level-2 {
        position: relative;
    }
    div.level-2.menu-horizontal li {
        border: 0;
        float: none;
    }
    div.level-2 .custom {
        display: none;
    }
    /* Reduce primary header size */
    /* ------------------------------------- */

    .primary-content #results-products h1 {
        font-size: 1.5em;
        margin: 10px 0;
        padding-top: 25px;
    }
    /* Grid, product tile layout adjustments */
    /* ------------------------------------- */

    .pt_product-search-result .product-view-details {
        display: block;
    }
    #quickviewbutton {
        // display: none !important;
    }
    .product-tile {
        height: auto;
    }
    .wide-tiles .product-tile .product-image {
        width: 41%;
    }
    .wide-tiles .product-tile .product-name,
    .wide-tiles .product-tile .product-pricing,
    .wide-tiles .product-tile .product-promo,
    .wide-tiles .product-tile .pr_stars,
    .wide-tiles .product-tile .product-swatches {
        float: right;
    }
    .wide-tiles .product-tile .product-name h2 {
        margin-top: 0px
    }
    ul.search-result-items {
        margin-right: 0;
    }
    ul.search-result-items .grid-tile {
        width: 100%;
        box-sizing: border-box;
        padding: 3px 0 0;
    }
    .search-result-content ul.search-result-items .grid-tile {
        height: auto;
    }
    ul.search-result-items .product-tile {
        cursor: pointer;
    }
    .product-tile .product-image > a.thumb-link {
        visibility: hidden;
        position: relative;
        height: 100%;
        width: 100%;
    }
    .product-tile .product-image .product-tile-mobile a.thumb-link {
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
    }
    .product-tile .product-image {
        margin: -3px 0 0;
    }
    .product-tile .product-swatches .swatch img {
        height: 2em;
        width: 2em;
    }
    .product-listing-1x4 ul.search-result-items .grid-tile {
        margin: 2%;
        width: 92%;
    }
    .product-compare,
    .compareitems {
        display: none !important;
    }
    .product-tile-hover .product-image .thumb-link img.bottom {
        display: none;
    }
    .back-to-top.container {
        top: auto;
        bottom: 0px;
    }
    .pdp-main .pdp-row-third {
        display: none;
    }
    .pdp-main .pdp-top .product-col-2 .hide-on-mobile {
        display: none;
    }
    /* breadcrumbs */
    /* -------------------------------------- */

    .pt_product-details ol.breadcrumb .last {
        display: none;
    }
    .breadcrumb {
        display: none;
    }
    .pt_customer-service .breadcrumb {
        display: block;
        visibility: hidden;
    }
    /* Signup Hopup */
    /* -------------------------------------- */

    .emailsignup-form {
        padding: 0px;
    }
    .emailsignup-form .form-row label {
        margin: 0px;
        width: 100%;
        text-align: left;
        float: none;
    }
    .emailsignup-form .form-row input[type="text"] {
        width: 65%;
    }
    /* Pdp */
    /* ------------------------------------- */

    .pt_product-details .primary-content {
        padding-bottom: 68px;
    }
    .pt_product-details ol.breadcrumb .last {
        display: none;
    }
    #product-nav-container {
        bottom: 0;
        width: 100%;
    }
    #product-nav-container div {
        width: 60px;
    }
    #product-nav-container div.product-next {
        float: right;
    }
    #product-nav-container .divided span {
        border: 0;
    }
    .pdp-main .product-col-1,
    .pdp-main .product-col-2,
    .pdp-main .product-name {
        clear: both;
        float: none;
        margin: 0;
        width: 100%;
        text-align: left;
    }
    .pdp-main .product-description {
        padding-top: 25px;
        letter-spacing: 1px;
        font-size: 16px;
    }
    .pdp-main .promotion {
        letter-spacing: 1px;
        font-size: 16px;
    }
    .pdp-main .promotion .promotion-title {
        font-size: 86%;
    }
    .pdp-main .attribute h3,
    .pdp-main .attribute .label {
        font-size: 16px;
    }
    .pdp-main .attribute .value {
        width: 64%;
    }
    .pdp-main .attribute span.value {
        padding-top: 5px;
    }
    .pdp-main .pdp-dimensions-label {
        font-size: 16px;
        padding-left: 0px;
    }
    .pdp-main .product-add-to-cart .inventory {
        width: 100%;
    }
    .pdp-main .product-add-to-cart .inventory label {
        display: inline-block;
        margin: 0px;
        font-size: 16px;
    }
    .pdp-main .product-add-to-cart .product-price {
        font-size: 2em;
        padding: 10px 0px;
        text-align: left;
        width: 100%;
    }
    .pdp-main .product-add-to-cart button {
        clear: both;
        float: none;
        padding: 0.6em 1em;
    }
    .pdp-main .product-number {
        float: none;
        font-size: 16px;
        clear: both;
    }
    .pdp-main .product-add-to-cart .availability {
        padding: 15px 0px 0px;
        font-size: 16px;
    }
    .pdp-main .product-actions .lists {
        float: none;
        margin-top: 0;
    }
    .pdp-main .product-actions .lists a {
        font-size: 1.5em;
        white-space: nowrap;
    }
    .pdp-main .product-actions {
        padding: 45px 0px 10px;
    }
    .pdp-main .product-set .product-actions {
        padding: 19px 0px 10px;
    }
    .pdp-main .pdp-row-third,
    .pdp-main .pdp-row-fourth {
        background: transparent;
    }
    .pdp-main .product-actions .social-actions {
        float: left;
    }
    .ui-tabs .ui-tabs-nav li {
        display: block;
        font-size: .8em;
        margin: 0;
        width: 99.4%;
    }
    .ui-tabs .ui-tabs-nav li a {
        width: auto;
    }
    .pdp-main .product-set-list .product-set-image img {
        max-width: 90%;
    }
    .pdp-main .product-set-list .block-add-to-cart .inventory {
        margin: 0 0 0 23%;
        width: 30%;
        font-size: 16px;
    }
    .pdp-main .product-set-list .block-add-to-cart .product-number {
        margin: 0 0 0 23%;
    }
    .pdp-main .product-set-list .block-add-to-cart .availability {
        margin: 0 0 0 23%;
    }
    .pdp-main .product-set-list .block-add-to-cart .product-price {
        font-size: 1em;
        width: inherit;
    }
    .pdp-main .product-set-list label {
        width: initial;
    }
    .pdp-main .product-set .product-add-to-cart .product-price {
        width: 100%;
        float: right;
        text-align: center;
    }
    .pdp-main .product-set .product-add-to-cart button.button-yellow-ps {
        float: right;
    }
    .pdp-main .product-set-list .block-add-to-cart button {
        font-size: .7em;
    }
    .pdp-main .product-review-links:visible {
        margin: 20px 0;
    }
    .pdp-main .prSnippetTitle {
        float: left;
    }
    .pdp-main .prSnippetRatingUnclickable {
        float: left;
    }
    .pdp-main .product-name {
        padding: 30px 0 20px 0;
    }
    .primary-image {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    .pdp-main .product-actions .social-actions {
        margin: 20px 0 10px 0;
    }
    .pdp-main .pdp-row-first {
        display: none;
    }
    .pdp-main .product-set .product-add-to-cart .standardprice {
        padding-left: 25px;
    }
    .pdp-main .product-set .product-add-to-cart .salesprice {
        padding-right: 25px;
    }
    .pdp-main .product-set-list .wlar-block li {
        display: inline;
        padding-left: 5px;
    }
    .pdp-main .product-set-list .wlar-block {
        width: 100%;
        margin-top: 400px;
        margin-left: -30px;
    }
    .button-price-box.desktop {
        display: none;
    }
    /* size chart */
    /* ------------------------------------- */

    .sizinginformation {
        padding: 0;
    }
    .sizinginformation th {
        font-weight: normal;
        padding: 0 0 0 2px;
    }
    .sizinginformation td {
        background: #ffffff;
        padding: 5px;
        text-align: center;
    }
    .sizinginformation .sizechart {
        padding: 0 !important;
    }
    /* Cart: tables need to be broken up and cells stacked */
    /* ------------------------------------- */

    .pt_checkout .squaredTwo {
        width: 30px;
        height: 30px;
    }
    .pt_checkout .squaredTwo label:after {
        font-size: 1em;
        top: 0px;
    }
    .pt_checkout .billing-coupon-code input[type="text"] {
        padding: 0.2em 0.25em;
    }
    .pt_checkout .checkout-shipping .form-row.squaredTwo label,
    .pt_checkout .checkout-billing .form-row.squaredTwo label {
        width: 425px;
        padding-top: 0;
    }
    .pt_checkout .header-separator {
        border-width: 2px;
    }
    .pt_checkout .checkout-billing .privacy-policy {
        margin-top: 0;
        margin-left: 92px;
        padding-top: 10px;
        font-size: 0.4em;
    }
    .pt_cart #main {
        padding: 0 13px;
        background-image: none;
    }
    .pt_cart .breadcrumb {
        display: none;
    }
    .cart-empty {
        padding: 1.75em 0.86em;
    }
    .breadcrumb {
        display: none;
    }
    .pt_cart h1 {
        float: none;
        font-size: 1.43em;
        margin: 0;
    }
    .pt_cart #cart-banner {
        float: none;
        padding: 0 0.86em;
    }
    #cart-table {
        margin-top: 0;
    }
    #cart-table thead {
        display: none;
    }
    #cart-table tbody,
    #cart-table tr {
        border-top: 1px solid #adadad;
    }
    #cart-table td {
        display: block;
        padding: 5px;
    }
    #cart-table td.item-name,
    #cart-table td.item-links,
    #cart-table tr.rowbundle td.item-details {
        padding: 15px 4.0485%;
        font-size: 1em;
    }
    #cart-table tr.rowbundle td.item-details a {
        font-size: 35px;
    }
    #cart-table tr th,
    #cart-table tr td {
        padding-left: 4.0485%;
    }
    #cart-table tr td.item-name {
        display: block;
        padding-bottom: 0;
    }
    #cart-table tr td.item-name .name {
        margin-bottom: 0;
    }
    #cart-table tr th:first-child,
    #cart-table tr td.item-image {
        padding: 4%;
        padding-right: 0;
        width: 20.25%;
    }
    #cart-items-form td.item-name,
    #cart-items-form td.item-links {
        display: block;
    }
    #cart-table td.item-price {
        font-size: 1.4em;
        text-align: right;
    }
    .pt_cart #secondary {
        display: none;
    }
    #cart-table .item-image {
        float: left;
        text-align: center;
        width: 20.25%;
        padding: 0 0 0 3.9%;
    }
    #cart-table .item-image img {
        max-width: 100%;
        height: auto;
        display: block;
        float: left;
        margin: 0 auto;
    }
    #cart-table .item-details,
    #cart-table .item-quantity-details,
    #cart-table .item-price {
        float: right;
        width: 69%;
        font-size: 1em;
        padding: 0 4% 0 0;
        max-width: none;
    }
    #cart-table .item-details {
        padding-top: 4%;
    }
    #cart-table .item-details .product-list-item .name {
        font-size: 1.3em;
        display: block;
    }
    #cart-table .item-details .item-edit-details {
        display: none;
    }
    #cart-table .item-quantity {
        float: left;
        clear: left;
        text-align: center;
        width: 20.25%;
        padding: 0 0 0 3.9%;
        margin-top: 20px;
    }
    #cart-table .item-quantity input {
        text-align: center;
        width: auto;
        border-width: 2px;
        font-size: 45px;
        padding: 0.5em;
        width: 40%;
    }
    #cart-table .item-quantity .item-quantity-label {
        display: inline-block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 35px;
        margin-top: 5px;
        padding: 0.7em 1em;
        width: 20%;
    }
    #cart-table .item-quantity-details .add-to-wishlist,
    #cart-table .item-quantity-details .add-to-registry {
        display: none;
    }
    #cart-table tr.rowbundle .item-quantity {
        font-size: 45px !important;
    }
    #cart-table .item-total {
        font-size: 1.28em;
        text-align: right;
        width: auto;
        clear: both;
        padding: 0 0.86em;
        padding-right: 0.666em;
        margin-bottom: 10px;
    }
    #cart-table .item-user-actions {
        width: auto;
    }
    #cart-table .item-links .item-user-actions a {
        font-size: 35px;
    }
    .cart-coupon-code .input-text {
        width: 100%;
    }
    .cart-coupon-code.form-row {
        clear: both;
        float: none;
        width: 100%;
    }
    .cart-coupon-code button {
        margin: 5px 0 0 .5em;
    }
    .cart-actions {
        margin: auto;
        overflow: hidden;
        padding: 0 3.9%;
    }
    .cart-order-totals {
        clear: both;
        float: none;
        width: 100%;
    }
    .cart-order-totals button {
        margin: 0 0 20px;
        font-size: 40px;
        border-width: 2px;
        padding: 7px 20px;
    }
    table.order-totals-table td {
        font-size: 35px;
    }
    table.order-totals-table td.name {
        width: 65%;
    }
    .cart-order-totals #shipping-method-list .form-row {
        margin-top: 30px;
    }
    .cart-order-totals #shipping-method-list p,
    .cart-order-totals #shipping-method-list .form-row label {
        font-size: 35px;
        margin: 0;
    }
    .cart-order-totals #shipping-method-list .form-row label {
        padding-top: 0;
    }
    .cart-order-totals #shipping-method-list .input-text {
        padding: 0.45em 0.25em;
        height: auto;
        width: auto;
    }
    .cart-order-totals #shipping-method-list select {
        width: 48%;
        font-size: 35px;
    }
    .cart-order-totals #shipping-method-list button {
        font-size: 35px;
    }
    .cart-footer {
        float: none;
        margin: 0 3.9%;
        padding: 0;
        width: 92.2%;
    }
    .cart-footer-slot-mobile {
        display: block;
        font-size: 35px;
        margin-top: 25px;
    }
    .cart-footer .cart-footer-slot {
        display: none;
    }
    .cart-coupon-code label {
        font-size: 35px;
        margin: 0;
        margin-top: 45px;
    }
    .cart-coupon-code #add-coupon {
        display: block;
        padding: 0;
        margin: 10px 0 20px 0;
        float: left;
        position: static;
    }
    .cart-coupon-code div.error {
        float: right;
        clear: none;
        text-align: right;
    }
    .cart-coupon-code span.error {
        float: right;
        margin-top: 5px;
        clear: none;
        text-align: right;
    }
    #checkout-form button.cart-save {
        font-size: 33px;
        float: left;
        margin-top: 17px;
        padding: 0.4em;
    }
    .cart-action-checkout {
        float: none;
        text-align: right;
    }
    .cart-action-continue-shopping button.button-text {
        font-size: 35px;
        margin-left: 3.9%;
        margin-bottom: 45px;
    }
    #update-cart {
        clear: right;
        margin-right: 3.9%;
        margin-bottom: 50px;
        margin-top: 1em;
        margin-bottom: 1em;
        position: relative;
        right: 0;
        bottom: 0;
    }
    #cart-table .item-total .promo-adjustment {
        width: auto;
    }
    .pdp-main .product-col-1 {
        margin-left: 0;
    }
    /* Checkout */
    /* ------------------------------------- */

    .pt_checkout .form-row.form-row-radio {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .pt_checkout .char-count {
        margin: 10px 12px;
    }
    .pt_checkout .checkout-billing .payment-method .ccnumber .form-caption {
        margin-left: 0;
    }
    .pt_checkout .header-separator,
    .pt_order-confirmation .header-separator {
        padding-top: 30px;
    }
    .pt_checkout legend {
        width: auto;
    }
    .pt_checkout input[type="text"],
    .pt_checkout input[type="password"],
    .pt_checkout textarea {
        width: 90%;
    }
    .pt_checkout .dialog-required {
        margin-right: 3%;
    }
    .checkout-progress-indicator {
        clear: both;
        font-size: .8em;
        margin-bottom: 3%;
        position: relative;
        overflow: hidden;
        text-align: center;
        top: auto;
    }
    .checkout-progress-indicator div {
        padding: 0;
    }
    .order-summary-footer h2 {
        width: 50%;
    }
    .place-order-totals .order-totals-table {
        width: 100%;
    }
    .payment-method-options {
        padding: 0;
    }
    .payment-method-options .form-row {
        width: 100%;
    }
    .payment-method-options .form-row label {
        float: left;
        margin: 0;
        padding: 0 0 0 1em;
        width: 150px;
    }
    .payment-method-options .form-row .input-radio {
        margin: 0;
    }
    .pt_order-confirmation .item-list th {
        display: none;
    }
    .pt_order-confirmation .item-list td {
        float: left;
        padding: 1em 0;
        width: 98%;
    }
    .pt_order-confirmation .item-list .order-totals-table td {
        float: left;
        width: 48%;
    }
    .pt_order-confirmation .order-shipment-table th {
        display: none;
    }
    .pt_order-confirmation .order-shipment-table td {
        font-size: .8em;
    }
    .pt_order-confirmation .order-shipment-table .shipping-method {
        padding-left: 0;
    }
    .pt_checkout .form-label-text.cc-exp-label {
        width: 100%;
    }
    .pt_cart #main,
    .pt_checkout #main,
    .pt_order-confirmation #main {
        margin-left: 0;
        padding: 0 1.7%;
        background-image: none;
        // background-color: rgba(116, 116, 116, 0.6);
        width: auto;
    }
    .pt_checkout #main > h1 {
        margin-top: 55px;
        margin-left: 7px;
    }
    .pt_checkoutsimple #header,
    .pt_checkoutsimple #navigation,
    .pt_checkoutsimple #footer {
        display: block;
    }
    .pt_checkoutsimple #cart-footer {
        text-align: center;
        margin-bottom: 20px;
        padding: 0;
    }
    .pt_checkoutsimple #cart-footer ul {
        margin-top: 5px;
        display: block;
    }
    .pt_checkoutsimple #cart-footer ul li a {
        text-transform: capitalize;
        text-decoration: none;
        white-space: nowrap;
    }
    .pt_checkoutsimple #cart-footer ul li:after {
        content: " | ";
    }
    .pt_checkoutsimple #cart-footer ul li:last-child:after {
        content: "";
    }
    .pt_checkoutsimple #cart-footer ul li:last-child:before {
        content: "";
    }
    .checkout-simple .checkout-message {
        padding: 0 2%;
        margin-bottom: 0;
        border-bottom: none;
    }
    .checkout-simple .login-box .login-box {
        margin-bottom: 0;
    }
    .checkout-simple .login-box-content .form-row {
        text-align: left;
    }
    .checkout-simple form .form-row .input-text,
    .checkout-simple form .form-row .input-text-pw {
        width: 94%;
        padding-left: 3%;
        padding-right: 3%;
        margin: 0;
    }
    .checkout-simple form .form-row.remember-me {
        margin-left: 2px;
    }
    .checkout-simple .col-1,
    .checkout-simple .col-2 {
        float: none;
        width: 96%;
        padding: 15px 2% 0 2%;
        border-top: 1px solid #afafae;
        margin-top: 20px;
        margin-bottom: 0;
    }
    .checkout-simple .col-1 {
        border-left: none;
    }
    .checkout-simple .col-1 .login-box {
        margin-bottom: 20px;
    }
    .checkout-simple .col-1 .login-box-content form {
        border-top: 1px solid #afafae;
        width: 100%;
        margin-left: -2%;
        padding: 0 2%;
    }
    .checkout-simple .col-1 .login-box-content form:first-of-type {
        border: none;
        width: auto;
        margin-left: 0;
        padding: 0;
        padding-bottom: 20px;
    }
    .checkout-simple .col-1 .login-box-content div.Facebook {
        font-size: 15px;
        margin-top: 15px;
    }
    .checkout-simple .col-2 > .login-box {
        margin-bottom: 0;
    }
    .checkout-simple .col-2 .button-white {
        float: right;
    }
    .pt_checkout a.return-to-cart {
        clear: both;
        float: none;
        font-size: 1em;
        margin-left: 4.0485%;
    }
    .pt_checkoutsimple .pt_checkout #main {
        letter-spacing: 2px;
        margin-top: -15px;
    }
    .pt_checkoutsimple .account-benefit-container {
        display: none;
    }
    .pt_checkoutsimple #checkout-header {
        display: block;
        width: 100%;
        margin-bottom: 60px;
    }
    .pt_checkoutsimple #password-reset {
        display: none;
    }
    #password-reset-mobile {
        text-decoration: underline;
        display: block;
        color: @gray;
        float: right;
        padding-bottom: 55px;
    }
    .pt_checkoutsimple #checkout-header .desktop-logo {
        display: none;
    }
    .pt_checkoutsimple #checkout-header .mobile-logo {
        display: inline-block;
        margin: 0 auto;
        height: auto;
        width: auto;
        max-width: 83%;
    }
    .pt_checkoutsimple .cart-intercept-page #checkout-header {
        margin-bottom: 75px;
    }
    /* Order Confirmation */

    .pt_order-confirmation .item-list .order-totals-table td {
        padding-left: 0;
        padding-right: 0;
    }
    /* account pages */
    /* ------------------------------------- */

    .pt_accountlogin #main {
        width: auto;
        background: none;
    }
    .pt_accountlogin .col-1 {
        margin-left: 0px;
        border-left: none;
        padding-left: 0px;
    }
    .content-asset ul.account-options li {
        float: none;
        width: auto;
    }
    ul.address-list li,
    ul.payment-list li {
        float: none;
        width: auto;
    }
    .order-history-header button {
        font-size: 1em;
        padding: .25em;
    }
    .pt_customer-service table {
        width: 100%;
    }
    .login-box-content #password-reset {
        display: none;
    }
    .pt_accountlogin .login-box-content .form-row.remember-me {
        margin: 17px 0 0 0;
    }
    .pt_accountlogin .form-row.label-inline.remember-me.squaredTwo {
        position: relative;
        top: -20px;
    }
    .pt_accountlogin #dwfrm_login .button-base {
        background-size: 80% 100%;
    }
    .pt_accountlogin #dwfrm_oauthlogin .button-base {
        background-size: 80% 100%;
    }
    .pt_account #myaccount-container {
        background: none!important;
    }
    .pt_account #myaccount-container > .content-asset {
        width: 90%;
    }
    .pt_accountlogin .Facebook {
        width: 90%;
        margin-right: -30px;
        margin-top: 10px!important;
    }
    .pt_accountlogin form#dwfrm_oauthlogin {
        border-bottom: 1px solid #c8c8c8;
        border-top: 1px solid #c8c8c8;
        min-width: 280px;
    }
    .pt_accountlogin .col-1 {
        min-width: 285px;
        margin-right: -30px;
    }
    .pt_accountlogin .button-base.button-white {
        font-family: 'Century Gothic Bold', sans-serif;
        float: right;
    }
    .pt_accountlogin .login-order-track .dialog-required {
        margin-right: -30px;
    }
    .pt_account .primary-content h1.gift-certificate-header {
        margin-top: 30px;
    }
    /* wish list */
    /* ------------------------------------- */

    .login-box-content .form-row label,
    .pt_wish-list .form-inline button {
        width: 100%;
    }
    .wishlist-page .send-list .description {
        width: 70%;
    }
    #wrapper.pt_wish-list #main {
        background: rgba(116, 116, 116, 0.5);
    }
    .pt_wish-list .find-fieldset label,
    .pt_wish-list .landing.col-1 label {
        float: left;
        width: initial;
        width: 110px;
        text-align: right;
        margin-right: 2%;
    }
    .pt_wish-list .landing.col-1 .form-row .input-text {
        float: left;
    }
    .pt_wish-list .landing.col-1 .form-row-button {
        clear: both;
    }
    .pt_wish-list .landing.col-1 .form-row {
        width: 100%;
        float: none;
        text-align: left;
    }
    .pt_wish-list .landing.col-2 .returning-customers .form-row.required label {
        width: initial;
    }
    .pt_wish-list .landing.col-2 .login-account,
    .pt_wish-list .landing.col-2 .login-create-account {
        float: none;
        width: 100%;
    }
    .pt_wish-list .landing.col-2 .login-account {
        border-right: 0px;
        border-bottom: 1px solid #ffffff;
        padding-bottom: 2%
    }
    .pt_wish-list .landing.col-1,
    .pt_wish-list .landing.col-2 {
        max-width: 90%;
    }
    .login-create-account .login-box-content .form-row-button {
        text-align: center;
    }
    .pt_wish-list .item-option.option-toggle-public label {
        margin: 10px 0px;
        top: -10px;
    }
    .pt_wish-list .option-quantity-desired label {
        margin: 10px 0px;
    }
    .wishlist-page .item-list .item-add-to-cart {
        float: none;
        width: 97%;
    }
    .wishlist-page table.item-list .item-image {
        width: auto;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container .submit {
        float: none;
        clear: both;
        padding-top: 18px;
    }
    .wishlist-page .option-add-to-cart .container .quantity {
        width: 100%;
        float: none;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container .quantity label {
        margin-left: 0px;
        margin-top: 0px;
        float: left;
    }
    .wishlist-page td.item-add-to-cart .option-add-to-cart input {
        float: left;
    }
    .wishlist-page table.item-list td {
        padding: 5px 18px;
    }
    .pt_wish-list .landing.col-2 .returning-customers .form-row.required label,
    .pt_wish-list .landing.col-2 .returning-customers .form-row.required label {
        text-align: left;
    }
    .pt_wish-list #dwfrm_oauthlogin .Facebook {
        width: 80%;
        margin-top: 20px;
    }
    .pt_wish-list .landing.col-2 .input-text,
    .pt_wish-list .landing.col-2 .input-text-pw {
        width: 90%;
    }
    .pt_wish-list .landing.col-2 .remember-me.squaredTwo {
        margin-left: 2%;
    }
    .pt_wish-list #dwfrm_oauthlogin {
        margin-bottom: 20px;
        margin-top: 20px;
        border-top: 1px solid #c8c8c8;
    }
    .pt_wish-list .find-fieldset .input-text {
        width: 125px;
    }
    .pt_wish-list .find-fieldset .form-row.form-row-button {
        float: right;
        margin-right: 25px;
    }
    .pt_wish-list .form-inline form {
        width: 90%;
    }
    .pt_wish-list .primary-content h1 {
        width: 90%;
        font-size: 28px;
    }
    .pt_wish-list .find-fieldset label,
    .pt_wish-list .landing.col-1 label {
        width: 90px;
    }
    .wishlist-page .send-list {
        word-wrap: break-word;
        width: 90%;
    }
    .wishlist-page .giftCert {
        width: 90%;
        min-height: 90px;
    }
    .wishlist-page .giftCert .description {
        width: 100%;
        padding-bottom: 10px;
    }
    .wishlist-page .sku {
        margin-top: -20px;
    }
    .pt_wish-list .landing.col-1 .form-row-button button {
        float: right;
    }
    /* Gift certificates */
    /* ------------------------------------- */

    .gift-certificate-purchase .content-asset,
    .gift-certificate-purchase form {
        float: none;
        width: 90%;
    }
    .gift-certificate-purchase .cert-amount .form-caption {
        width: 80%
    }
    /* Gift registry */
    /* ------------------------------------- */

    .page-content-tab-navigaton button {
        width: 100%;
    }
    .pt_gift-registry .create-new-registry p {
        display: none;
    }
    .pt_gift-registry .create-new-registry {
        overflow: hidden;
    }
    .pt_gift-registry .item-list {
        width: 98%;
    }
    .pt_gift-registry .item-list th {
        display: none;
    }
    .pt_gift-registry .event-search .form-row {
        clear: left;
        width: 100%;
        margin: 1% 0%
    }
    .pt_gift-registry .event-search .form-row.form-row-button {
        clear: both;
        margin-top: 18px;
        width: 28.5%;
        min-width: 150px;
        text-align: left;
    }
    .pt_gift-registry .event-search label {
        width: 120px;
        text-align: right;
    }
    .pt_gift-registry .primary-content h1 {
        padding-top: 25px;
    }
    .pt_gift-registry .event-search .form-row {
        float: none;
    }
    .pt_gift-registry .start .event-search .form-row {
        width: 100%;
        margin: 1% 0%;
    }
    .pt_gift-registry .event-search button {
        width: auto;
    }
    .pt_gift-registry .start .event {
        font-size: 12px;
    }
    .pt_gift-registry .start .event-search .form-row.form-row-button {
        margin-top: 18px;
    }
    .pt_gift-registry .primary-content h3 {
        display: block;
        clear: left;
    }
    .pt_gift-registry .primary-content .list-table-header h2 {
        font-size: 30px;
        text-transform: uppercase;
        font-family: 'arrow';
        padding: 0px;
        margin: 0px;
        float: none;
        letter-spacing: 2px;
    }
    .pt_gift-registry .primary-content .list-table-header {
        margin-bottom: 15px;
        padding-left: 0px;
    }
    .pt_gift-registry span.error {
        float: left;
        margin-left: 5px;
        width: auto
    }
    .pt_gift-registry .event-info .form-row.event-date .form-caption {
        position: relative;
        left: 0;
        top: 0;
    }
    .pt_gift-registry .event-search .input-text {
        margin-left: 0px;
        width: 48%;
    }
    .pt_gift-registry .event-search .form-row.form-row-button {
        text-align: right;
    }
    .pt_gift-registry .selectboxit-option-icon-container {} .pt_gift-registry .gift-registry-advanced .form-row.event-year label {
        margin-left: 8px;
    }
    .pt_gift-registry .event-search .toggle-content .form-row.event-name,
    .pt_gift-registry .event-search .toggle-content .form-row,
    .pt_gift-registry .event-search .toggle-content .form-row.event-month,
    .pt_gift-registry .event-search .toggle-content .form-row.event-city,
    .pt_gift-registry .event-search .toggle-content .form-row.event-state {
        width: 100%
    }
    .pt_gift-registry .login-account .form-row label {
        width: 100%;
        text-align: right;
    }
    .pt_gift-registry .login-account .login-box-content .form-row.remember-me label {
        text-align: left;
    }
    .pt_gift-registry .col-2 .login-account {
        float: none;
        width: 100%;
        border-right: 0px;
        border-bottom: 1px solid #ffffff;
    }
    .pt_gift-registry .col-2 .login-create-account {
        float: none;
        width: 100%;
    }
    .login-box-content div.Facebook {
        float: right;
        padding-right: 0px;
    }
    .pt_gift-registry .registry-main .item-container .item-availability,
    .pt_gift-registry .registry-main .item-container .item-dashboard,
    .pt_gift-registry .registry-main .item-container .item-add-to-cart {
        width: 97%;
        float: none;
    }
    .pt_gift-registry .form-row button.button-yellow {
        color: @blue;
        background-image: url('../images/button-yellow.png');
        background-size: 100% 100%;
        border: none;
        font-family: 'Century Gothic', sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
    }
    .pt_gift-registry .registry-results-table.item-list td {
        font-size: 12px;
    }
    .pt_gift-registry .option-quantity-desired label {
        margin: 10px 0px;
    }
    .pt_gift-registry .option-toggle-public .squaredTwo label {
        left: 12px;
        top: -10px;
    }
    .pt_gift-registry .description {
        width: 100%;
        word-break: break-all;
    }
    .pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity label {
        margin-right: 0px;
        float: left;
    }
    .pt_gift-registry .option-add-to-cart .container .quantity {
        width: 100%;
    }
    .pt_gift-registry table.item-list td {
        padding: 3px;
    }
    .pt_gift-registry .item-dashboard .item-option.option-toggle-public {
        height: auto;
    }
    .pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity {
        margin-left: 0px;
    }
    .pt_gift-registry .item-add-to-cart .option-add-to-cart .container .submit {
        float: none;
    }
    .pt_gift-registry .shipping-info .form-row-button {
        float: none;
    }
    .pt_gift-registry .confirm .confirm-details {
        display: block;
    }
    .pt_gift-registry .confirm .confirm-details dl {
        width: auto;
        display: block;
        border-top: 1px solid #afafaf;
        border-left: none;
        padding-left: 0;
        padding-top: 1em;
    }
    .pt_gift-registry .confirm .confirm-details dl:first-of-type {
        border-top: none;
        padding-top: 0;
    }
    /* report tables */
    /* ------------------------------------- */

    table.item-list td,
    table.item-list .section-header {
        font-size: .9em;
        padding: .3em;
    }
    table.item-list .item-image,
    table.item-list .item-details,
    table.item-list .item-availability,
    table.item-list .item-dashboard {
        display: inline-block;
    }
    table.item-list .item-image {
        width: 30%;
    }
    table.item-list .item-details {
        width: 50%;
    }
    table.item-list .item-availability {
        width: 97%;
    }
    table.item-list .item-dashboard {
        width: 97%;
    }
    table.item-list .delete-registry {
        text-align: center;
    }
    table.item-list th.section-header {
        background-image: none;
    }
    .list-table-header {
        padding: 1em;
    }
    .list-table-header .button {
        display: block;
        margin: 20px 0;
        width: 196px;
    }
    .list-table-header p {
        display: none;
    }
    .list-table-header button {
        display: block;
        width: 260px;
    }
    /* Homepage assets */
    /* ------------------------------------- */

    .pt_storefront #freeShippingText {
        color: #C2CEE4;
        font: bold 250% 'Arrow';
        letter-spacing: 2px;
        position: absolute;
        left: 5px;
        top: 10px;
        text-transform: uppercase;
    }
    .pt_storefront #freeShippingText-mobile {
        color: #C2CEE4;
        font: bold 19pt 'Arrow';
        letter-spacing: 2px;
        position: absolute;
        left: 5px;
        top: 10px;
        text-transform: uppercase;
    }
    .pt_storefront #ordersFreeShipping {
        color: #C2CEE4;
        font: 13pt regular 'Arrow';
        letter-spacing: 2px;
        position: absolute;
        left: 230px;
        top: 5px;
    }
    .pt_storefront #ordersFreeShipping-mobile {
        color: #C2CEE4;
        font: 13pt regular 'Arrow';
        letter-spacing: 2px;
        position: absolute;
        left: 230px;
        top: 5px;
    }
    .pt_storefront #learnMoreFreeShipping {
        color: #C2CEE4;
        letter-spacing: 2px;
        position: absolute;
        left: 230px;
        top: 25px;
    }
    .pt_storefront #learnMoreFreeShipping-mobile {
        color: #C2CEE4;
        letter-spacing: 2px;
        position: absolute;
        left: 230px;
        top: 25px;
    }
    .pt_storefront #learnMoreFreeShipping a {
        color: #C2CEE4;
    }
    .pt_storefront #learnMoreFreeShipping-mobile a {
        color: #C2CEE4;
    }
    .pt_storefront #learnMoreFreeShipping-mobile a:hover {
        text-decoration: underline;
    }
    .pt_storefront #onOrdersText {
        top: 10%;
        left: 63%;
    }
    .pt_storefront #dollarsText {
        top: 43%;
        left: 63%;
    }
    .pt_storefront #learnMoreText {
        top: 60%;
        left: 85%;
    }
    #arrows {
        min-width: 0px;
    }
    #arrowContainer a.heroPrev {
        display: none !important;
    }
    #arrowContainer a.heroNext {
        display: none !important;
    }
    .home-bottom-left {
        width: 100% !important;
    }
    .home-bottom-center {
        width: 100% !important;
    }
    .home-bottom-right {
        clear: both;
        float: none;
        width: 100% !important;
    }
    .home-bottom-right .product-tile {
        min-height: 0;
    }
    .product-tile .product-image {
        height: auto;
        max-width: none;
    }
    #email-alert-signup {
        margin: 0;
        text-align: center;
        width: 100%;
    }
    #email-alert-signup label {
        text-align: center;
        width: 100%;
    }
    #email-alert-signup input[type="text"] {
        float: none;
        margin: 0;
        width: 50%;
    }
    #email-alert-signup input[type="image"] {
        float: none;
        vertical-align: bottom;
    }
    .facebook-registration {
        left: -3px;
    }
    .facebook-registration,
    .facebook-registration .fb_iframe_widget,
    .facebook-registration .fb_iframe_widget span,
    .facebook-registration .fb_iframe_widget span iframe {
        position: relative;
        width: 100.7% !important;
    }
    .pt_storefront .underneath-slider-slot {
        display: none;
    }
    .pt_storefront .home-bottom-slots #textOverlayWrapper {
        margin: 15px 0px
    }
    .pt_storefront .home-bottom-slots .home-middle-left {
        clear: none;
        float: left;
        padding: 10px 0px;
        width: 100%;
    }
    .pt_storefront .home-bottom-slots .home-middle-center {
        clear: none;
        float: left;
        padding: 10px 0px;
        width: 100%;
    }
    .pt_storefront .home-bottom-slots .home-middle-right {
        clear: none;
        float: left;
        padding: 10px 0px;
        width: 100%;
    }
    .pt_storefront #communityText {
        font: bold italic 2em 'calisto';
    }
    .pt_storefront #downAroundText {
        top: 5px;
        left: 5px;
        color: #C2CEE4;
    }
    .pt_storefront #downAroundText-mobile {
        top: 5px;
        left: 5px;
        font: bold 25pt 'Arrow';
        color: #C2CEE4;
        position: absolute;
    }
    .pt_storefront #trademarkSymbol {
        top: 10px;
        left: 240px;
        color: #C2CEE4;
    }
    .pt_storefront #trademarkSymbol-mobile {
        top: 10px;
        left: 205px;
        font: bold 15pt 'Arrow';
        color: #C2CEE4;
        position: absolute;
    }
    .pt_storefront #pillowText {
        top: 5px;
        left: 260px;
        color: #C2CEE4;
    }
    .pt_storefront #pillowText-mobile {
        top: 5px;
        left: 230px;
        font: bold 25pt 'Arrow';
        color: #C2CEE4;
        position: absolute;
    }
    .pt_storefront #shopNowImage {
        top: 15px;
        left: 370px;
    }
    .pt_storefront #shopNowImage-mobile {
        top: 13px;
        left: 330px;
        position: absolute;
        width: 110px;
    }
    .product-tile-hover img.top {
        display: none !important;
    }
    .product-tile-hover img.bottom {
        display: none !important;
    }
    .product-tile-hover .product-tile-mobile {
        display: block;
    }
    .product-tile-hover .product-tile-mobile .thumb-link img {
        position: relative;
        left: 0;
        transition: none;
    }
    .product-tile-hover .product-tile-mobile img.top:hover {
        opacity: 1;
    }
    .contentframeImage,
    .pt_account .contentframeImage {
        width: 130%;
        left: -25%;
        margin-left: 0;
    }
}
@media screen and (min-width: @mobile-max) and (max-device-width: @desktop-min) and (orientation: portrait) {
    /* Wishlist */
    /* ---------------------------------------- */

    .pt_wish-list .wishlist-page .item-list {
        width: 90%;
    }
    .pt_wish-list .wishlist-page table.item-list .item-details {
        width: 30%;
    }
    .pt_wish-list .wishlist-page table.item-list .item-availability {
        width: auto;
    }
    .pt_wish-list .wishlist-page table.item-list td.item-dashboard {
        width: 30%;
        float: left;
    }
    .wishlist-page .item-list .item-add-to-cart {
        width: 100%;
        margin: 10px 0px;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container {
        width: 350px;
        float: right;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container .quantity {
        width: 30%;
    }
    .wishlist-page .item-add-to-cart .option-add-to-cart .container .submit {
        padding-top: 0px;
        margin-left: -15px;
    }
    .pt_wish-list .find-fieldset .input-text {
        width: 60%;
    }
    .pt_wish-list .wishlist-page .sku {
        margin-top: 0px;
    }
    /* Gift Registry */
    /* ---------------------------------------- */

    .pt_gift-registry .registry-main .item-container .item-availability {
        width: 10%;
    }
    .pt_gift-registry .registry-main .item-container .item-dashboard {
        width: 30%;
        float: right;
    }
    .pt_gift-registry .registry-main .item-container td.item-details {
        width: 35%;
    }
    .pt_gift-registry .registry-main .item-container .item-add-to-cart {
        width: 100%;
    }
    .pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity {
        width: 43%;
        float: left;
    }
    .pt_gift-registry .registry-main .item-container .item-add-to-cart .option-add-to-cart {
        width: 55%;
    }
    .pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity label {
        margin-top: 0px;
        margin-right: 5px;
    }
    .pt_gift-registry .registry-main .item-availability .notavailable {
        white-space: pre-line;
    }
    .pt_gift-registry .login-account .form-row label {
        float: left;
        width: 120px;
        margin-top: 0px;
    }
    .pt_gift-registry .login-account .form-row .input-text,
    .pt_gift-registry .login-account .form-row .input-text-pw {
        margin-right: 40px;
    }
    .pt_gift-registry #password-reset-mobile {
        font-size: 20px;
        float: left;
        margin-left: 23%;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD span.error {
        margin-left: 2%;
    }
    .pt_checkout .checkout-billing .payment-method .ccnumber .form-caption.error-message {
        margin-left: 2%;
    }
    .pt_checkout span.error {
        margin-left: 2%;
    }
}
/* Bump up to a min-480 viewport */

@media screen and ( min-width: 480px) and (max-width: @mobile-max) {
    #wrapper {
        width: 100%;
    }
    #wrapper.pt_cart,
    #wrapper.pt_checkout {
        font-size: 35px;
    }
    #header #header-banner {
        display: none;
    }
    #header .menu-utility {
        display: none;
    }
    #footer .desktop {
        display: none;
    }
    #footer .mobile {
        display: block;
    }
    #footer {
        padding: 0px;
    }
    #navigation {
        background: none;
    }
    .product-tile .pr_stars {
        left: 40px
    }
    #logo-responsive {
        margin-top: 57px;
        display: block;
    }
    .mm-list a.mm-subopen {
        width: 100% !important;
    }
    .wide-tiles .product-tile .product-name h2 {
        margin-top: 10px;
    }
    .wide-tiles .product-tile .product-image {
        width: 38%
    }
    .facebook-registration {
        left: 0px;
    }
    .facebook-registration,
    .facebook-registration .fb_iframe_widget,
    .facebook-registration .fb_iframe_widget span,
    .facebook-registration .fb_iframe_widget span iframe {
        position: relative;
        width: 100% !important;
    }
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
    #textOverlayImage {
        width: 100%;
    }
    #caringComforterText {
        top: 70%
    }
    #learnMoreComforterText {
        top: 78%
    }
    .pt_storefront #freeShippingText {
        left: 20%;
    }
    .pt_storefront .underneath-slider-slot {
        display: none;
    }
    .pt_storefront #home-page-bottom #leftColumn {
        width: 100%;
        float: none;
    }
    .pt_storefront #home-page-bottom #leftColumn .home-bottom-leftTop {
        float: left;
        width: 100%
    }
    .pt_storefront #home-page-bottom #rightColumn {
        width: 100%;
        float: none;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom-rightTop {
        display: none;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom-rightMiddle {
        display: none;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom-rightBottom {
        display: none;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom .mobile {
        display: block;
    }
    .pt_storefront .fullFeatherDivider {
        display: none;
    }
    .pt_storefront #communityText {
        font: bold italic 2em 'calisto';
    }
    .pt_storefront #comfortguarantee-mobile {
        position: absolute;
        bottom: 30px;
        left: 120px;
        color: #ACACAC;
        text-align: center;
        font: bold 23pt 'Arrow';
    }
    .pt_storefront #learnMoreComfort {
        position: absolute;
        bottom: 10px;
        left: 245px;
        color: #ACACAC;
        font-size: 16px;
    }
    .pt_storefront #learnMoreComfort a {
        color: #ACACAC;
    }
    .pt_checkoutsimple #main > h1 {
        font-size: 50px;
        padding: 0 2%;
    }
    .pt_checkoutsimple #main h2 {
        font-size: 40px;
    }
    .pt_checkoutsimple .button-yellow {
        font-size: 46px;
        font-weight: 700;
        padding: 0.4em 1em 0.4em;
    }
    .pt_checkoutsimple .login-box-content .form-row label {
        font-size: 32px;
        margin-left: 0;
    }
    .pt_checkoutsimple .label-inline label {
        white-space: nowrap;
    }
    .pt_checkoutsimple .login-box-content .label-inline.remember-me {
        width: 30px;
        height: 30px;
        float: left;
    }
    .pt_checkoutsimple .login-box-content .label-inline.remember-me label {
        padding-left: 50px;
        padding-top: 0px;
        letter-spacing: 3px;
    }
    .pt_checkoutsimple .login-box-content .form-row.remember-me.squaredTwo label:after {
        font-size: 61px;
        left: 0px;
        top: 2px;
        background-size: 30px 30px;
    }
    .pt_checkoutsimple .checkout-simple .col-1 .login-box-content div.Facebook {
        float: left;
        font-size: 40px;
        background-size: contain;
        background-image: url('../images/SocialIcons_60x60_Facebook.png');
        padding-left: 90px;
    }
    .pt_checkoutsimple .login-box-content .button-white {
        font-size: 32px;
        font-weight: 700;
    }
    .checkout-simple .checkout-message {
        font-size: 35px;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD span.error {
        margin: 1% 2% 2% 2%;
    }
    .pt_checkout .checkout-billing .payment-method .ccnumber .form-caption.error-message {
        margin-left: 2%;
    }
    .pt_checkout span.error {
        margin-left: 2%;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error {
        margin-left: 2%
    }
    .pdp-main .product-set .product-add-to-cart .standardprice {
        padding-left: 450px;
    }
    .pdp-main .product-set-list .wlar-block li {
        padding-left: 20px;
    }
    .pdp-main .product-set-list .wlar-block {
        margin-top: 0;
        margin-left: 0;
    }
    .pdp-main .product-set-list .wlar-block {
        width: 100%;
        margin-top: 420px;
        margin-left: -70px;
    }
    .pdp-main .pdp-top .product-col-2 .hide-on-mobile {
        display: none;
    }
    /* Cart */

    .pt_cart #cart-items-form td.item-name,
    .pt_cart #cart-items-form td.item-links,
    .pt_cart #cart-items-form tr.rowbundle td.item-details {
        font-size: 25px;
    }
    #cart-table .item-details,
    #cart-table .item-quantity-details,
    #cart-table .item-price {
        font-size: 20px;
    }
    #cart-table td.item-name,
    #cart-table td.item-links,
    #cart-table tr.rowbundle td.item-details {
        font-size: 1.6em;
    }
    #cart-table .item-details,
    #cart-table .item-quantity-details,
    #cart-table .item-price {
        font-size: 1.5em;
    }
    #cart-table .item-links .item-user-actions a {
        font-size: 18px;
        display: block;
    }
    #cart-table .item-quantity .item-quantity-label {
        font-size: 22px;
        display: inline-block;
        margin-top: -0;
    }
    #cart-table .item-quantity input {
        font-size: 22px;
    }
    .cart-order-totals #shipping-method-list p,
    .cart-order-totals #shipping-method-list .form-row label {
        font-size: 25px;
    }
    .cart-order-totals #shipping-method-list select {
        font-size: 22px;
    }
    .pt_cart .order-totals-table {
        border-top: 1px solid #a9a9a8;
        font-size: 1.2em;
    }
    .pt_cart .order-totals-table .order-total {
        font-size: 18px;
    }
    table.order-totals-table td {
        font-size: 25px;
    }
    .cart-coupon-code label {
        font-size: 22px;
    }
    .cart-action-continue-shopping button.button-text {
        font-size: 22px;
    }
    #cart-footer-mobile {
        font-size: 0.8em;
    }
    .cart-order-totals #shipping-method-list button {
        font-size: 27px;
    }
    #wrapper.pt_cart,
    #wrapper.pt_checkout {
        font-size: 14px;
    }
    #cart-table td.item-price {
        text-align: right;
    }
    .order-totals-table tr.order-total {
        font-size: 26px;
    }
    #checkout-form button,
    #checkout-form button.cart-save {
        font-size: 1.5em;
        margin-top: 0;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error-message {
        margin-left: 2%;
    }
    #paypal-text {
        float: none;
        vertical-align: super;
    }
    /* mobile gift cert purchase */
    /* -------------------------------------- */

    .gift-certificate-purchase .main-form .dialog-required {
        width: 95%;
    }
    .gift-certificate-purchase .main-form input {
        max-width: 95% !important;
    }
    .gift-certificate-purchase .main-form .label-above label {
        text-align: left;
    }
    .gift-certificate-purchase .main-form .label-above .char-count {
        margin-left: 5px !important;
    }
    .gift-certificate-purchase span.error {
        margin-left: 20%;
    }
    /* mobile PDP sets */
    /* -------------------------------------- */

    .pdp-main .product-set .product-add-to-cart .product-price {
        width: 60%;
        float: none;
        margin: 0 auto;
        text-align: center;
    }
    .pdp-main .product-set .product-add-to-cart .standardprice {
        padding-left: 0px;
    }
    .pdp-main .product-set .product-add-to-cart button.button-yellow-ps {
        float: none;
        margin: 0 auto;
    }
    .pdp-main .product-set .product-add-to-cart .uncrossed {
        width: 98%
    }
    .pdp-main .product-set .product-primary-image-frame {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: none;
        margin: 3px;
    }
    #wrapper.pt_product-details {
        font-size: 14px;
        max-width: 100%;
        min-width: 320px;
        width: 100%;
    }
    /* mobile gift registry */
    /* ------------------------------------- */

    .pt_gift-registry .event-search .selectboxit-container {
        width: 100%;
        max-width: 168px;
        float: left;
    }
    .registry-page-header img {
        height: auto !important;
    }
    .pt_gift-registry .registry-page-header .contentframeImage {
        top: -14px;
    }
    .pt_wish-list #dwfrm_oauthlogin .Facebook {
        width: 100%;
    }
    /* mobile my account */
    /* ------------------------------------- */

    .my-account-header-login {
        font-weight: normal !important;
    }
    #myaccount-content-slot img {
        height: auto !important;
        max-width: 100%;
    }
    .pt_account #primary {
        padding: 0px;
    }
    .pt_account #addresses {
        margin-top: 30px;
    }
    .login-box-content div img.facebook {
        padding-right: 20px;
    }
    .login-box-content div.Facebook {
        float: left;
        width: 100%;
    }
    /* mobile Category landing page ^/
	/* ------------------------------------- */

    .search-result-options {
        margin: 1.7em 0;
        margin-top: -10px;
    }
    .pt_account #myaccount-content-slot .mobile-cgp-header {
        max-width: @mobile-max;
        margin-left: 35px;
        margin-right: -40px;
    }
    .pt_account .mobile-cgp-header {
        max-width: @mobile-max;
        margin-left: -35px;
        margin-right: 25px;
    }
    .pt_gift-registry .registry-page-header .mobile-cgp-header {
        max-width: @mobile-max;
        margin-left: -33px;
        margin-right: -90px;
    }
    .pt_gift-registry .registry-page-header {
        position: relative;
        top: -12px;
    }
    .pt_wish-list .wishlist-page .mobile-cgp-header {
        max-width: @mobile-max;
        margin-left: -33px;
    }
    .pt_wish-list .wishlist-page {
        position: relative;
        top: -12px;
    }
    /* mobile wishlist */
    /* ------------------------------------- */

    .wishlist-page .contentframeImage {
        height: auto !important;
        top: -12px;
    }
    /* mobile Customer Service */
    /* ------------------------------------- */

    .pt_customer-service h1 {
        font-weight: normal;
    }
    .pt_customer-service #secondary,
    .pt_blog #secondary {
        height: auto !important
    }
}
@media screen and ( min-width: 960px) and (max-width: 1009px) {
    .pt_storefront #caringComforterText {
        color: #7EB157;
        text-shadow: white 0.1em 0.1em 0.2em;
        font: bold 30pt 'Arrow';
        letter-spacing: 1px;
        width: 100%;
        position: absolute;
        top: 300px;
        left: 0px;
        text-align: center;
        text-transform: uppercase;
    }
    #learnMoreComforterText {
        color: #7EB157;
        font: 20pt regular century-gothic;
        width: 100%;
        position: absolute;
        top: 350px;
        left: 0px;
        text-align: center;
    }
}
@media screen and (max-width: 665px) {
    /*
		Cart buttons get messed up too much between 768 and 480
		Mobile landscape view is a popular view between these sizes
	*/

    #checkout-form button {
        width: 100%;
    }
    #checkout-form button.cart-save {
        font-size: 14px;
        float: right;
        margin: 0 0 25px;
        width: 80px;
    }
    .pdp-main .pdp-top .product-col-2 .hide-on-mobile {
        display: none;
    }
    #background-cs {
        background-color: rgba(116, 116, 116, 0.5);
        position: relative;
        z-index: 0;
    }
    .dirt-on-clean-bedding-asset {
        margin-top: 23px !important;
        margin-bottom: 28px !important;
    }
    /* Mobile My Account */
    /* ------------------------------------- */

    #myaccount-content-slot img {
        height: auto !important;
        max-width: 100%;
    }
    #paypal-text {
        float: none;
        vertical-align: super;
    }
}
@media screen and (max-width: 479px) {
    #homepage-slider div.slide-align-right {
        text-align: right;
    }
    #homepage-slider p.slide-text-header {
        font-size: 5em;
    }
    #homepage-slider p.slide-text-large {
        font-size: 3.5em;
        padding: 0;
        padding-left: 0.2em;
    }
    #homepage-slider p.slide-text-medium {
        font-size: 2.5em;
    }
    #homepage-slider div.slide-align-left img {
        margin-left: -100%;
    }
    #homepage-slider div.slide-align-center img {
        margin-left: -85.805%;
    }
    #homepage-slider div.slide-align-right img {
        margin-left: -110%;
    }
    #homepage-slider div.slide-align-center div.slide-text-white,
    #homepage-slider > div div.slide-align-center div.slide-text-gray {
        left: 5%;
    }
    #minicart {
        display: block;
    }
    /* breadcrumbs */
    /* -------------------------------------- */

    .pt_product-details ol.breadcrumb .last {
        display: none;
    }
    .breadcrumb {
        display: none;
    }
    #arrows {
        min-width: 0px;
    }
    #arrowContainer a.heroPrev {
        display: none !important;
    }
    #arrowContainer a.heroNext {
        display: none !important;
    }
    .js #suggestions {
        background-color: #919294;
        width: 100% !important;
        color: #e0e0e0;
        cursor: pointer;
        padding: 0;
        display: none;
        position: absolute;
        z-index: 8184
    }
    .js #suggestions div {
        clear: both;
        height: 45px;
        padding: 5px;
        overflow: hidden;
        white-space: nowrap;
        font-size: 25px;
    }
    .js #suggestions div:hover {
        background-color: #cccccc;
        color: #333333;
    }
    .js #suggestions div div.suggestionterm {
        float: left;
        overflow: hidden;
        width: auto;
    }
    .js #suggestions div span.hits {
        float: right;
        line-height: 1.8em;
        text-align: right;
        margin-left: 3px;
    }
    ul.search-result-items .grid-tile {
        margin: 0 16px 15px 0;
        width: 87%;
    }
    .search-result-options {
        margin: 1.7em 0;
        margin-top: -10px;
    }
    #navigation h1 {
        top: 2px
    }
    #navigationSpacing .menu-category {
        display: none;
    }
    #navigationSpacing #rightNav {
        display: none;
    }
    #navigationSpacing #rightNav #mini-cart {
        display: none !important;
    }
    .body-trigger {
        position: absolute;
        top: 0;
        right: -17.5em;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 1;
    }
    .mobileMenu-Homepage ul.level-1 {
        padding: 5px 0;
    }
    .mobileMenu-Homepage ul.level-1 a {
        font-size: 2em;
    }
    .mobileMenu-Homepage ul.level-1 a span {
        float: right;
        font-size: .8em;
    }
    .mobileMenu-Homepage ul.level-1 .last a.level-1 {
        border: 0;
    }
    .mobileMenu-Homepage ul.level-1 li:hover a.level-1 {
        border-color: #efefef;
    }
    #footer .desktop {
        display: none;
    }
    #footer .mobile {
        display: block;
    }
    #footer {
        padding: 0px;
    }
    input[type="text"] {
        border: 1px solid #AAAAAA;
        margin-left: 2%;
        padding: 0.8em 0.5em;
        text-indent: 0;
        width: 49.5966%;
    }
    div#wrapper.pt_gift-registry {
        font-size: 12px;
    }
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
    .mm-list a.mm-subopen {
        width: 100% !important;
    }
    .pt_storefront .underneath-slider-slot {
        display: none;
    }
    .pt_storefront .home-bottom-slots #textOverlayWrapper {
        margin: 15px 0px
    }
    .pt_storefront .mobileMenu-Homepage {
        display: block;
    }
    .pt_storefront .mobile-menu {
        display: block;
    }
    .pt_storefront .mobile-menu .fullFeatherDivider {
        display: none;
    }
    .pt_storefront #home-page-bottom #leftColumn {
        width: 100%;
    }
    .pt_storefront #home-page-bottom #rightColumn {
        width: 100%;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom-rightTop {
        display: none;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom-rightMiddle {
        display: none;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom-rightBottom {
        display: none;
    }
    .pt_storefront #home-page-bottom #rightColumn .home-bottom .mobile {
        display: block;
    }
    .pt_storefront #freeShippingText {
        color: #C2CEE4;
        font: bold 11pt 'Arrow';
        letter-spacing: 2px;
        position: absolute;
        left: 5px;
        top: 5px;
        text-transform: uppercase;
    }
    .pt_storefront #freeShippingText-mobile {
        color: #C2CEE4;
        font: bold 11pt 'Arrow';
        letter-spacing: 2px;
        position: absolute;
        left: 5px;
        top: 5px;
        text-transform: uppercase;
    }
    .pt_storefront #ordersFreeShipping {
        font: 8pt regular 'Arrow';
        top: 5px;
        left: 150px;
    }
    .pt_storefront #ordersFreeShipping-mobile {
        font: 8pt regular 'Arrow';
        top: 5px;
        left: 150px;
    }
    .pt_storefront #learnMoreFreeShipping {
        font: 8pt regular 'Arrow';
        top: 19px;
        left: 150px;
    }
    .pt_storefront #learnMoreFreeShipping-mobile {
        font: 8pt regular 'Arrow';
        top: 19px;
        left: 150px;
    }
    .pt_storefront #trademarkSymbol {
        font: bold 12pt 'Arrow';
    }
    .pt_storefront #companyStoryText {
        font: bold 19pt 'Arrow';
        top: 15px;
    }
    .pt_storefront #learnMoreComforterText {
        top: 130px;
        left: 10px;
    }
    .pt_storefront #learnMoreComfort a {
        color: #ACACAC;
    }
    .pt_storefront #caringComforterText {
        font: bold 13pt 'Arrow';
        top: 110px;
        left: 0px;
    }
    .pt_storefront #weRockText {
        font: 13pt regular century-gothic;
        top: 45px;
    }
    .pt_storefront .home-bottom {
        width: 100%;
    }
    .pt_storefront .fullFeatherDivider {
        display: none;
    }
    .pt_storefront #communityText {
        font: bold italic 2em'calisto';
    }
    .pt_storefront #comfortguarantee-mobile {
        position: absolute;
        bottom: 20px;
        left: 80px;
        color: #ACACAC;
        text-align: center;
        font: bold 15pt 'Arrow';
    }
    .pt_storefront #learnMoreComfort {
        position: absolute;
        bottom: 5px;
        left: 155px;
        color: #ACACAC;
    }
    .pt_storefront #downAroundText {
        top: 5px;
        left: 5px;
        font: bold 15pt 'Arrow';
        color: #C2CEE4;
    }
    .pt_storefront #downAroundText-mobile {
        top: 5px;
        left: 5px;
        font: bold 15pt 'Arrow';
        color: #C2CEE4;
        letter-spacing: 1px;
    }
    .pt_storefront #trademarkSymbol {
        top: 5px;
        left: 155px;
        color: #C2CEE4;
    }
    .pt_storefront #trademarkSymbol-mobile {
        top: 5px;
        left: 132px;
        color: #C2CEE4;
        position: absolute;
    }
    .pt_storefront #pillowText {
        top: 5px;
        left: 175px;
        font: bold 16pt 'Arrow';
        color: #C2CEE4;
    }
    .pt_storefront #pillowText-mobile {
        top: 5px;
        left: 150px;
        font: bold 16pt 'Arrow';
        color: #C2CEE4;
        position: absolute;
        letter-spacing: 1px;
    }
    .pt_storefront #shopNowImage {
        top: 10px;
        left: 250px;
    }
    .pt_storefront #shopNowImage-mobile {
        top: 8px;
        left: 220px;
        width: 80px;
        position: absolute;
    }
    .contentframeImage {
        margin-top: 5px;
        width: 130%;
        left: -25%;
    }
    .wishlist-page .contentframeImage,
    .registry-page-header .contentframeImage,
    .pt_account #myaccount-content-slot .contentframeImage {
        width: 130%;
        margin-left: 0px;
        left: -25%;
    }
    .wishlist-page .giftCert {
        min-height: 100px;
    }
    .wishlist-page .selectboxit-container {
        width: 100%;
    }
    .product-tile-hover img.top {
        display: none !important;
    }
    .product-tile-hover img.bottom {
        display: none !important;
    }
    .product-tile-hover .product-tile-mobile {
        display: block;
    }
    .product-tile-hover .product-tile-mobile .thumb-link img {
        position: relative;
        left: 0;
        transition: none;
    }
    .product-tile-hover .product-tile-mobile img.top:hover {
        opacity: 1;
    }
    #product-content h1.product-name {
        padding: 30px 0px 10px;
    }
    #product-content {
        padding: 0px 15px;
    }
    /* Mobile My Account Landing Page */
    /* -------------------------------------- */

    .pt_account .primary-content .my-account-header {
        font-weight: normal !important;
    }
    .my-account-header-login {
        margin-top: 31px !important;
        font-weight: normal !important;
    }
    .pt_account #myaccount-container {
        width: 100%;
        height: 100%;
    }
    .login-box-content div img.facebook {
        padding-right: 5px;
    }
    .login-box-content div.Facebook {
        float: left;
        width: 100%;
        font-size: 19px;
    }
    #myaccount-content-slot img {
        height: auto !important;
        max-width: 100%;
    }
    .pt_account #primary {
        padding: 0px;
    }
    .pt_account #myaccount-content-slot .contentframeImage {
        top: 0px;
    }
    .pt_account #addresses {
        margin-top: 30px;
    }
    /* Mobile PDP */
    /* -------------------------------------- */

    .pdp-main .product-set-list .button-price-box {
        clear: both;
    }
    .pdp-main .product-set-list .block-add-to-cart .inventory {
        width: 40%;
    }
    .pdp-main .product-set .product-primary-image-frame {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: none;
        margin: 3px;
    }
    #wrapper.pt_product-details {
        font-size: 14px;
        max-width: 100%;
        min-width: 320px;
        width: 100%;
    }
    /* Mobile Product Sets */
    /* -------------------------------------- */

    .pdp-main .pdp-top .product-col-2 .hide-on-mobile {
        display: none;
    }
    .pdp-main .product-set .product-add-to-cart {
        padding: 3px 0 10px 0;
    }
    .pdp-main .product-set .product-add-to-cart button.button-yellow-ps {
        float: none;
        margin: 0 auto;
    }
    .pdp-main .product-set .product-add-to-cart .uncrossed {
        width: 80%
    }
    /* search results grid */
    /* -------------------------------------- */

    .pdp-main .product-set-list .block-add-to-cart .inventory {
        margin: 0 0 0 0;
        width: 50%;
        font-size: 16px;
    }
    .pdp-main .product-set-list .block-add-to-cart .product-number {
        margin: 0 0 0 0;
    }
    .pdp-main .product-set-list .block-add-to-cart .availability {
        margin: 0 0 0 0;
    }
    .pdp-main .product-set-list .button-price-box {
        float: left;
        padding-bottom: 20px;
    }
    .pdp-main .product-set-list .product-set-image {
        position: absolute;
    }
    .pdp-main .attribute ul {
        width: 100%;
    }
    .pdp-main .product-set-list .product-name {
        margin-left: 90px;
        margin-top: 0px;
        width: 75%;
        padding-top: 0;
    }
    .pdp-main .product-variations {
        width: 100%;
        padding-top: 25px;
    }
    .pdp-main ul.swatches.size {
        width: 100%;
    }
    .pdp-main .product-set-list .product-set-details {
        width: 90%;
    }
    /* Cart */

    .pt_cart h1 {
        padding-top: 1.5em;
    }
    .pt_cart #cart-banner {
        padding: 0 0.86em;
    }
    .pt_cart #cart-items-form td.item-name,
    .pt_cart #cart-items-form td.item-links,
    .pt_cart #cart-items-form tr.rowbundle td.item-details {
        font-size: 20px;
    }
    #cart-table .item-details,
    #cart-table .item-quantity-details,
    #cart-table .item-price {
        font-size: 20px;
    }
    #cart-table td.item-name,
    #cart-table td.item-links,
    #cart-table tr.rowbundle td.item-details {
        font-size: 1.4em;
    }
    #cart-table .item-details,
    #cart-table .item-quantity-details,
    #cart-table .item-price {
        font-size: 1em;
    }
    #cart-table .item-links .item-user-actions a {
        font-size: 16px;
        display: block;
    }
    #cart-table .item-links .item-user-actions span.separator {
        display: none;
    }
    #cart-table .item-quantity .item-quantity-label {
        font-size: 20px;
        display: inline-block;
        margin-top: -0;
    }
    #cart-table .item-quantity input {
        font-size: 20px;
    }
    #cart-table .item-quantity {
        width: 23.25%;
    }
    #cart-table .item-details,
    #cart-table .item-quantity-details,
    #cart-table .item-price {
        width: 62%;
    }
    .cart-order-totals #shipping-method-list select {
        font-size: 20px;
    }
    .pt_cart .order-totals-table {
        border-top: 1px solid #a9a9a8;
        font-size: 1em;
    }
    .pt_cart .order-totals-table .order-total {
        font-size: 16px;
    }
    table.order-totals-table td {
        font-size: 14px;
    }
    .cart-coupon-code label {
        font-size: 20px;
    }
    .cart-order-totals #shipping-method-list select {
        width: 100%;
        margin-top: 15px;
    }
    .cart-action-continue-shopping button.button-text {
        font-size: 20px;
    }
    .pt_cart .error-message,
    .pt_checkout .error-message {
        margin: 10px 0;
        padding: 0;
    }
    #cart-footer-mobile {
        font-size: 0.8em;
    }
    .cart-order-totals #shipping-method-list button {
        font-size: 25px;
    }
    #wrapper.pt_cart,
    #wrapper.pt_checkout {
        font-size: 16px;
    }
    #cart-table td.item-price {
        text-align: right;
    }
    .order-totals-table tr.order-total {
        font-size: 20px;
    }
    /* Checkout */

    .pt_checkout .form-row.squaredTwo label {
        padding-left: 0px;
        line-height: 30px;
    }
    .pt_checkout .login-box .form-row.squaredTwo label {
        padding-left: 26px;
        line-height: normal;
    }
    .pt_checkout .form-row.squaredTwo label:after {
        font-size: 1.5em;
        top: 0;
        left: -30px;
        background-size: 30px 30px;
    }
    .checkout-progress-indicator div.step:after {
        padding: 0 5px;
    }
    .checkout-progress-indicator div.inactive a,
    .checkout-progress-indicator div.inactive span,
    .checkout-progress-indicator div.active a,
    .checkout-progress-indicator div.active span {
        background-image: none;
        padding: 0;
    }
    .pt_checkout .form-row span.error {
        margin-left: 2%;
    }
    .pt_checkout .squaredTwo label {
        width: 220px;
        padding-top: 0;
    }
    .pt_checkout button.button-yellow {
        font-size: 18px;
    }
    .pt_checkout label {
        margin-left: 2%;
    }
    .pt_checkout .checkout-shipping .form-row-button,
    .pt_checkout .checkout-billing > .form-row-button {
        border-top: 1px solid #afafae;
        margin-top: 10px;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .pt_checkout .checkout-billing .privacy-policy {
        margin-left: 12%;
        font-size: 0.75em;
        padding-top: 0;
    }
    .pt_checkout .checkout-billing .form-indent.checkout-billing-coupon-indent {
        width: auto;
        margin: 15px 2%;
    }
    .pt_checkout .checkout-billing legend.not-first {
        width: 100%;
    }
    .pt_checkoutsimple #checkout-header {
        display: block;
        width: 100%;
        margin-bottom: 17px;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD span.error {
        margin-left: 2%;
    }
    .pt_checkout .checkout-billing .payment-method .ccnumber .form-caption.error-message {
        margin-left: 2%;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD span.error {
        margin: 1% 2% 2% 2%
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error-message {
        margin-left: 2%;
    }
    .pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error {
        margin-left: 2%;
    }
    .pt_product-search-result #secondary {
        top: 31%;
    }
    .pt_gift-registry .registry-page-header .mobile-cgp-header {
        max-width: 479px;
        margin-left: -18px;
        margin-right: -39px;
    }
    .pt_gift-registry .registry-page-header {
        position: relative;
        top: -12px;
    }
    .pt_wish-list .wishlist-page .mobile-cgp-header {
        max-width: 479px;
        margin-left: -18px;
    }
    .pt_wish-list .wishlist-page {
        position: relative;
        top: -12px;
    }
    /* Gift Registry */

    .pt_gift-registry .add-gift-cert button {
        width: 100%;
        margin-bottom: 10px;
    }
    .pt_gift-registry table.item-list td.event-view a img {
        max-width: 60px;
    }
    .pt_gift-registry .shipping-info .form-row-button {
        text-align: center;
    }
    .pt_gift-registry .shipping-info .form-row-button button.submit,
    .pt_gift-registry .shipping-info .form-row-button button.cancel {
        display: inline-block;
        margin: 20px auto;
        float: none;
    }
    .pt_gift-registry .mobile-event-name {
        white-space: nowrap;
        display: inline;
        padding: 10px !important;
    }
    .pt_gift-registry .mobile-event-type {
        display: inline;
        padding: 10px !important;
    }
    .pt_gift-registry .mobile-event-type:after {
        content: "\a";
        white-space: pre;
    }
    .pt_gift-registry .mobile-event-date {
        display: inline;
        padding: 10px !important;
    }
    .pt_gift-registry .mobile-event-location {
        display: inline;
        padding: 10px !important;
    }
    .pt_gift-registry .mobile-event-location:after {
        content: "\a";
        white-space: pre;
    }
    .pt_gift-registry .mobile-event-details {
        display: inline;
        white-space: nowrap;
        padding: 10px !important;
    }
    .pt_gift-registry .mobile-event-details a {
        width: 30% !important;
    }
    .pt_gift-registry .mobile-event-details a:after {
        white-space: pre;
    }
    .pt_gift-registry .mobile-delete-registry {
        display: inline-block;
    }
    .pt_wish-list #dwfrm_oauthlogin .Facebook {
        width: 100%;
    }
    .pt_order-confirmation .shipment-list {
        margin-top: 0;
    }
    .pt_order-confirmation .order-shipment-details {
        display: none;
    }
    .pt_order-confirmation .order-shipment-details.mobile-only {
        display: table-cell;
    }
    .pt_order-confirmation .shipment-list td.order-shipment-details {
        border-bottom: 1px solid #adadad;
    }
    .pt_order-confirmation .shipment-list thead {
        display: none;
    }
    .pdp-main .product-actions .lists li:last-of-type {
        float: right;
        margin-left: 0;
    }
    /* blog */
    /* -------------------------------------- */

    .pt_blog .blog-landing-assets .container .image {
        display: block;
        float: none !important;
        margin: auto;
        text-align: center;
        width: 100%;
    }
    .pt_blog .blog-landing-assets .container .top-link {
        margin-top: 30px;
        text-decoration: none;
    }
    .dirt-on-clean-bedding-asset {
        margin-top: 23px !important;
        margin-bottom: 28px !important;
    }
    /* mobile gift cert purchase */
    /* -------------------------------------- */

    .gift-certificate-purchase .main-form .dialog-required {
        width: 95%;
    }
    .gift-certificate-purchase .main-form .label-above label {
        text-align: left;
    }
    .gift-certificate-purchase .main-form .label-above .char-count {
        margin-left: 5px !important;
    }
    .gift-certificate-purchase span.error {
        margin-left: 20%;
    }
    /* mobile gift registry */
    /* ------------------------------------- */

    .pt_gift-registry .event-search .selectboxit-container {
        width: 47%;
        max-width: 138px;
        float: left;
    }
    .registry-page-header img {
        height: auto !important;
    }
    .pt_gift-registry .registry-page-header .contentframeImage {
        top: -14px;
    }
    /* mobile wishlist */
    /* ------------------------------------- */

    .wishlist-page .contentframeImage {
        height: auto !important;
        top: -15px;
    }
    /* mobile Customer Service */
    /* ------------------------------------- */

    .pt_customer-service h1 {
        font-weight: normal;
    }
    .pt_customer-service #secondary,
    .pt_blog #secondary {
        height: auto !important
    }
}
@media screen and ( min-width: 460px) and (max-width: 479px) {
    .pt_checkoutsimple .cart-intercept-page #checkout-header {
        margin-bottom: 60px;
    }
}
@media screen and ( min-width: 430px) and (max-width: 459px) {
    .pt_checkoutsimple .cart-intercept-page #checkout-header {
        margin-bottom: 40px;
    }
}
@media screen and ( min-width: 400px) and (max-width: 429px) {
    .pt_checkoutsimple .cart-intercept-page #checkout-header {
        margin-bottom: 25px;
    }
}
@media screen and ( min-width: 365px) and (max-width: 399px) {
    .pt_checkoutsimple .cart-intercept-page #checkout-header {
        margin-bottom: 10px;
    }
}
@media screen and ( min-width: 320px) and (max-width: 364px) {
    .pt_checkoutsimple .cart-intercept-page #checkout-header {
        margin-bottom: 0px;
        margin-top: -25px;
    }
}
.mm-list li a {
    display: inline-block;
    width: 100%;
    height: 100%;
}
@media (min-width: @desktop-min) {
	.hide-desktop {
		display: none !important;
	}
}
@media (max-width: @tablet-max) and (min-width: @tablet-min) {
	.hide-tablet {
		display: none !important;
	}
}
@media (max-width: @mobile-max) {
	.hide-mobile {
		display: none !important;
	}
}

nav.mm-menu {
    background-color: var(--color-accent);
}
