/*Less Variables*/

@mobile-max: 767px;
@tablet-min: (@mobile-max + 1);
@tablet-max: 1023px;
@desktop-min: (@tablet-max + 1);
@desktop-max: 1200px;
@xl-min: (@desktop-max + 1);

@blue: #02263e;
@red: #dc3737;
@error: @red;
@white: #fff;
@black: #000;
@gray: #303e48;
@lightgray: #bfbebe;
@mediumgray: #ebebeb;
@darkgray: #4f5a5e;
@othergray: #e8ebed;


// color names from https://www.color-name.com/
@color-cinereous: #927d70; // this is the call to action color
@color-wenge: #64564d; // promo bar background, other misc?
@color-isabelline: #f1efed; // footer background, other misc?




// Fonts
@font-face {
    font-family: 'Quiche Sans';
    src: url('fonts/QuicheSans-Medium.woff2') format('woff2'),
         url('fonts/QuicheSans-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quiche Sans';
    src: url('fonts/quiche_sans_bold-webfont.woff2') format('woff2'),
         url('fonts/quiche_sans_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'termina';
    src: url('fonts/termina_regular-webfont.woff2') format('woff2'),
         url('fonts/termina_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'termina';
    src: url('fonts/termina_medium-webfont.woff2') format('woff2'),
         url('fonts/termina_medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'termina';
    src: url('fonts/termina_bold-webfont.woff2') format('woff2'),
         url('fonts/termina_bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

:root {
    --font-body: 'termina', Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial, sans-serif;
    --font-callout: 'Quiche Sans', Optima, Candara, 'Noto Sans', source-sans-pro, sans-serif;
    --color-cta: @color-cinereous;
    --color-accent: @color-wenge;
    --color-gentle-base: @color-isabelline;
  }