/* Mini-Cart */
// mini-cart
#mini-cart:hover > div,
#mini-cart:focus-within > div,
#mini-cart.hovered > div {
  visibility: visible;
  display: block;
}

#mini-cart.hovered > .mini-cart-total {
  display: none;
  @media screen and (min-width: @desktop-min) {
    display: block;
  }
}

.mini-cart-content {
  height: 0px;
  max-height: 505px;
  transition: 1s;
  background: transparent;
}
#mini-cart:hover .mini-cart-content,
#mini-cart.hovered .mini-cart-content {
  height: 75vh;
  transition: 1s;
}
.mini-cart-content-wrapper {
  background: @white;
}
.free-ship-widget,
.free-ship-widget-cart {
  .grid-3col {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding-top: 10px;
  }
  .threshold-start {
    text-align: left;
  }
  .discount-threshold {
    text-align: right;
  }
  .fs-progress-bar {
    height: 15px;
    padding-inline: 5px;
    padding-top: 2px;
  }
}
.free-ship-widget {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid #cacaca;
  .fs-copy {
    font-size: 1.3em;
    text-align: center;
    display: block;
    strong {
      display: block;
    }
  }
}
.free-ship-widget-cart {
  background-color: var(--color-gentle-base);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #02263e;
  width: 63%;
  float: right;
  .fs-copy {
    font-size: 1.2em;
    text-align: center;
    strong {
      display: block;
    }
  }
  .grid-3col {
    font-size: 12px;
    
    @media (min-width: @xl-min) {
        font-size: 14px;
    }
  }
}

#secondary .order-totals-table .free-ship-widget-cart .grid-3col {
  display: none;
}

.order-payment-summary .free-ship-widget-cart {
  display: none;
}

// mini-cart: checkout
.pt_checkout {
  .ship-to-multiple {
    width: 100%;
    margin: 40px 0 20px 0;
  }
  .checkout-login {
    margin: 30px 0;
    p {
      display: inline;
    }
    .account-dialog {
      text-decoration: underline;
      padding: 0.6em 2em 0.5em;
      margin-top:10px;
      color: @gray;
    }
  }
  .ship-to-multiple,
  .checkout-login {
    text-align: right;
    text-transform: uppercase;
  }
  #ca-fees-alert {
    display: none;
    &.visible {
      display: block;
    }
    .ca-alert {
      width: 50%;
      margin: 0 auto 10px;
    }
  }
  .mini-ca-alert {
    margin-top: 10px;
    padding: 10px;
    background-color: rgba(200, 200, 200, .7);
  }
}
