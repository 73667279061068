.mainButtonCTA(@firstcolor : #000, @secondcolor : #006f00, @fontsize : 12px) {
    color: @white;
    background: var(--color-cta);
    font-weight: 500;
	padding: 15px 30px;
    font-size: @fontsize;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
}
.mainButtonCTAH(@firstcolor: #006f00, @secondcolor: #8ac45e) {
    opacity: .9;
}
.clearBtn() {
	background: @white;
	text-decoration: underline;
	color: @gray;
}
