/* base elements */
/* ------------------------------------- */

body {
    background-color: @white;
    color: @gray;
    font-family: var(--font-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a {
    color: @gray;
    outline: medium none;
    overflow: hidden;
}
fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}
table {
    width: 100%;
}
th {
    text-align: left;
}
td {
    padding: 1em;
    vertical-align: top;
}
img {
    max-width: 100%;
}
/* fonts */
/* ------------------------------------ */
/* fonts moved to components/header/fonts.isml */
/* helper classes */
/* ------------------------------------- */

.clearfix:after {
    clear: both;
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
}
.visually-hidden {
    border: 0 none !important;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    width: 1px;
    background-color: transparent;
    background-image: none;
}
.ie7 .visually-hidden {
    width: 1px !important;
}
ul.menu {
    float: left;
    margin: 0;
    padding: 0;
}
ul.menu li {
    float: left;
    list-style: none outside none !important;
}
.mobile-only,
.mobile-show {
    display: none;
}
/* page layouts */
/* ------------------------------------- */

#homeWrapper {
    border: 0px solid #FFFFFF;
    margin: auto;
    max-width: 960px;
    width: 960px;
    margin-top: -15px;
}
#wrapper {
    border: 0px solid #FFFFFF;
    margin: auto;
    width: 1000px;
    clear: both;
}
.page-wrapper {
    min-width: 1000px;
    position: relative;
    z-index: 0;
}
.centerContent {
    margin: 0 auto;
    width: 100%;
    border-bottom: 1px solid @blue;
}
header {
    position: relative;
}

.primary-content {
    float: right;
    margin-left: -1px;
    padding: 0 0 20px;
    position: relative;
    width: 810px;
}
#secondary {
    float: left;
    width: 17%;
    display: block;
}
#secondaryNav-featherIcon {
    position: relative;
    top: -10px;
}
.ca-warning-container {
	display: none;
	@media (min-width: @tablet-max) {
		margin: 10px auto;
		width: 50%
	}
}
.ca-warning-container-multi {
	width: 90%;
    margin: 0 auto;
    padding: 20px 0;
	display: none;
}
.primary-focus .primary-content {
    border: 0 none;
    border-right: 1px solid #aeaeae;
    float: left;
    margin: 0;
    width: 77%;
    padding-right: 2%;
    clear: both;
    margin-bottom: 40px;
    padding-bottom: 0;
}
.primary-focus #secondary {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    float: right;
    width: 18.5%;
}
.full-width .primary-content {
    border: 0 none;
    width: 100%;
}
.page-content .primary-content {
    width: 81%;
}
/* jQuery Ui */
/* ------------------------------------- */

.ui-widget-overlay {
    background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% #333333;
    opacity: 0.4;
    position: fixed;
}
.ui-dialog {
    padding: 0 47px;
    background-color: @white;
}
.ui-dialog .pdp-top {
    background: none !important;
    margin-top: -5px;
}
.ui-dialog .dialog-content {
    padding: 25px 0;
}
.ui-dialog .ui-widget-header {
    border: 0 none;
    border-radius: 0 0 0 0;
    text-transform: uppercase;
    padding: 20px 0 0 !important;
    background: @white;
}
.ui-dialog .ui-dialog-title {
    float: none;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
}
.ui-dialog.quickview .ui-dialog-title {
    font-size: 16px;
    color: @gray;
}
.ui-dialog .ui-dialog-titlebar {} .emailsignup .ui-dialog-titlebar {
    border-bottom: none;
    padding: 0px;
}
.ui-dialog .ui-icon-closethick {
    display: block;
    background: url("../images/sprite.png") no-repeat -184px -207px !important;
    height: 21px;
    width: 21px;
}
.ui-dialog .ui-dialog-titlebar-close {
    border: 0 none;
    border-radius: 0 0 0 0;
    height: 21px;
    margin: 0 !important;
    padding: 0;
    right: -42px;
    top: 0 !important;
    width: 21px;
}
.ui-dialog.checkout-address-edit .ui-dialog-titlebar-close {
    right: 0px;
}
.ui-dialog .ui-dialog-titlebar-close span {
    left: auto;
    margin: 0;
    padding: 0;
    top: 0;
}
.ui-dialog h1,
.ui-dialog h2 {
    font-size: 30px;
    font-weight: bold;
    margin: 5px 64px 5px 0;
    text-transform: uppercase;
}
.ui-dialog .dialog-required {
    font-weight: 400;
    position: relative;
    text-transform: none;
}
.ui-dialog .pdp-main .product-add-to-cart .availability {
    padding: 40px 0 20px 0px;
    font-size: 13px;
}
.ui-dialog .pdp-main .attribute {
    clear: both;
}
.ui-dialog .pdp-main .attribute .value {
    float: left;
    width: 50%;
}
.ui-dialog .pdp-main .attribute .pdp-dimensions-label {
    clear: left;
}
.ui-accordion .ui-accordion-content {
    padding: 0;
}
.ui-tabs .ui-tabs-nav {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    height: 2.45em;
    padding: 0;
}
.ui-tabs .ui-tabs-nav li {
    -moz-border-bottom-colors: none;
    -moz-border-image: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 #EFEFEF;
    border-color: #D9D9D9 #D9D9D9 #FFFFFF;
    border-style: solid;
    border-width: 1px;
    font-family: georgia;
    font-style: italic;
    list-style: none outside none !important;
    margin: 0 1em -1px 0;
}
.ui-tabs .ui-tabs-nav li.ui-state-active {
    background: none repeat scroll 0 0 #FFFFFF;
}
.ui-tabs .ui-tabs-nav li.ui-state-active a {
    color: @gray;
}
.ui-tabs .ui-tabs-nav li a {
    color: @gray;
}
.ui-tabs .ui-tabs-panel {
    background: none repeat scroll 0 0 #FFFFFF;
    border-color: #D9D9D9;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-width: 1px;
    box-shadow: 4px 2px 10px 0 rgba(66, 66, 66, 0.1);
    color: #414141;
    font-size: 0.9em;
    line-height: 1.5em;
    margin: 0;
    padding: 20px 10px;
}
/* form components */
/* ------------------------------------- */

.button,
a.buttonstyle {
    background: none repeat scroll 0 0 #FFFFFF;
    border-color: #CCCCCC #CCCCCC #D0D0D0;
    border-style: solid;
    border-width: 1px 1px 4px;
    cursor: pointer;
    display: inline;
    font-weight: 700;
    padding: 0.6em 2em 0.5em;
    text-align: center;
}
button,
a.buttonstyle {
    background: none repeat scroll 0 0 #FFFFFF;
    border-style: none;
    border-width: 1px 1px 4px;
    cursor: pointer;
    display: inline;
    font-weight: 700;
    padding: 0.6em 2em 0.5em;
    text-align: center;
}
input[type="button"] {
    background: none repeat scroll 0 0 #FFFFFF;
    border-color: #CCCCCC #CCCCCC #D0D0D0;
    border-style: solid;
    border-width: 1px 1px 4px;
    cursor: pointer;
    display: inline;
    font-weight: 700;
    padding: 0.6em 2em 0.5em;
    text-align: center;
}
button[disabled],
input[disabled] {
    color: #CCCCCC;
}
.button-fancy-small {
    outline: none;
    background: url("../images/interface/ui-wht-tran-grad-52.png") repeat-x scroll 0 0 #01A4E4;
    color: #FFFFFF;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
}
.button-fancy-medium {
    outline: none;
    background: url("../images/interface/ui-wht-tran-grad-52.png") repeat-x scroll 0 0 #01A4E4;
    color: #FFFFFF !important;
    font-weight: 400;
    text-transform: uppercase;
}
.button-fancy-large {
    outline: none;
    background: #01A4E4;
    color: #FFFFFF;
    font-size: 1.75em;
    font-weight: 400;
    padding: 0.5em 1em;
    text-transform: uppercase;
}
.button-fancy-small[disabled] {
    opacity: 0.5
}
.button-fancy-medium[disabled] {
    opacity: 0.5
}
.button-fancy-large[disabled] {
    opacity: 0.5
}
.button-base {
    .mainButtonCTA();
}
.button-base:hover {
	.mainButtonCTAH();
}
a.button-base:hover {
    text-decoration: none;
}
.button-yellow {
    .mainButtonCTA();
}
.button-white {
    .clearBtn();
}
.button-gray {
	.clearBtn();
}
.button-other {
	.mainButtonCTA(#8ac45e, #006f00, 14px);
}
button[disabled].button-base {
    .mainButtonCTA(#767676, #3b3b39);
}
/* Radio buttons */

span.indicator {
    display: none;
}
input[type=radio].radio-yellow {
    display: inline-block;
}
input[type=radio].radio-yellow + span.error {
    display: inline;
    margin: 0;
    float: right;
}
input[type=radio].radio-yellow + label,
input[type=radio].radio-yellow + span.error + label {
    width: auto;
    float: none;
    padding: 0;
    text-align: left;
    display: inline-block;
    margin-left: 0;
    margin-top: 10px;
}
input[type=radio].radio-yellow + label span.indicator,
input[type=radio].radio-yellow + span.error + label span.indicator {
    display: none;
    width: 20px;
    height: 18px;
    margin: -1px 10px 0 0;
    vertical-align: middle;
    background: url("../images/checkout-radio-template.png") left top no-repeat;
    cursor: pointer;
}
input[type=radio].radio-yellow:checked + label span.indicator,
input[type=radio].radio-yellow:checked + span.error + label span.indicator {
    background-position: -21px top;
}
.button-text {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    font-weight: 400;
    padding: 0;
}
.button-full-width {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
}
.js button.simple-submit {
    display: none;
}
.select-address button.simple-submit {
    display: none;
}
legend {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top #EFEFEF;
    border-bottom: 1em solid #FFFFFF;
    font-size: 1.1em;
    font-weight: 700;
    margin: 0;
    padding: 14px 2% 10px;
    position: relative;
    text-transform: uppercase;
    width: 96%;
}
.ie7 legend {
    width: 94%;
}
legend span {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    text-transform: none;
    padding: 0 .5;
}
legend span em {
    color: #333333;
}
span em {
    color: #333333;
}
h2 span {
    font-size: 10px;
    font-weight: normal;
    position: relative;
    text-transform: none;
    float: right;
    padding: 0 0;
}
h2 span em {
    color: #333333;
}
h1 span {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    text-transform: none;
    margin-left: -3px;
    padding-right: 3px;
}
h1 span em {
    color: #333333;
}
.dialog-required {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    float: right;
    padding: 2px 0;
    margin-bottom: 5px;
}
.checkout-shipping .dialog-required {
    margin-right: 24%;
    clear: both;
}
.dialog-required em {
    color: @red;
    font-size: 10px;
    font-style: normal;
}
label,
.form-label-text {
    display: block;
    float: left;
    font-size: 12px;
    padding: 0.75em 0 0 0;
    text-align: left;
    width: 22%;
    clear: both;
    text-align: right;
}
#sortingOptionsContainer {
    .selectboxit-default-arrow {
        border-top: 4px solid @gray;
        top: 40%;
    }
}
#sortingOptionsContainer label {
    width: auto;
    padding-top: 7px;
    padding-right: 10px;
    font-size: 12px;
}
.form-label-text span {
    margin-right: 5px;
}
.form-row {
    clear: both;
    margin: 0 0 1em;
}
.form-row.hidden {
    margin: 0;
}
.form-row-button {
    margin-left: 24.5%;
    width: 62%;
}
input[type="text"],
input[type="password"],
textarea {
    border: 1px solid @gray;
    margin-left: 2%;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 50%;
    background: @white;
    color: @gray;
}
input.error {
    border-color: @error;
}
select {
    text-indent: 0;
    height: 3em;
    border: 1px solid @gray;
    margin-left: 2%;
    padding: 0.7em 0.3em;
    width: 51.6%;
}
.search-result-options select {
    height: auto;
}
input[type="checkbox"] {
    margin: 10px 2%;
}
input[type="radio"] {
    margin: 1em 3px 1em 2%;
}
.label-inline label {
    display: inline-block;
    float: none;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    width: 90%;
}
.label-above label {
    float: none;
    text-align: left;
    width: auto;
    font-size: 14pt;
    margin-bottom: 10px;
}
.label-above input {
    margin-left: 0;
}
.label-above select {
    margin-left: 0;
}
.label-above textarea {
    margin-left: 0;
}
.label-above .form-caption {
    margin-left: 0;
    width: auto;
}
.label-removed label {
    left: -999em;
    position: absolute;
}
.label-hidden label {
    text-align: left;
    text-indent: -999em;
}
.form-indent {
    margin-left: 24%;
}
.form-indent .form-caption {
    margin-left: 0;
}
.form-caption {
    display: block;
    margin: 1% 0 1% 24%;
    width: auto;
    font-size: 12px;
}
.error-message {
    color: @error;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 49.5966%;
}
.error {
    color: @error;
}
span.error {
    background: none repeat scroll 0 0 transparent;
    display: block;
    margin: 1% 2% 2% 34%;
}
.cvn span.error {
    position: absolute;
    margin-left: 23.5%;
}
div.error {
    background: none repeat scroll 0 0 transparent;
}
.error-form {
	clear: both;
    background: url("../images/interface/icon-alert.png") no-repeat scroll left top transparent;
    color: @error;
    margin: 1em 0;
    padding: 0.5em 0.5em 0.5em 4em;
}
.form-inline form {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left bottom transparent;
    padding: 0 0 2em;
    width: 98%;
}
.form-inline .form-row {
    clear: none;
    float: left;
    margin: 5px 2% 0 0;
    width: 28%;
}
.form-inline label {
    padding: 0 0 0.75em;
    text-transform: uppercase;
    width: auto;
}
.form-inline input[type="text"] {
    margin-left: 0;
    width: 95%;
}
.form-inline select {
    margin-left: 0;
    width: 95%;
}
.form-inline .form-row-button {
    float: left;
    margin: 30px 0 0;
    width: 10%;
}
.form-inline button {
   	margin:7px 0 0 10px;
}
.login-box-content .form-row {
    text-align: right;
    margin-top: 25px;
}
.login-box-content .form-row label {
    width: 43%;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
}
#secondary .login-box-content .form-row label {
    text-align: left;
    width: 100%;
}
.login-box-content input[type="text"],
.login-box-content input[type="password"] {
    border-color: #555;
    background-color: #fff;
}
.login-box-content #password-reset {
    float: right;
    font-size: 10px;
    color: @gray;
    margin-bottom: 5px;
    font-weight: bold;
}
.login-box-content .form-row.remember-me {
    margin: 17px 0 0 47%;
    width: 13px;
    height: 13px;
}
.login-box-content .form-row.remember-me.squaredTwo label:after {
    font-size: 14px;
    left: -1px;
    background-size: 15px 15px;
    top: 1px;
}
.login-box-content .form-row.remember-me label {
    margin: 0;
    padding: 0;
    padding-left: 26px;
    color: @gray;
    width: 160px;
    left: 0;
    top: 2px;
}
.login-box-content .form-row.remember-me input[type="checkbox"] {
    margin: 5px 10px 0 0;
}
.login-box-content div.Facebook {
    border: 0;
    margin-top: 47px;
    padding: 10px 10px 10px 0px;
    font-size: 20px;
    color: @gray;
}
.login-box-content div img.facebook {
    width: 34px;
    height: 34px;
    padding-right: 20px;
    vertical-align: middle;
}
.login-box-content input[type="submit"] {
    float: right;
}
.login-create-account .content-asset {
    padding-top: 20px;
}
.login-box-content textarea {
    margin-left: 0;
    width: 87%;
}
.login-box-content .form-caption {
    margin-left: 0;
}
.login-box-content input[type="checkbox"] {
    margin-left: 0;
}
.login-box-content select {
    margin-left: 0;
}
.login-box-content .form-row-button {
    margin-left: 0;
    width: auto;
}
.row-inline {
    display: inline;
}
.row-inline label {
    width: auto;
}
.require-dialog {
    font-weight: 400;
    position: absolute;
    right: 1em;
    text-transform: none;
    top: 1em;
}
.required-indicator {
    color: @red;
}
.form-field-tooltip {
    float: right;
    margin: -36px 0 0;
    width: 21%;
}
.with-helper-text {
    margin-top: -59px;
}
.char-count {
    display: block;
    margin-left: 24%;
}
.btn {
    padding: 0;
    position: relative;
    display: inline-block;
    border: 0;
    border-radius: 0;
    background: transparent;
    vertical-align: middle;
    text-transform: uppercase;
    cursor: pointer;
}
.btn:hover {
    text-decoration: none;
}
.btn-left,
.btn-right {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
}
.btn-left {
    left: 0;
    background-position: 0 0;
}
.btn-right {
    right: 0;
    background-position: right 0;
}
.btn-yellow .btn-text {
    height: 40px;
    padding: 0 22px;
    font-size: 20px;
    line-height: 40px;
    color: @blue;
}
.btn-yellow .btn-left,
.btn-yellow .btn-right {
    background-image: url("../images/yellow-stripes.png");
}
.btn-white .btn-text {
    height: 32px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 32px;
    color: #fff;
}
.btn-white .btn-left,
.btn-white .btn-right {
    background-image: url("../images/white-stripes.png");
}
/* tables used for product lists (wishlist, gift reg) */
/* ------------------------------------- */

table.item-list tr {
    border-bottom: 1px solid #adadad;
}
table.item-list tr.last {
    border: 0 none;
}
table.item-list tr.headings {
    display: none;
}
table.item-list td {
    padding: 20px;
    vertical-align: top;
}

td.item-details .name,
td.item-name .name {
    font-size: 1.16em;
    margin-bottom: 15px;
}
td.item-details .product-list-item .value {
    /* font-weight:700; */
}
td.item-details .product-list-item .promo {
    color: @red;
    margin-bottom: 5px;
}
td.item-details .product-list-item .price-standard {
    color: #999999;
    text-decoration: line-through;
}
td.item-details .item-edit-details {
    margin-top: 20px;
}
td.item-availability ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
td.item-quantity-details ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
td.item-dashboard {
    color: #AAAAAA;
    width: 250px;
}
td.item-dashboard label,
td.item-dashboard .label {
    display: inline;
    float: none;
    font-size: .9em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    width: auto !important;
}

td.item-dashboard .input-text {
    margin: 0;
    text-align: center;
}
td.item-dashboard .input-select {
    margin: 0;
    padding: 8px;
    width: auto !important;
}
td.item-dashboard .input-checkbox {
    margin: 0 5px;
}
td.item-dashboard .item-option {
    clear: both;
    font-size: 1em;
    margin: 0 0 0.5em;
}
td.item-dashboard .item-option .form-row {
    display: inline;
}
td.item-dashboard .option-date-added .value {
    color: #333333;
    font-weight: 700;
}
td.item-dashboard .option-quantity-desired input {
    padding: 0;
    width: 20px;
}
td.item-dashboard .option-priority .label {
    color: #333333;
    font-weight: 700;
}
td.item-dashboard .option-priority label {
    color: #333333;
    font-weight: 700;
}
td.item-dashboard .option-add-to-cart .label {
    color: #333333;
    font-size: 1.1em;
    font-weight: 700;
    text-transform: uppercase;
}
td.item-dashboard .option-add-to-cart label {
    color: #333333;
    font-size: 1.1em;
    font-weight: 700;
    text-transform: uppercase;
}
td.item-dashboard .option-add-to-cart input {
    padding: 0.8em 0.3em 0.7em;
    width: 50px;
}
td.item-dashboard .option-quantity-purchased span.exceeded {
    color: #ff0000
}
/* base tool tip */
/* ------------------------------------- */

.tooltip {
    cursor: help;
    position: relative;
}
.tooltip .tooltip-content {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.8);
    border-radius: 3px 3px 3px 3px;
    color: @gray;
    display: none;
    font-size: 12px;
    font-style: normal;
    left: 60%;
    max-width: 400px;
    min-width: 200px;
    opacity: 0;
    padding: 1em;
    position: absolute;
    text-decoration: none;
    top: 50%;
    visibility: hidden;
    z-index: 10;
    background-color: @white;
    border: 1px solid @gray;
}
.tooltip .tooltip-content h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 0;
    padding: 0;
    padding-bottom: 1em;
}
.tooltip:hover .tooltip-content {
    display: block;
    opacity: 1;
    overflow: visible;
    visibility: visible;
}
.js .tooltip-content {
    display: none;
}
#tooltip {
    background-color: #111;
    opacity: 0.95;
    padding: 5px;
    position: absolute;
    z-index: 3000;
}
#tooltip .small {
    width: 300px;
}
#tooltip .medium {
    width: 500px;
}
#tooltip .recommendation-tooltip-header {
    background: none repeat scroll 0 0 #CCCCCC;
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px;
}
#tooltip .recommendation-tooltip-header .product-price .price-standard {
    color: #999999;
    text-decoration: line-through;
}
#tooltip .recommendation-tooltip-description {
    padding: 10px;
}
#tooltip .recommendation-tooltip-attributes {
    padding: 10px;
}
/* Blog */

.pt_blog img {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
}
.pt_blog img.nodropshadow {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.pt_blog #secondary ul.left-navigation li a {
    text-decoration: none;
}
.pt_blog .left-navigation {
    display: none;
}
.pt_blog #main {
    background-size: 100% 100%;
    width: 100%;
    padding-right: 10%;
    position: relative;
    z-index: 0;
}
/* content assets and slots */
/* ------------------------------------- */

.blog-landing-assets img {
    width: 180px;
    height: 180px;
    float: left;
}
.blog-landing-assets a,
.blog-landing-assets .description {
    float: left;
}
.blog-landing-assets .top-link {
    letter-spacing: 2px;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'arrow';
    text-transform: uppercase;
}
.blog-landing-assets .container .text {
    max-width: 610px;
    margin-left: 20px;
    float: left;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
}
.blog-landing-assets .container {
    display: inline-block;
}
.blog-landing-assets .description {
    font: 15px 'century-gothic';
}
.primary-content .heading h1 {
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 15px;
    border-bottom: 1px solid #afafaf;
    margin-bottom: 43px;
}

.contentframeImage {
    margin: 0 auto;
    position: relative;
    top: 0px;
    left: -333px;
    max-width: none !important;
    display: none;
    z-index: -9999;
}
.registry-page-header .contentframeImage,
.pt_account #myaccount-content-slot .contentframeImage {
    top: -22px;
    width: 1302px;
    height: 268px;
}
.wishlist-page .contentframeImage {
    top: -37px;
    width: 1302px;
    height: 268px;
    margin-left: -7px;
}
.pt_account .contentframeImage {
    width: auto;
    margin-left: -6px;
}
#textOverlayWrapper {
    position: relative;
}
#textOverlayWrapper img {
    vertical-align: top;
}
#textOverlayImage {
    display: block;
    height: auto;
    max-width: 100%;
}
#freeShippingText {
    color: #C2CEE4;
    font-size: 60px;
    position: absolute;
    top: 14px;
    left: 210px;
    text-transform: uppercase;
}
#freeShippingSearch {
    color: #C2CEE4;
    font-size: 40pt;
    font-weight: 600;
    position: absolute;
    top: 10px;
    left: 160px;
    text-transform: uppercase;
}
#onOrdersText {
    color: #C2CEE4;
    font-size: 18.11px;
    position: absolute;
    top: 25px;
    left: 670px;
}
#onOrdersSearch {
    color: #C2CEE4;
    font-size: 10pt;
    position: absolute;
    top: 20px;
    left: 565px;
}
#dollarsText {
    color: @gray;
    font-size: 22.64px;
    position: absolute;
    top: 45px;
    left: 668px;
}
#dollarsSearch {
    color: @gray;
    position: absolute;
    top: 37px;
    left: 564px;
}
#learnMoreText {
    color: @gray;
    font-size: 14px;
    position: absolute;
    top: 53px;
    left: 820px;
}
#learnMoreText a {
     color: @gray;
}
#learnMoreText a:hover {
    color: @gray;
    text-decoration: underline;
}
#learnMoreSearch {
    color: @gray;
    font-size: 8pt;
    position: absolute;
    top: 41px;
    left: 670px;
}
#learnMoreSearch a {
   color: @gray;
}
#learnMoreSearch a:hover {
    color: @gray;
    text-decoration: underline;
}
#companyStoryText {
    font-size: 30px;
    width: 100%;
    position: absolute;
    top: 35px;
    left: 0px;
    text-align: center;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}
#weRockText {
    font-size: 20px;
    width: 100%;
    position: absolute;
    top: 57px;
    left: 0px;
    text-align: center;
    font-size: 1em;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}
#caringComforterText {
    color: #7EB157;
    text-shadow: white 0.1em 0.1em 0.2em;
    font-size: 22px;
    width: 100%;
    position: absolute;
    top: 180px;
    left: 0px;
    text-align: center;
    text-transform: uppercase;
}
#learnMoreComforterText {
    color: #7EB157;
    font-size: 15px;
    width: 100%;
    position: absolute;
    top: 205px;
    left: 0px;
    text-align: center;
}
#learnMoreComforterText a {
    color: #7EB157;
}
#learnMoreComforterText a:hover {
    color: #7EB157;
    text-decoration: underline;
}
#downAroundText {
    font-size: 33px;
    position: absolute;
    top: 30px;
    left: 230px;
}
#downAroundSearch {
    font-size: 30pt;
    font-weight: bold;
    position: absolute;
    top: 20px;
    left: 40px;
}
#trademarkSymbol {
    font-size: 15pt;
    font-weight: bold;
    position: absolute;
    top: 30px;
    left: 440px;
}
#pillowText {
    font-size: 33px;
    position: absolute;
    top: 65px;
    left: 290px;
}
#shopNowImage {
    width: 20%;
    position: absolute;
    top: 105px;
    left: 291px;
    text-align: center;
}
#shopNowImageSearch {
    width: 20%;
    position: absolute;
    top: 25px;
    left: 450px;
}
#textSlotHeader {
    font-size: 20px;
    margin-top: 8px;
}
#textSlotDescription {
    margin-top: -10px;
}
#howDoYouText {
    font-size: 25px;
    position: absolute;
    top: 35px;
    left: 166px;
}
#youText {
    font-size: 45px;
    font-style: italic;
    position: absolute;
    top: 23px;
    left: 285px;
}
#sleepText {
    font-size: 17pt;
    font-weight: bold;
    position: absolute;
    top: 35px;
    left: 390px;
}
#sleepDictatesText {
    font-size: 15px;
    position: absolute;
    top: 75px;
    left: 166px;
}
#sleepDictatesText a {
    color: @gray;
}
#sleepDictatesText a:hover {
    color: @gray;
    text-decoration: underline;
}
#thirtynightComfortHeader {
    position: absolute;
    top: 20px;
    left: 140px;
    font-size: 19px;
}
#thirtynightComfortDescription {
    position: absolute;
    top: 50px;
    left: 140px;
    font-size: 12px;
}
.cat-product-slot-bottomHeader {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    top: 30px;
    left: 170px;
}
#cat-landing-bannerMainHeader {
    font-size: 32px;
    font-weight: 600;
    position: absolute;
    top: 30px;
    left: 315px;
    text-transform: uppercase;
}
#cat-landing-bannerMainDescription {
    position: absolute;
    top: 70px;
    left: 315px;
    width: 36%;
    font-size: 14px;
}
#cat-landing-column1Header {
    text-align: center;
    position: absolute;
    top: 210px;
    font-size: 20px;
    font-weight: bold;
    left: 365px;
    text-transform: uppercase;
}
#cat-landing-column1Description {
    text-align: center;
    position: absolute;
    top: 410px;
    left: 315px;
    width: 20%;
}
#cat-landing-column1Link {
    text-align: center;
    position: absolute;
    top: 475px;
    left: 315px;
    width: 20%;
}
#cat-landing-column2Header {
    text-align: center;
    position: absolute;
    top: 210px;
    font-size: 20px;
    font-weight: bold;
    left: 625px;
    text-transform: uppercase;
}
#cat-landing-column2Description {
    text-align: center;
    position: absolute;
    top: 410px;
    left: 570px;
    width: 20%;
}
#cat-landing-column2Link {
    text-align: center;
    position: absolute;
    top: 475px;
    left: 570px;
    width: 20%;
}
#cat-landing-column3Header {
    text-align: center;
    position: absolute;
    top: 210px;
    font-size: 20px; 
    font-weight: bold;
    left: 850px;
    text-transform: uppercase;
}
#cat-landing-column3Description {
    text-align: center;
    position: absolute;
    top: 410px;
    left: 820px;
    width: 20%;
}
#cat-landing-column3Link {
    text-align: center;
    position: absolute;
    top: 475px;
    left: 820px;
    width: 20%;
}
.cat-product-slot-bottomDescription {
    font-size: 12px;
    position: absolute;
    top: 60px;
    left: 170px;
}
.content-slot img {
    height: auto;
    max-width: 100%;
}
.slot-grid-header p {
    margin: 0;
    padding: 0;
}
.product-listing-1x3 {
    overflow: hidden;
    padding: 10px 0;
}
.product-listing-1x3 ul.search-result-items .grid-tile {
    margin: 0 5px 10px 5px;
}
.product-listing-1x4 {
    background: none repeat scroll 0 0 #FFFFFF;
    overflow: hidden;
    padding: 10px 0;
}
.product-listing-1x4 ul.search-result-items .grid-tile {
    margin: 0 1% 0 2%;
    width: 18%;
}
.account-nav-asset {
    color: @gray;
    background-size: 100% 5px;
    line-height: 1.5em;
    margin: 20px 10px;
    padding: 10px 0;
    margin-left: 0px;
    border-top: 1px solid @gray;
}
.account-nav-asset h2 {
    font-weight: bold;
    letter-spacing: -0.5px;
    margin: 14px 0 20px;
    font-size: 12px;
    text-transform: uppercase;
}
.account-nav-asset a {
    text-decoration: underline;
    color: @gray;
}
.content-asset p {
    line-height: 1.6em;
}
.content-asset ul {
    line-height: 1.6em;
    margin: 1em 0;
    padding: 0 1.5em;
}
.content-asset ol {
    line-height: 1.6em;
    margin: 1em 0;
    padding: 0 1.5em;
}
.content-asset address {
    margin: 1em 0;
}
.content-asset ul li {
    list-style: square outside none !important;
}
.content-asset ol li {
    list-style: decimal outside none;
}
.content-asset ul.account-options {
    margin: 0;
    padding: 0;
}
.content-asset ul.account-options li {
    border-bottom: 1px solid #D9D9D9;
    list-style: none outside none !important;
    position: relative;
    padding: 20px 0;
}
.content-asset ul.account-options li:last-child {
    border-bottom: none;
}
.content-asset ul.account-options li a {
    display: block;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}
.content-asset ul.account-options li img {
    left: 0;
    position: absolute;
    top: 10px;
}
.content-asset ul.account-options li p {
    margin: 0;
    font: 15px century-gothic, sans-serif;
}
.content-asset .tooltip-container {
    position: relative;
}
.content-asset .tooltip-container .tooltip,
.content-asset .hotspot,
.categoryLandingContent .hotspot {
    position: absolute;
    width: 30px;
    height: 28px;
    margin: -14px 0 0 -14px;
    background: transparent url('../images/pdp-hotspot-button.png') no-repeat left center;
}
.content-asset .tooltip-container .tooltip:hover,
.content-asset .hotspot:hover,
.categoryLandingContent .hotspot:hover {
    background-image: url('../images/pdp-hotspot-button-active.png');
}
.content-asset .contentframe {
    position: absolute;
    top: 0;
    padding: 1em;
}
.browser-compatibility-alert {
    background: url("../images/interface/icon-alert.png") no-repeat scroll 1em 1em;
    border: 1px solid;
    color: @error;
    margin: 1em;
    padding: 0.5em 4em;
    min-height: 35px;
}

#shippingPromoText {
    color: @red;
    font-size: 16px;
    letter-spacing: 1px;
}
#mini-cart {
    float: right;
    position: relative;
}
#mini-cart a {
    color: @blue;
    text-decoration: none;
}
.mini-cart-label {
    margin: -2px 0 0;
    display: inline-block;

    img {
        margin-right: 0.3em;
        position: relative;
        top: 1px;
    }
}
.mini-cart-total {
    font-size: 1em;
    text-transform: none;
    color: @gray;
}
.mini-cart-content {
    z-index: 20;
    background: none repeat scroll 0px 0px @white;
    position: absolute;
    top: 25px;
    right: 0;
    width: 270px;
    overflow: hidden;
}
.mini-cart-product ul.product-availability-list li {
    font-size: 10px;
    letter-spacing: normal;
    font-weight: normal;
    height: auto;
    width: auto;
    white-space: normal;
}
.mini-cart-extender {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 10px;
}
.mini-cart-content .mini-cart-content-wrapper {
    border: 1px solid @blue;
    padding: 14px;
}
.mini-cart-content .mini-cart-logo {
    position: absolute;
    right: 5%;
    top: 9px;
}
.mini-cart-header {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 10px 0px;
    text-transform: uppercase;
    border-bottom: 1px solid #CACACB;
}
.mini-cart-close {
    background: url("../images/interface/icon-mini-cart-close.png") no-repeat scroll left top transparent;
    display: none;
    height: 20px;
    text-indent: -999em;
    width: 19px;
}
.mini-cart-products {
    max-height: 250px;
    overflow: auto;
}
.mini-cart-product {
    border-top: 1px solid #CACACB;
    overflow: hidden;
    padding: 10px 0;
}
.mini-cart-products .mini-cart-product:first-child {
    border-top: none;
}
.mini-cart-image {
    float: left;
    width: 38%;
}
.mini-cart-image img {
    max-width: 100%;
}
.mini-cart-name {
    float: right;
    width: 56%;
    font-size: 10px;
}
.mini-cart-name a {
    text-decoration: none;
}
.mini-cart-attributes {
    float: right;
    font-size: 11px;
    width: 56%;
    margin-top: 5px;
}
.mini-cart-product .product-availability-list {
    float: right;
    font-size: 0.9em;
    font-weight: bold;
    width: 56%;
}
.mini-cart-pricing {
    float: right;
    font-size: 11px;
    width: 56%;
}
.mini-cart-pricing .mini-cart-price {
    font-size: 12px;
    margin-top: 5px;
}
.mini-cart-toggler {
    display: none;
}
.mini-cart-subtotals {
    font-weight: 700;
    padding: 10px 0 0;
    text-align: center;
    font-size: 16px;
    border-top: 1px solid #cacaca;
}
.mini-cart-subtotals .label {
    margin: 0 10px 0 0;
    font-weight: normal;
    font-size: 10px;
}
.mini-cart-slot {
    padding: 10px;
    text-align: center;
    color: #fc3;
}
.mini-cart-link-cart {
    display: block;
    margin: 0 10px;
    padding: 10px 0;
    font-weight: bold;
    font-size: 14px;
    color: @white !important;
}
.mini-cart-link-checkout {
    clear: both;
    display: block;
    padding: 20px 0 10px;
    text-align: center;
}
.menu-utility {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    list-style: none outside none;
    margin: 0;
    overflow: visible;
    text-transform: none;
}

.menu-utility-user {
    color: #FFF;
    float: right;
    font-size: 0.8em;
    letter-spacing: 1px;
    margin: 6px 10px 0 0;
    padding: 0;
    text-transform: uppercase;
}
.menu-utility li {
    padding-block: .5em;
    padding-inline: .5em;
    text-align: center;
}

.menu-utility .account-menu__welcome {
    font-size: 13px;
    font-weight: 500;
}

.menu-utility li a {
    color: @gray;
    font-size: 12px;
    line-height: 1;
    text-decoration: none;
}
.menu-utility li a:hover {
    text-decoration: underline;
}
.menu-utility-user li {
    border-right: 1px solid;
    float: left;
    list-style: none outside none;
    padding: 0 6px;
}
.menu-utility-user li.last {
    border: 0 none;
}
#searchFormMobile {
    display: block;
}

@media (max-width: 1023px) {
	#wrapper {
		width: 100vw;
		margin-top: 60px;
	}
	#navigationSpacing #logo {
		top: 72px !important;
		height: 72px !important;
	}
	.navigationContainer {
        border-top: 1px solid @blue;
		height: 130px;
	}
}
@media (max-width: 767px) {
	#homepage-slider-container {
		padding-top: 0 !important;
	}
}

.header-search input[type="text"] {
    background-color: @white;
    border: unset;
    box-sizing: border-box;
    color: @black;
    font-size: 12px;
    height: 29px;
    margin-left: 0;
    padding: 0 30px 0 10px;
    width: 100%;
}

.header-search input[type="text"]::placeholder {
    color: @black;
}
.header-search input[type="text"]:focus::placeholder {
    color: desaturate(lighten(@darkgray, 12), 100);
}

@media (min-width: @desktop-min) {
    .header-search input[type="text"]:focus {
        /* TODO: HOL-976 */
    }
}

.header-search input[type="submit"] {
    background: url(../images/pc-icon-search.png) 0 0 no-repeat;
    background-size: contain;
    top: 6px;
    right: 110px;
    width: 16px;
    content: '';
    z-index: 10;
    border: 0 none;
    text-indent: -999em;
    position: absolute;
    @media (min-width: @desktop-min) {
    	filter: brightness(0);
    }
}
.header-search legend {
    background: none repeat scroll 0 0 transparent;
}

#searchForm #searchFormMobile .leftNav-toggleButton,
#miniCartButton,
#contactUsButton {
    border-left: 1px solid #d2d2d2;
    vertical-align: middle;
    padding: 0 17px;
}
#searchForm #searchFormMobile .leftNav-toggleButton img,
#miniCartButton img,
#contactUsButton img {
    max-height: 30px;
    vertical-align: middle;
}
.dropdownenu-feather {
    position: absolute;
    left: 12px;
    top: -6px;
}
/* primary catalog navigation */
/* ------------------------------------- */

#navigation {
    position: relative;
    padding-top: 27px;
    padding-bottom: 70px;
    width: 100%;
    margin: 0;

    nav {
        width: 100%;
    }
}
.navigationContainer {
    border: none;
    height: 160px;
}

#top-navWrapper .line {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    top: 13.8em;
    border-bottom: 1px solid @gray;
}
#navigationSpacing {
    padding: 18px 0 12px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    z-index: 20;

    a {
        text-decoration: none;
        cursor: pointer;
    }

    @media (min-width: @desktop-min) {
        border-top: 1px solid @blue;
        border-bottom: 1px solid @blue;
        padding: 15px 0;
    }
}
#dropDownMenuFeather {
    position: absolute;
    left: 12px;
    top: -6px;
}
#leftNav {
    max-width: 1000px;
    margin: 0 auto;
}
#navigationFeatherDivder {
    width: 100%;
}
#communityText {
    letter-spacing: normal;
    float: left;
    font-family: 'calisto';
    font-size: 18px;
    color: #7BAF54;
    text-transform: none;
    margin-top: -3px;
}
#navigationSpacing .menu-highlight {
    letter-spacing: normal;
    font-family: 'calisto';
    font-size: 18px;
    float: left;
    color: #7BAF54;
    text-transform: none;
    margin-top: -3px;
}
.pt_storefront .mobile-menu {
    display: none !important;
}
ul.level-1 {
    display: flex;
    justify-content: space-evenly;
    letter-spacing: 0.5px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 100%;
}
ul.level-1Community {
    margin: 0 0 10px;
    padding: 10px 0 11px;
    position: relative;
}
ul.level-1 li {
    float: left;
    padding: 0px;
}
ul.level-1Community li {
    float: left;
    padding: 5px;
    margin-top: -20px;
}
ul.level-1Community li:hover a.level-1Community {
    background: none repeat scroll 0 0 #979797;
    color: @gray;
}
a.level-1:focus + .level-2 {
    display: block;
}
ul.level-1 li:hover div.level-2 {
    display: block;
}
ul.level-1Community li:hover div.level-2 {
    display: block;
}
ul.level-1 a {
    color: @gray;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 18px 0;
    text-decoration: none;
    display: block;
}
ul.level-1 > li > a {
    white-space: nowrap;
}
ul.level-1 li+li {
    padding-left: 25px;
}
ul.level-1Community a {
    color: green;
    font: bold 18pt 'Calisto MT';
    display: block;
    font-size: 12px;
    padding: 7px;
}
div.level-2 {
    z-index: 100;
    background: none repeat scroll 0 0 @white;
    display: none;
    margin: 0;
    padding: 5px;
    position: absolute;
    width: auto;
    top: 50px;
}

div.level-2 .menu-wrapper {
    border: 1px solid @blue;
    overflow: hidden;
}
div.level-2 ul.level-2 {
    float: left;
    list-style: none;
    padding: 10px 15px;
    margin: 0;
}
div.level-2 ul.level-2 li {
    float: none;
    min-width: 130px;
    padding: 8px 0;
}
div.level-2 ul.level-2 a {
    line-height: 18px;
    font-size: 13px;
    padding: 0;
    white-space: nowrap;
}
div.level-2 ul.level-2 a:hover {
    cursor: pointer;
    text-decoration: underline !important;
    transition: 0.5s all;
}
div.level-2 ul.level-2 .level-3 a {
    line-height: normal;
}
div.level-2 .custom {
    float: right;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-left: 1px solid white;
}
div.level-2 .custom img {
    height: auto !important;
    width: auto !important;
}
div.level-2.menu-horizontal ul.level-2 {
    float: none;
}
div.level-2.menu-horizontal li {
    float: left !important;
    height: 100%;
}
div.level-2.menu-horizontal li li {
    border-color: #EFEFEF;
    border-style: solid;
    border-width: 0 1px 0 0;
    float: none !important;
    height: 100%;
}
div.level-2.menu-horizontal li.last li {
    border-width: 0 !important;
}
div.level-2.menu-horizontal .custom {
    clear: both;
}
div.level-3 ul.level-3 {
    margin: 0;
    padding: 0;
}
div.level-3 ul.level-3 li {
    padding: 3px 5px;
    width: auto;
}
div.level-3 ul.level-3 a {
    color: @gray;
    font-weight: 400;
    text-transform: none;
}
div.level-3 ul.level-3 a:hover {
    color: @gray;
    font-weight: 400;
    text-transform: none;
}
.hr {
    width: 3px;
    height: 150px;
    background-color: #000000;
}
ul.level-1 a.level-1:hover {
    color: @blue;
    cursor: pointer;
    transition: 0.5s all;
    text-decoration: none;
}
/* breadcrumbs */
/* ------------------------------------- */

.breadcrumb {
    width: 365px;
    position: relative;
    padding-top: 10px;
    margin-top: 0;
    top: 0;
    left: 0;
    z-index: 2;
    text-transform: lowercase;
    padding-left: 0px;
    font-weight: 500;
}
.pt_gift-registry .breadcrumb {
    width: 365px;
    position: relative;
    padding-top: 10px;
    margin-top: 0;
    top: 0;
    left: 0;
    z-index: 2;
    text-transform: lowercase;
}
.pdp-main .breadcrumb {
    position: absolute;
}
.breadcrumb,
.breadcrumb li a,
.breadcrumb a {
    color: @gray;
    font-weight: 400;
    text-decoration: none;
}
.breadcrumb li {
    display: inline;
    list-style: none outside none;
    text-transform: lowercase;
}
.breadcrumb li:after {
    content: " / ";
}
.breadcrumb li:last-child:after {
    content: none;
}
.breadcrumb span.spacer {
    padding: 0 5px;
}
.breadcrumb li:after,
.breadcrumb li a,
.breadcrumb li span {
    text-transform: lowercase;
    color: @gray;
}
.breadcrumb h1,
.breadcrumb h1 span {
    margin-left: 0;
    display: inline;
    font-size: 1em;
    color: #d2d2d2;
    font-weight: normal;
}
.breadcrumb h1 a {
    color: @gray;
}
.breadcrumb h1 a:hover,
.breadcrumb a:hover {
    color: @blue;
    cursor: pointer;
    transition: 0.5s all;
}
.breadcrumb h1 .paging-current-page {
    left: -999em;
    position: absolute;
}
.breadcrumb-refinement-name {
    background: none repeat scroll 0 0 transparent !important;
    font-family: Georgia, serif;
    font-style: italic;
}
.breadcrumb-refinement-value {
    background: none repeat scroll 0 0 transparent !important;
    font-family: Georgia, serif;
    font-style: italic;
    font-weight: bold;
}
a.breadcrumb-relax {
    background: none repeat scroll 0 0 transparent !important;
    font-family: Georgia, serif;
    font-style: italic;
    padding: 0 5px 0 0;
}
.breadcrumb-last {
    background: none repeat scroll 0 0 transparent !important;
    text-transform: lowercase;
}
.breadcrumb-result-text a,
.breadcrumb a.searchphraselink {
    background: none repeat scroll 0 0 transparent;
    color: #FFF;
    font-weight: bold;
    padding: 0;
}
/* primary region elements */
/* ------------------------------------- */

.primary-content a {
    color:@gray;
}
.primary-content a:hover {
    color: @gray;
    text-decoration: underline;
}
.primary-content h1,
#secondary h1 {
    color: var(--color-accent);
    font-size: 26px;
    font-weight: bold;
    margin-block-end: .5em;
    padding-top: 10px;
    text-transform: uppercase;
}
.searchResultArticles {
    color: @blue !important;
}
.articleHeader {
    font-size: 15px;
    font-weight: bold;
}
.primary-content a {
    color: @gray;
}
.primary-content a.underline {
    text-decoration: underline;
}
.primary-content a:hover {
    color: @gray;
    text-decoration: underline;
}
.primary-content #results-products {
    margin: 0px;
}
.primary-content h2 {
    font-size: 1.3em;
}
.primary-content h3 {
    font-size: 1.15em;
}
.primary-content dl {
    clear: both;
    overflow: hidden;
}
.primary-content dl dt {
    clear: both;
    float: left;
    margin: 2% 0 0;
    min-width: 15%;
}
.primary-content dl dd {
    float: left;
    margin: 2% 0 0;
    padding: 0 0 0 5%;
}
.col-1 {
    float: left;
    margin: 4px 0;
    width: 48%;
}
.col-2 {
    float: right;
    margin: 4px 0;
    width: 48%;
}
.content-header {
    font-size: 1.75em;
    margin: 0 0 14px
}
.content-header span {
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
}
.section-header {
    font-size: 12px;
    margin: 0;
    padding-bottom: 10px;
}
.section-header-note {
    font-size: 0.9em;
    font-weight: 400;
    position: absolute;
    right: 1em;
    text-transform: none;
}
.back {
    display: block;
    margin: 10px 0 10px;
}
/* secondary region elements */
/* ------------------------------------- */

#category-level-1 a.refinement-link {
    background: none repeat scroll 0 0 transparent;
    padding: 0 0 0 10px;
}
#category-level-1 a.active {
    font-weight: bold;
}
#category-level-1 li.expandable {
    background: url("../images/interface/icon-category-expand-off.png") no-repeat scroll 2px 5px transparent;
}
#category-level-1 li.active {
    background-position: -490px 5px;
}
#category-level-2 a.active {
    color: #000000;
    font-family: georgia;
    font-style: italic;
    font-weight: normal;
}
#category-level-2 a.refinement-link {
    margin: 0 0 0 6px;
    padding: 0;
}
#category-level-2 li.expandable {
    background: url("../images/interface/icon-refine-link-arrow.png") no-repeat scroll left 6px transparent;
}
#category-level-2 li.active {
    background-position: left -47px;
    color: #000000;
    font-family: georgia;
    font-style: italic;
}
#category-level-3 {
    padding: 5px 0 0;
}
#category-level-3 a.refinement-link {
    font-style: normal;
    margin: 0 0 0 15px;
    padding: 0;
}
#category-level-3 a.active {} .refinement {
    position: relative;
    padding: 12px 0 4px;
}
.refinement ~ .refinement {
    border-top: 1px solid #c8c8c8;
}
.refinement-header {
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin: 0;
    padding: 14px 9px 9px;
    text-transform: uppercase;
}
.refinement h3 {
    color: @gray;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 9px;
    text-transform: uppercase;
}
.ie7 .refinement h3 {
    clear: both;
}
.mobile-contentnav {
    position: relative;
    z-index: 10;
}
.refinement h3 .arrow,
.mobile-contentnav .arrow,
.nav .toggle .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-width: 0 5px 7px 5px;
    border-style: solid;
    border-color: transparent transparent @gray transparent;
    margin: -2px 0 0 3px;
    vertical-align: middle;
}
.secondary-navigation-content h3 span.arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-width: 0 5px 7px 5px;
    border-style: solid;
    border-color: transparent transparent @gray transparent;
    margin: -2px 0 0 3px;
    vertical-align: middle;
}
.refinement h3.expanded .arrow,
.mobile-contentnav.expanded .arrow,
.nav .toggle.expanded .arrow {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.secondary-navigation-content h3.expanded span.arrow {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.refinement h3 .arrow,
.mobile-contentnav .arrow,
.nav .toggle .arrow {
    transition: 0.5s transform;
}
.secondary-navigation-content h3 span.arrow {
    transition: 0.5s transform;
}
.refinement ul {
    list-style: none outside none;
    margin: 0;
    padding-bottom: 6px;
    padding-left: 0;
}
.secondary-navigation-content h3 span {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    background: none;
}
.secondary-navigation ul li a {
    text-decoration: underline;
    color: @gray;
}
.pt_account .secondary-navigation ul li a:hover,
.pt_gift-registry .secondary-navigation ul li a:hover,
.pt_order .secondary-navigation ul li a:hover,
.pt_wish-list .secondary-navigation ul li a:hover {
    text-decoration: underline;
}
.refinement li a {
    background: url('../images/interface/checkbox-unchecked.png') no-repeat left center;
    padding-left: 20px;
}
.refinement li.selected a {
    background-image: url('../images/interface/checkbox-checked.png');
}
.refinement ul li {
    font-size: 14px;
    line-height: 1.25;
    padding-bottom: 3px;
}
.refinement ul li a {
    font-size: 11.5px;
    display: block;
    letter-spacing: -0.5px;
    padding: 0;
    padding-left: 20px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        color: @gray;
    }
}
.refinement ul.swatches li.selected a {
    border-color: #3EBAEA;
    background: url('../images/interface/checkbox-checked.png') no-repeat left center;
    text-decoration: none;
}
.refinement ul.swatches li.unselectable a {
    background-image: url("../images/interface/icon-color-swatch-unselectable.png");
    background-position: right top;
    text-decoration: none;
}
.refinement ul.Color li a {
    height: 23px;
    padding: 0;
    text-indent: -999em;
    width: 30px;
    text-decoration: none;
}
.refinement .clear-refinement {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 10px;
    font-size: 11px;
}
.refinement .clear-refinement a {
    padding: 0px;
    text-decoration: none;
}
.refinement .refinement-link {
    background: url("../images/interface/icon-refine-link-check.png") no-repeat scroll left top transparent;
    border: 0 none;
    padding: 0 0 0 1.5em;
}
.refinement .selected .refinement-link {
    background-position: left bottom;
}
.refinement .refinement-top-level {
    padding: 12px;
}
.scrollable {
    height: 10em;
    overflow: auto;
}
.nav ul {
    border: 0 none;
    list-style: none outside none;
    margin: 7px 0;
    padding: 0;
}
.nav ul li {
    list-style: none outside none !important;
    margin: 0 0 7px !important;
}
.nav .toggle {
    border: 0 none;
    display: block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 10px 0 7px;
    text-transform: uppercase;
    cursor: pointer;
    color: @gray;
}
.nav .toggle:hover {
    text-decoration: underline;
}
.pt_account .toggle:hover,
.pt_gift-registry .toggle:hover,
.pt_order .toggle:hover,
.pt_wish-list .toggle:hover {
    text-decoration: none;
    cursor: default;
}
.nav .expanded {
    background-position: left -96px;
}
.nav .toggle span {
    background: none repeat scroll 0 0 transparent;
}
/* footer */
/* ------------------------------------- */

#footer {
    clear: both;
    font-size: 12px;
    padding: 2em 0;
    text-align: center;
}
#footer .container {
    width: auto;
    float: left;
}
#footer .header {
    text-transform: uppercase;
    color: @black;
    font-size: 13px;
    text-align: left;
}
#footer .header a {
    text-decoration: none;
}
#footer .header a:hover {
    color: @lightgray;
}
#footer .topBorder {
    border-top: 1px solid @blue;
    width: auto;
    margin-top: 5px;
}
#footer ul.ul-footer {
    float: left;
    padding: 0px;
    margin-top: 15px;
}
#footer .ul-footer li {
    padding-bottom: 10px;
    display: block;
    text-align: left;
    color: @black;
    font-size: 12px;
}
#footer .ul-footer li a {
    color: @black;
    font-size: 12px;
    padding-bottom: 5px;
    text-align: left;
    text-decoration: none;
    transition: 0.3s all;
}
#footer .ul-footer li a:hover {
    color: @darkgray;
    padding-bottom: 5px;
    text-decoration: underline;
    transition: 0.3s all;
}

#footer .social-footer-box .ul-footer li a:hover {
    text-decoration: none;
}

#footer .header {
    text-decoration: none;
    text-align: left;
}
#footer .social-footer-box .facebook .header {
    float: left;
    padding-top: 20px;
}
#footer .social-footer-box .facebook .topBorder {
    float: left;
    clear: both;
}
#footer .header a:hover,
#footer .header a:active,
#footer .header a {
    text-decoration: none;
}
#footer .header:hover {
    text-decoration: none;
}
#footer .menu-footer {
    float: none;
    margin: 1em 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
}
#footer .menu-footer li {
    display: inline;
    float: none;
    padding: 0 0.5em;
}
#footer .mobile {
    display: none;
}
#footer .mobile ul.ul-footer-mobile {
    text-align: center;
    width: auto;
    margin: 0px auto;
    display: inline-block;
    padding: 0px !important;
}
#footer .mobile .ul-footer-mobile li {
    list-style-type: none !important;
    float: left;
    padding-right: 5px;
}
#footer .mobile .ul-footer-mobile li + li:before {
    content: " | ";
    padding-right: 5px;
}
#footer .mobile .ul-footer-mobile li a {
    text-decoration: none;
    color: @blue;
    &:hover {
        text-decoration: underline;
        color: @lightgray !important;
    }
}
#footer .social-footer-box {
    display: inline-block;
}
#footer .social-footer-box .container {
    padding-right: 0px;
    padding-left: 0px;
}
#footer .social-footer-box .container .container {
    clear: left;
}
#footer .desktop {
    letter-spacing: .5px;
}
.mobile {
    display: none;
}
#footer .pluginConnectTextDark {
    color: @blue;
}
.copyright {
    color: @blue;
}
.postscript {
    color: @blue;
    font-family: Georgia, serif;
    font-style: italic;
}
#footer .fb_iframe_widget span {
    overflow: visible!important;
    width: 450px!important;
    display: inline !important;
}
.fb_iframe_widget #u_0_3 {
    color: white !important;
}
/* color swatches */
/* ------------------------------------- */

.swatch-black a {
    background-color: #000000 !important;
}
.swatch-beige a {
    background-color: #F5F5DC !important;
}
.swatch-blue a {
    background-color: blue !important;
}
.swatch-purple a {
    background-color: purple !important;
}
.swatch-red a {
    background-color: red !important;
}
.swatch-brown a {
    background-color: #783201 !important;
}
.swatch-green a {
    background-color: green !important;
}
.swatch-grey a {
    background-color: #8F979D !important;
}
.swatch-pink a {
    background-color: #FE249A !important;
}
.swatch-orange a {
    background-color: orange !important;
}
.swatch-white a {
    background-color: #FFFFFF !important;
}
.swatch-yellow a {
    background-color: #FFFF00 !important;
}
.swatch-navy a {
    background-color: navy !important;
}
.swatch-miscellaneous a {
    background: url("../images/interface/icon-color-swatch-misc.png") no-repeat scroll left top transparent;
}
.unselectable.swatch-miscellaneous a {
    background: url("../images/interface/icon-color-swatch-misc-unselectable.png") no-repeat scroll left top transparent !important;
}
/* product tiles */
/* ------------------------------------- */

.product-tile-frame {
    position: absolute;
    width: 96%;
    height: 97%;
    opacity: 0.5;
    top: 3px;
    left: 4px;
    pointer-events: none;
    display: none;
}
.product-tile-hover a.thumb-link + a.thumb-link:hover .image1 {
    opacity: 0;
}
.product-tile-hover a.thumb-link img {
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}
.product-tile-hover a.thumb-link {
    position: absolute;
    left: 0;
}
.product-tile-mobile {
    display: none;
}
.product-tile {
    color: @gray;
    position: relative;
    height: 100%;
}
.product-tile .product-tile-content {
    border-top: 0;
    padding: 0 0 50px;
    display: flex;
    flex-direction: column;
}
.product-tile .product-image .thumb-link img {
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: -1;
    -moz-backface-visibility: hidden;
    box-shadow: #000 0em 0em 0em;
}
.product-tile .product-image {
    height: 260px;
    position: relative;
}
.product-tile .product-image a.thumb-link {
    position: absolute;
    height: 260px;
    top: 0;
    left: 0;
    display: block;
}
.product-tile .product-image a.thumb-link:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 0;
    border-bottom: 0;
    z-index: 5;
}
.product-tile .product-name {
    font: italic 1.2em;
}
.product-tile .product-name h2 {
    margin: 0px 0 5px 0;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
}
.product-tile .product-name a {
    line-height: 1.25;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.5s all;

    @media (min-width: 767px) {
        font-size: 14px;
    }
}
.product-tile .product-name a:hover {
    text-decoration: underline;
    color: @gray;
}
.product-tile .product-description {
    font-size: 1em;
    line-height: 1.2;
    text-align: left;
    margin: 0px;
}
.product-tile .product-pricing .product-pricingWrapper {
    text-align: left;
    padding: 5px 0 5px 0;
    font-size: 12px;
    font-weight: 500;
}
.product-tile .product-pricing .product-standard-price,
.product-tile .product-pricing .price-standard {
    text-decoration: line-through;
}
.product-tile .product-pricing .price-sales {
    padding-left: 5px;
    color: @red;
}
.product-tile .product-pricing .price-sales.regular {
    padding-left: 0px;
    color: #fff;
}
.product-tile .product-swatches {
    right: 0;
    display: none;
}
.product-tile .product-swatches-all {
    cursor: pointer;
    font-size: 0.9em;
}
.product-tile .product-swatches ul {
    list-style: none outside none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}
.product-tile .product-swatches ul.swatch-toggle {
    background: none repeat scroll 0 0 #FFFFFF;
    display: none;
    position: absolute;
}
.product-tile .product-swatches:hover ul.swatch-toggle {
    display: block;
}
.product-tile .product-swatches li {
    float: left;
}
.product-tile .product-swatches .swatch {
    display: block;
    width: 16px;
}
.product-tile .product-swatches .swatch img {
    height: 12px;
    padding: 1px;
    width: 12px;
}
.product-tile .product-swatches .selected img {
    border-color: @blue;
}
.product-tile .product-swatches img:hover {
    border-color: @blue;
}
.product-tile .product-promo {
    font-size: 1em;
    text-transform: uppercase;
    color: @red;
    text-align: left;
    margin: 0px;
    position: relative;
}
.product-tile .order-coupon-message {
    font-size: 1em;
    text-transform: uppercase;
    color: @red;
    text-align: left;
    margin: 0px;
    position: relative;
}
.pdp-main .order-coupon-message {
    display: inline-block;
    line-height: 1em;
    vertical-align: top;
    width: 77%;
    color: @red;
}
.product-tile .reviews-compare-bar {
    height: 35px;
}
.product-tile .product-compare {
    box-sizing: border-box;
    position: absolute;
    bottom: -2px;
    left: 9px;
    overflow: hidden;
    width: 100%;
    padding: 0 0px 13px;
    text-transform: lowercase;
}
.product-tile .product-compare .checkbox {
    float: left;
    padding-top: 0;
}
.product-tile .checkbox-small label:before {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(../images/sprite.png) -129px -235px no-repeat;
    content: '';
    vertical-align: middle;
    margin: 0 6px 0 0;
    filter: invert(100%);
}
.product-tile .checkbox-small input:checked + label:before {
    background-position: -143px -235px;
}
.product-tile .product-compare label {
    width: auto;
    float: none;
    padding: 0;
    color: @gray;
    display: inline-block;
    cursor: pointer;
    font-size: 11px;
}
.product-tile .product-compare input {
    margin: 0;
}
.product-tile .product-compare input[type="checkbox"] {
    display: none;
}
.product-tile .product-compare .compare-text {
    margin-left: 17px;
}
.product-tile .pr_stars {
    height: auto;
    position: relative;
    left: 8px;
}
.product-tile .prSnippetTitle {
    float: left;
}
.product-tile .prSnippetReadReviews {
    display: none;
}
.product-tile .prSnippetRatingUnclickable {
    float: left;
}
.product-tile .prSnippetNumberOfRatingsText {
    display: none;
}
.product-tile .pr-snippet-review-count {
    display: none;
}
.product-tile .pr-snippet-read-write {
    display: none;
}
.product-tile .pr-snippet-social-bar {
    display: none;
}
.product-tile .pr-clear {
    display: none;
}
.product-tile .pr-snippet-stars {
    float: none;
    margin: 0 auto;
    width: 115px;
}
.product-tile .BVInlineRatings {
    text-align: right;
    margin: 0 5px 2px 0;
    position: absolute;
    right: 0;
    bottom: 0;
    filter: invert(100%);
}
.capture-product-id {
    left: -999em;
    position: absolute;
}
.product-tile .product-image .productBadge {
    position: absolute;
    top: 0px;
    right: 0px;
    width: auto;
    height: auto;
}
.product-tile .product-image .product-tile-badge img {
    width: auto;
    height: auto;
}
/* search results, grid layout */
/* ------------------------------------- */

.pt_product-search-result .product-view-details {
    display: none;
}
.pt_product-search-result .product-tile .product-pricing .price-sales {
    padding-left: 0px;
    display: inline-block;
    width: 100%;
}
.pt_content-search-result ul.folder-content-list li {
    float: none;
}
.category-main-banner img {
    max-width: 100%;
}
.primaryContentSpacing {
    margin-top: 195px;
}
.pt_product-search-result #secondary {
    width: auto;
    border-bottom: 1px solid #c8c8c8;
    padding-top: 10px;
    margin: 30px 0 0 5px;
    background: url(../images/widget-filters.png) 0 0 no-repeat;
}
.form-filters {
    width: 148px;
    padding: 5px 7px 1px;
    border-top: 0;
    border-bottom: 0;

    a {
        color: @gray;
    }
}
.search-result-bookmarks {
    margin: 0px 15px 15px 0px;
}
.search-result-bookmarks .first {
    border-right: 1px solid #999999;
    padding-right: 5px;
}
.search-result-options {
    padding: 15px 0 16px;
    overflow: visible;
    width: 100%;
}
.didYouMean {
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 20px;
}
.error-page input {
    float: left;
}
.sort-by {
    float: right;
    min-width: 134px;
}
.sort-by select {
    opacity: 0;
}
.sort-by .selectboxit-container * {
    color: @gray;
}
.sort-by .selectboxit-btn {
    border: 1px solid @gray;
    background: transparent;
}
.sort-by .selectboxit-list {
    background-color: @white;
    border: 1px solid @gray;
    border-top: 0;
}
.sort-by .selectboxit-container span,
.sort-by .selectboxit-container .selectboxit-options a {
    box-sizing: border-box;
    background-color: transparent;
    height: 27px;
    line-height: 25px;
}
.sort-by .selectboxit-btn.selectboxit-enabled:hover,
.sort-by .selectboxit-btn.selectboxit-enabled:focus,
.sort-by .selectboxit-btn.selectboxit-enabled:active {
    color: @gray;
    background-color: transparent;
}

.sort-by .selectboxit .selectboxit-option-icon-container {
    margin-left: 5px;
}
.sort-by .selectboxit-option,
.sort-by .selectboxit-optgroup-header {
    text-indent: 5px;
    margin: 0;
    list-style-type: none;
}
@-moz-document url-prefix() {
    .sort-by .selectboxit-container option {
        color: #000;
    }
    .sort-by select {
        margin-left: 0px;
    }
}
.items-per-page {
    background: none repeat scroll 0 0 #D9D9D9;
    border-bottom: 1px solid #C2C2C2;
    float: left;
    margin: 0 0 0 20px;
    padding: 3px 5px;
}
.items-per-page label {
    display: none;
}
.items-per-page select {
    background: none repeat scroll 0 0 #D9D9D9;
    border: 0 none;
    font-size: 0.9em;
    margin: 0 6px 0 0;
    padding: 0;
    width: auto;
}
.sort-by button {
    background: none repeat scroll 0 0 #C2C2C2;
    border: 0 none;
    color: #333333;
    font-size: 0.9em;
    padding: 0.3em;
    width: auto;
}
.items-per-page button {
    background: none repeat scroll 0 0 #C2C2C2;
    border: 0 none;
    color: #333333;
    font-size: 0.9em;
    padding: 0.3em;
    width: auto;
}
.pagination .results-hits {
    float: left;
    padding: 20px 0;
}
.pagination {
    float: right;
    margin-top: -26px;
}
.pagination ul {
    float: right;
    margin: 0;
    padding: 0;
}
.pagination li {
    display: block;
    float: left;
    list-style: none outside none;
    padding: 0.5em;
}
.pagination li.first-last a {
    background-color: #00A4E3;
    color: #FFFFFF;
    font-size: 0.9em;
    padding: 0 2px;
}
.pagination .current-page {
    font-weight: bold;
}
ul.search-result-items {
    margin: 0;
    padding: 0;
    margin-right: -18px;
    display: flex;
    flex-wrap: wrap;
}
ul.search-result-items li {
    list-style: none outside none;
}
ul.search-result-items .grid-tile {
    background-size: 100%;
    margin: 0 16px 15px 0;
    padding: 3px;
    width: 254px;
}
ul.search-result-items .invisible {
    display: none;
}
.search-result-content {
    clear: both;
}
.search-promo {
    padding: 20px;
}
.readMoreText a:hover {
    text-decoration: underline;
}
h1#results-content {
    padding-top: 30px;
}
.search-results-content ol.folder-content-list {
    list-style: square outside;
}
.search-results-content ol.folder-content-list li {
    float: left;
    margin-bottom: 10px;
    width: 48%;
    clear: left;
    margin-left: 15px;
}
.search-results-content ol.folder-content-list li:nth-child(even) {
    clear: right;
    float: right;
}
ul.folder-content-list {
    margin: 0;
    overflow: hidden;
    padding-bottom: 25px;
}
ul.folder-content-list li {
    float: left;
    padding-bottom: 30px;
    display: block;
    clear: left;
}
ul.folder-content-list li:nth-child(even) {
    clear: right;
}
ul.folder-content-list .content-title {
    display: block;
}
.folder-content-list .readMoreDescription {
    padding-top: 5px;
}
.backtolink {
    margin-bottom: 5px;
}
.no-results {
    padding: 20px;
}
.wide-tiles ul.search-result-items .grid-tile {
    width: 91%;
}
.wide-tiles .product-tile {
    height: 166px !important;
}
.wide-tiles .product-tile .product-image {
    float: left;
    width: 40%;
}
.wide-tiles .product-tile .product-name,
.wide-tiles .product-tile .product-pricing,
.wide-tiles .product-tile .product-promo,
.wide-tiles .product-tile .pr_stars,
.wide-tiles .product-tile .product-swatches {
    float: left;
    text-align: left;
    width: 55%;
    margin-bottom: 5px;
}
.wide-tiles .product-tile .product-name {
    margin-top: 0px;
}
.wide-tiles .product-tile .pr-snippet-stars {
    margin: 0;
}
.wide-tiles .product-tile .pr_stars {
    left: -5px;
}
.wide-tiles .product-tile .product-compare {
    left: 40.5%;
    width: 32px;
    bottom: 5px;
    line-height: 12px;
}
.wide-tiles .product-tile .product-swatches {
    bottom: auto;
    left: auto;
    position: relative;
    right: auto;
    margin-top: 5px;
}
.wide-tiles .product-tile .product-swatches li {
    float: left;
}
/* infinite scroll amendments */
/* ------------------------------------- */

.infinite-scroll .pagination ul {
    display: none
}
.infinite-scroll .pagination .current-page-label {
    display: none
}
.infinite-scroll .items-per-page {
    display: none
}
.infinite-scroll div.search-result-content .infinite-scroll-loading {
    text-align: center;
    background: url("../images/infinite-loading-indicator.gif") center no-repeat;
    height: 35px;
    width: 100%
}
.disable-infinite-scroll div.search-result-content .page-element {
    display: none
}
/* compare tools */
/* ------------------------------------- */

.compare-item-remove {
    display: none;
}
.active .compare-item-remove {
    display: inline;
}
.compare-item {
    background: none repeat scroll 0 0 #878787;
    float: left;
    height: 100px;
    margin-right: 13px;
    overflow: hidden;
    position: relative;
    width: 100px;
}
#compare-items.compareitems {
    background-color: @blue;
    color: #fff;
}
.compareitems {
    height: 138px;
    background: url(../images/interface/compare-box-background.png) no-repeat scroll 0 0;
    clear: both;
    margin: 3.5em 0em 0;
    overflow: hidden;
    padding: 0 1em 1em;
}
.compareitems h2 {
    font-size: 0.9em;
    margin: 0.5em 0;
    font-weight: normal;
}
.compareitemcount {
    color: #CCCCCC;
    font-size: 3em;
    left: 30%;
    position: absolute;
    top: 15%;
    z-index: 1;
}
.compareproduct {
    max-width: 100%;
    position: absolute;
    z-index: 2;
    width: 100px;
    height: 100px;
}
.compareitemremove {
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 3;
}
.compare-buttons {
    padding: 0.7em 0 0;
    text-align: center;
}
.primary-content .compare-header {
    font-size: 16px;
    text-transform: none;
}
.primary-content .back {
    color: #878787;
    text-decoration: none;
}
.primary-content .compare-print {
    display: none;
}
.compare-buttons #compare-items-button {
    font-size: 1.2em;
    margin-bottom: 16px;
    padding: 12px 20px;
}
.compare-buttons #clear-compared-items {
    background: transparent;
    text-decoration: underline;
    color: @white;
    font-weight: normal;
}
.compare-print {
    float: right;
    margin: 0 0 10px;
}
#compare-table {
    margin-bottom: 15px;
}
#compare-table td {
    -moz-border-bottom-colors: none;
    -moz-border-image: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: -moz-use-text-color #D9D9D9 #D9D9D9;
    border-style: none solid solid;
    border-width: 0 1px 1px;
    width: 159px;
    text-align: center;
}
#compare-table td.value {
    font-size: 12px;
}
#compare-table .norightborder {
    border-right: 0px;
}
#compare-table .yellow-arrow-small {
    margin: 0px 5px;
}
#compare-table td.productattribute {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    vertical-align: middle;
}
#compare-table td.nocontentdash {
    vertical-align: middle;
}
#compare-table td .product-tile {
    font-size: 0.9em;
    width: 100%;
    border: 0px;
}
#compare-table td .product-tile .product-image {
    height: 159px;
}
#compare-table td .product-tile .product-image .thumb-link img {
    height: 159px;
}
#compare-table td .product-tile .product-swatches {
    float: none;
}
#compare-table td.product button {
    font-size: 0.9em;
}
#compare-table td.product .product-name {
    font-size: 12px;
    font-weight: bold;
}
#compare-table td.product .product-description {
    display: none;
}
#compare-table td.product .compare-product-actions {
    text-align: left;
}
#compare-table td.product .compare-product-actions button.button-fancy-small {
    background: transparent;
    text-align: left;
    color: @blue;
    font-size: 14px;
    font-weight: bold;
    padding-top: 0px;
}
#compare-table .compare-lists,
#compare-table .productattribute {
    border-left: 0px;
}
#compare-table label {
    margin-bottom: 10px;
}
#compare-table .compare-lists form {
    width: 200px;
    text-align: left;
}
#compare-table .compare-lists .selectboxit-container,
#compare-table .compare-lists .selectboxit-container .selectboxit {
    max-width: 200px;
}
#compare-table select {
    opacity: 0;
}
#compare-table .selectboxit-container * {
    color: #fff;
}
#compare-table .selectboxit-btn {
    border: 1px solid #b5b5b5;
    background: transparent;
}
#compare-table .selectboxit-list {
    background: url(../images/body.jpg) 0 0 repeat;
    border: 1px solid #b5b5b5;
    border-top: 0;
}
#compare-table .selectboxit-arrow-container {
    top: 10px;
    right: 9px;
    width: 12px;
    height: 8px;
    background: url(../images/sprite.png) -129px -188px no-repeat;
}
#compare-table .selectboxit-default-arrow {
    display: none;
}
#compare-table .selectboxit-container span,
#compare-table .selectboxit-container .selectboxit-options a {
    box-sizing: border-box;
    background-color: transparent;
    height: 27px;
    line-height: 25px;
}
#compare-table .selectboxit-btn.selectboxit-enabled:hover,
#compare-table .selectboxit-btn.selectboxit-enabled:focus,
#compare-table .selectboxit-btn.selectboxit-enabled:active {
    background-color: transparent;
}
#compare-table .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
    color: @white;
    background: @blue;
}
#compare-table .selectboxit .selectboxit-option-icon-container {
    margin-left: 5px;
}
#compare-table .selectboxit-option,
#compare-table .selectboxit-optgroup-header {
    text-indent: 5px;
    margin: 0;
    list-style-type: none;
}
#compare-table .compareremovecell {
    border-bottom: 0px;
}
#compare-table .lastattribute td {
    border-bottom: 0px;
}
#compare-table .section-header {
    font-size: 0.9em;
    padding: 1em;
    text-transform: none;
}
#compare-table .selectcategory label {
    display: block;
    font-size: 0.9em;
    margin: 1em 0;
    text-transform: none;
}
#compare-table .selectcategory select {
    margin: 0;
    width: 100px;
}
#compare-table .comparelists {
    padding: 0;
}
#compare-table .remove-link {
    background: url("../images/interface/icon-mini-close.png") no-repeat scroll right top transparent;
    display: block;
    padding: 0 20px 0 0;
    text-align: right;
    color: transparent;
}
#compare-table .add-to-wishlist,
#compare-table .add-to-gift-registry {
    padding: 0.2em 0;
    text-align: left;
    text-decoration: underline;
}
#compare-table .compare-lists label,
#compare-table .compare-lists select {
    float: none;
    text-align: left;
    width: 200px;
}
#compare-table td.product {
    padding: 5px;
}
#compare-table .product-tile .product-image a.thumb-link:after {
    display: none;
}
#compare-table .product-tile .product-image a.thumb-link {
    width: 100%;
}
#compare-table #quickviewbutton {
    display: none;
}
#compare-table .emptyspotimage {
    opacity: 0.5;
}
#compare-table .product-tile .BVInlineRatings {
    margin-top: 33px;
    margin-bottom: 0px;
    position: relative;
    text-align: left;
}
#compare-table .product-tile .product-pricing .price-sales {
    padding-left: 0px;
    width: 100%;
    display: block;
}
#compare-table .product-tile .product-pricing .price-standard {
    width: 100%;
    display: block;
}
.pt_compare #wrapper {
    width: 100%;
}
.pt_compare #main {
    width: 1000px;
    margin: 0 auto;
}
/* power reviews */
/* ------------------------------------- */

body .pr-snippet-wrapper {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
}
body .pr-snippet-stars {
    border: 0 none;
}
body .pr-snippet-review-count {
    display: none;
}
body .pr-snippet-read-write {
    border: 0 none;
    clear: none;
    float: left;
    font-size: 1.1em;
    margin: 0;
    padding: 4px 0;
}
body .pr-snippet-read-write p {
    display: none;
}
body .pr-snippet-read-write a.pr-snippet-link {
    color: #3EBAEA;
}
body .review-image {
    float: left;
    margin: 0 20px;
}
/* Quick View */
/* ------------------------------------- */

body > #QuickViewDialog {
    display: none;
}

#QuickViewDialog .pricing-button-block .product-price {
    float: none;
    margin: auto;
    width: 100%;
}
#QuickViewDialog .pricing-button-block button {
    float: none;
}
#QuickViewDialog .product-add-to-cart .availability {
    float: none;
    padding-top: 20px;
}
#QuickViewDialog .product-add-to-cart .product-view-button {
    float: left;
    clear: left;
    padding-top: 5px;
}
#QuickViewDialog .product-add-to-cart .product-number {
    float: left;
    padding-top: 17px;
}
.js .quickview {
    padding: 0 7px;
}
.js .quickview .ui-dialog-titlebar-close {
    right: -6px;
}
/* product detail page (pdp) */
/* ------------------------------------- */

#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;
}
#product-nav-container div {
    float: left;
    text-align: center;
    width: 77px;
}
#product-nav-container img {
    max-height: 100%;
    max-width: 80%;
}
#product-nav-container span {
    display: block;
    height: 65px;
}
#product-nav-container .divided span {
    border-right: 1px solid #E0E0E0;
    padding-right: 5px;
}
.pdp-main ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
.pdp-main .pdp-top {

    margin-top: 20px;
    padding: 30px 0;
    display: flex;
    flex-direction: row-reverse;
    &.product-set {
        flex-direction: row;
    }
    @media (max-width: @mobile-max) {
        flex-direction: column-reverse;
        &.product-set {
            flex-direction: column;
        }
    }
}
.pdp-main label {
    text-align: left;
    width: auto;
}
.pdp-main .product-col-1 {
    flex-grow: 2;
    margin: 10px 10px 3px 0;
    @media (min-width: @desktop-min) {
        margin: 10px 10px 13px 0;
    }
}
.pdp-main .product-primary-image {
    max-width: 100%;
    text-align: center;
    position: relative;
}
.pdp-main .product-primary-image-frame,
#recommendations-carousel .product-tile .product-image .thumbnail-frame {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.primary-image {
    max-width: 100%;
    display: block;
}
.pdp-main img.primary-image {
    width: 100%;
}
.pdp-main .product-thumbnails li {
    padding: 0 10px 10px;
}
.pdp-main .product-thumbnails li.selected img {
    border-color: @blue;
}
.pdp-main .product-thumbnails li a {
    outline: none;
}
.pdp-main .product-thumbnails li img {
    border: 1px solid #CCCCCC;
    display: block;
}
.pdp-main .product-thumbnails li.selected .thumbnail-frame {
    position: absolute;
    width: 90%;
    height: 85%;
    border: 2px solid #7baf54;
    top: 4px;
    left: 1px;
}
.pdp-main .product-thumbnails h2 {
    text-transform: uppercase;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-size: 1.5em;
    font-style: normal;
    font-weight: bold;
    margin: .83em 0;
}
.pdp-main .product-col-2 {
    @media (min-width: @desktop-min) {
        max-width: 350px;
        margin: 10px 0 10px 10px;
    }
}
.pdp-main form .input-select {
    width: auto;
}
.pdp-main .product-name {
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0;
    text-transform: none;
    margin-top: -3px;
}
.pdp-main .product-description {
    font-size: 14px;
    margin-top: 15px;
}
.pdp-main .product-number {
    color: @gray;
    font-size: 10px;
    clear: left;
    margin: 10px 0;
}
.pdp-main .product-price {
    font-size: 2em;
    font-weight: 500;
    clear: both;
}
.pdp-main .product-price .price-standard {
    color: @gray;
    text-decoration: line-through;
}
.pdp-main .product-price .price-tiered {
    color: @red;
    font-size: 0.6em;
    line-height: 1.5em;
}
.pdp-main .inventory {
    float: left;
    width: 100%;
}
.inventory .quantity {
    .adjust-qty {
        .quickview-dialog & {
            display: none;
        }
        background: var(--color-cta);
        width: 32px;
        height: 32px;
        border: 1px solid var(--color-cta);
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
        line-height: 32px;
        font-size: 18px;
        color: @white;
        user-select: none;
        font-weight: 600;
        &.quantity-minus {
            border-right: none;
        }
        &.quantity-plus {
            border-left: none;
        }

        &:hover {
            opacity: .9;
        }
    }

    display: flex;
    align-items: end;
    position:relative;

    @media (max-width: @mobile-max) {
        height: 45px;

        .adjust-qty {
            box-sizing: border-box;
            height: 45px;
            line-height: 40px;
            width: 50px;
        }
    }
}

.pdp-main .product-review-links li {
    margin-right: 1em;
}
.pdp-main .attribute {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1em 0;
    overflow: hidden;
    padding: 0;
    position: relative;
}
.pdp-main .attribute h3,
.pdp-main .attribute .label {
    clear: both;
    font-size: 12px;
    margin: 0;
    padding: 6px 0;
    letter-spacing: 0.5px;
}
.pdp-main .attribute .value {
    flex-grow: 1;
}
.pdp-main .attribute ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.pdp-main .attribute .selected-value {
    border: 0 none;
    color: #fff;
    font-style: italic;
    padding: 0.5em 0 0 2em;
}
.pdp-main .attribute .size-chart-link {
    clear: both;
    float: none;
}
.pdp-main .attribute .size-chart-link a {
    border-color: #FFFFFF !important;
    padding: 0;
}
.pdp-main .swatches li {
    float: left;
}
.pdp-main .value.size .swatches li {
    width: 50%;
}
.pdp-main .swatches li:hover a,
.pdp-main .swatches li.selected a {
    background-color: #FFF;
    color: @blue;
    font-weight: 500;
    border-color: @blue;
    text-decoration: none;
}
.pdp-main .swatches li.unselectable a, .pdp-main .swatches li.oos a {
    opacity: 0.3;
    text-decoration: line-through;
}
.pdp-main .swatches li.unselectable:hover a, .pdp-main .swatches li.oos:hover a {
    border-color: @gray;
    text-decoration: line-through;
}
.pdp-main .swatches li.unselectable:hover, .pdp-main .swatches li.oos:hover {
    border-color: @gray;
}
.pdp-main .swatches li a {
    border-color: @gray;
    border-style: solid;
    border-width: 1px;
    display: block;
    margin: 0 0.3em 0.3em 0.2em;
    padding: 0.8em 0.3em;
    text-decoration: none;
    text-align: center;
}
.pdp-main .swatches li a:hover {
    text-decoration: none;
}
.pdp-main .pricing-button-block {
    @media (min-width: @tablet-min) {
        width: calc(100% - 0.3em);
    }
}

#QuickViewDialog .pdp-main .pricing-button-block {
    bottom: 25px;
}

.pdp-main .pricing-button-block .product-price {
    float: none;
    margin: auto;
    width: 100%;
}
.pdp-main .pricing-button-block button {
    float: none;
}
.pdp-main .product-add-to-cart .availability {
    float: none;
    padding-top: 20px;
}
.pdp-main .product-add-to-cart .product-view-button {
    float: left;
}
.pdp-main .Color li:hover,
.pdp-main .Color li.selected {
    border-color: #00A4E4;
}
.pdp-main .Color li:hover a,
.pdp-main .Color li.selected a {
    border-width: 2px;
    border-style: solid;
    width: 33px;
    height: 33px;
}
.pdp-main .Color li a {
    height: 35px;
    padding: 0;
    text-indent: -999em;
    width: 35px;
}
.pdp-main .product-main-attributes .attribute {
    padding: 0.2em 0;
}
.pdp-main .product-main-attributes .label {
    padding: 0;
}

.pdp-main .product-add-to-cart {
    background-size: 100%;
    clear: both;
}
.pdp-main .product-set .product-add-to-cart {
    background-size: 100%;
    clear: both;
    margin: 1em 0;
    overflow: hidden;
    padding: 20px 0 10px 0;
    border-top: 1px solid @gray;
}
.ui-dialog .pdp-main .product-add-to-cart .view-details {
    clear: both;
    float: left;
    font-weight: bold;
    padding: 10px;
    text-decoration: none;
    margin-top: 1em;
}
.product-set .product-add-to-cart {
    display: block;
}
.pdp-main .product-add-to-cart .inventory {
    float: left;
    width: 100%;
}
.pdp-main .product-add-to-cart .inventory label,
.pdp-main .product-set .inventory label {
    float: left;
    font-weight: 400;
    font-size: 12px;
    padding: 10px 10px 10px 0;
    letter-spacing: 0.5px;
}
.pdp-main .product-add-to-cart .inventory input,
.pdp-main .product-set .inventory input {
    margin: 0;
    width: 40px;
    height: 32px;
    padding: 0;
    border: 1px solid @gray;
    background: transparent;
    color: @gray;
    text-align: center;

    @media (max-width: @mobile-max) {
        height: 43px;
    }
}
.pdp-main .product-add-to-cart .product-price {
    font-size: 18px;
    margin: 0;
    text-align: center;
}
.pdp-main .product-add-to-cart .price-sales {
    padding-left: 16px;
    color: @red;
}
.pdp-main .product-add-to-cart button {
    float: right;
    margin-top: 0.5em;
    border: none;
    width: 100%;
    font-size: 2em;
}
.pdp-main .product-add-to-cart .availability {
    position: relative;
    float: left;
    padding-top: 14px;
    clear: left;
    width: 60%;
}
.pdp-main .product-add-to-cart .availability.error .availability-msg p {
    color: @error;
}
.pdp-main .product-add-to-cart .availability .label {
    position: absolute;
    left: 0px;
}
.availability .availability-msg p {
    display: inline;
    color: @gray;
}
.availability .availability-msg {
    display: inline;
}
.availability .in-stock-msg {
    color: black
}
.availability .not-available-msg {
    color: @error
}
.availability .preorder-msg {
    color: blue
}
.availability .backorder-msg {
    color: green
}
.availability .in-stock-date-msg {
    color: @error
}
.pdp-main .product-actions {
    background-size: 100%;
    clear: both;
    overflow: hidden;
    padding: 1.5em 0;
}
.pdp-main .product-actions li {
    display: inline;
    font-size: 12px;
}
.pdp-main .product-actions li a {
    text-decoration: underline;
}
.pdp-main .product-actions .social-actions a {
    text-decoration: none;
}
.pdp-main .product-actions .social-actions {
    float: right;
    margin-top: 5px;
}
.pdp-main .product-actions .social-actions li {
    margin-left: 5px;
}
.pdp-main .product-actions .social-actions li:first-child {
    margin-left: 0;
}
.pdp-main .product-actions .lists {
    float: left;
}
.pdp-main .product-actions .lists li {
    margin-left: 10px;
}
.pdp-main .product-actions .lists li:first-child {
    margin-left: 0;
}
.pdp-main .product-actions .addthis_toolbox {
    float: right;
}
.pdp-main .product-actions li .amazon-wishlist-btn {
    display: inline;
}
.pdp-main .recommendations h2 {
    font-size: 20px;
    margin-left: 14px;
    font-weight: normal;
    margin-bottom: 3px;
}
.pdp-main .recommendations li {
    float: left;
    width: 100px;
}
.pdp-main .recommendations .product-tile {
    padding: 0 0.5em;
    border: none;
}
.pdp-main .recommendations .product-image {
    height: auto;
}
.pdp-main .recommendations .product-image img {
    width: 100%;
    display: block;
}

.pdp-main .recommendations .product-name {
    font-size: 1em;
    margin: 0.2em 0;
    width: auto;
}
.pdp-main .recommendations .product-price {
    font-size: 1em;
}
.pdp-main .recommendations .jcarousel-clip {
    margin: auto;
    overflow: hidden;
    width: 300px;
}
.pdp-main .recommendations .jcarousel-prev {
    cursor: pointer;
    height: 17px;
    left: 0;
    position: absolute;
    top: 20%;
    width: 9px;
}
.pdp-main .recommendations .jcarousel-next {
    cursor: pointer;
    height: 17px;
    position: absolute;
    right: 0;
    top: 20%;
    width: 9px;
}
.pdp-main .recommendations .jcarousel-prev-disabled {
    background: none repeat scroll 0 0 transparent;
}
.pdp-main .recommendations .jcarousel-next-disabled {
    background: none repeat scroll 0 0 transparent;
}
.pdp-main .recommendations .product-tile:hover .recommendation-tooltip {
    display: block !important;
}
.js .pdp-main .recommendations .product-tile .recommendation-tooltip {
    display: none !important;
}
.pdp-main .recommendations .recommendation-tooltip {
    background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid;
    left: 50%;
    position: absolute;
    text-align: left;
    top: 50%;
    width: 300px;
    z-index: 100;
}

.pdp-main .promotion {
    /*background:url("../images/interface/ui-promo-bg-gray-diag.png") repeat scroll 0 0 transparent;*/

    clear: both;
    /*margin:1em 0;padding:4%;*/
}
.pdp-main .promotion .promotion-title {
    color: #999999;
    display: inline-block;
    font-size: 1.5em;
    line-height: 1em;
    text-transform: uppercase;
    vertical-align: top;
    width: 20%;
}
.pdp-main .promotion .promotion-callout {
    display: inline-block;
    line-height: 1em;
    vertical-align: top;
    width: 77%;
    font-size: 15px;
    color: @red;
}
.pdp-main .product-tabs {
    border: 0 none;
    margin: 25px 0 0;
}
.print-page {
    cursor: pointer;
}
.pdp-main .product-tabs .print-page {
    float: right
}
.list-table-header .print-page {
    font-size: 13.2px;
    float: right;
}
.pt_gift-registry .list-table-header .print-page {
    margin-top: -0.5em;
}
.pt_gift-registry .require-dialog {
    position: static;
    text-align: right;
    color: @red;
}
.pdp-main .product-set .product-number {
    display: none;
}
.pdp-main .product-set .selected-value {
    display: none;
}
.pdp-main .product-tabs .tab-content {
    min-height: 100px;
}
.pdp-main .product-tabs .tab-content ul {
    list-style-type: disc;
    margin-left: 2em;
}
.pdp-main .product-tabs .tab-content ol {
    list-style-type: decimal;
    margin-left: 2em;
    padding: 0px;
}
.pdp-main .product-set-list {
    margin: 0 0 0 6px;
}
.pdp-main .product-set-list .wlar-block {
    width: 29%;
    text-align: center;
    letter-spacing: 0.5px;
}
.ui-dialog .pdp-main .product-set-list .wlar-block {
    text-align: left;
}
.pdp-main .product-set-list .wlar-block li {
    margin: 8px 0px;
}
.pdp-main .product-set-list .product-set-item {
    background: url("../images/pdp-white-divider.png")no-repeat;
    clear: both;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.pdp-main .product-set-list .product-set-image {
    float: left;
    width: 29%;
    margin: 20px 0px;
}
.pdp-main .product-set-list .product-set-image img {
    width: 100%;
    border: 1px solid #7baf54
}
.pdp-main .product-set-list .product-set-details {
    float: left;
    width: 67%;
    margin: 20px 0px 0px;
    padding: 0px 10px;
}
.pdp-main .product-set-list .pdp-dimensions-label {
    padding-left: 0px;
    margin: 6px 0px;
}
.pdp-main .product-set-list .attribute h3,
.pdp-main .product-set-list .attribute .label {
    width: 25%;
}
.pdp-main .bundle .attribute .label {
    width: initial;
    padding: 0px 5px 0px 0px;
}
.pdp-main .product-set-list .product-name {
    float: none;
    margin: 0;
    width: auto;
    font-size: 1.3em;
    letter-spacing: 0.5px;
}
.pdp-main .product-set-list .promotion-title {
    font-size: 1.2em;
}
.pdp-main .product-set-list label {
    padding: 0.5em 0;
}
.pdp-main .product-set-list .block-add-to-cart {
    overflow: hidden;
    padding: 0.5em 0;
    float: left;
    width: 67%;
    position: relative;
}
.pdp-main .product-set-list .block-add-to-cart .inventory {
    float: left;
    padding-left: 10px;
}
.pdp-main .product-set-list .block-add-to-cart label {
    padding: 0.5em 0;
    text-align: left;
}
.pdp-main .product-set-list .block-add-to-cart .input-text {
    width: 35px;
}
.pdp-main .product-set-list .block-add-to-cart .product-price {
    font-size: 1.2em;
    margin: 0;
    padding: 6px 0 0;
    text-align: center;
    display: block;
}
.pdp-main .product-set-list .block-add-to-cart .product-price .price-sales {
    color: @red;
}
.pdp-main .product-set-list .block-add-to-cart .product-price .price-standard {
    padding-right: 10px;
}
.pdp-main .product-set-list .block-add-to-cart button {
    float: right;
    font-size: 0.9em;
    margin: 3px 5px 0 0;
    padding-left: 1em;
    padding-right: 1em;
    clear: right;
}
.pdp-main .product-set-list .block-add-to-cart button.button-product-set-single {
    background: url("../images/button-white.png");
    background-size: 100% 100%;
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: normal;
    text-transform: uppercase;
}
.pdp-main .product-set-list .product-number {
    clear: left;
    display: block;
    padding: 10px 10px;
}
.pdp-main .product-set-list .availability {
    padding: 5px 10px 0px;
}
.pdp-main .product-set-list .button-price-box {
    float: right;
    margin-top: 20px;
}
.pdp-main .product-set .product-add-to-cart .product-price {
    display: block;
}
.pdp-main .product-set .product-add-to-cart .standardprice {
    text-decoration: line-through;
    float: left;
    padding-left: 80px;
}
.pdp-main .product-set .product-add-to-cart .uncrossed {
    text-decoration: none !important;
}
.ui-dialog .pdp-main .product-set .product-add-to-cart .standardprice {
    padding-left: 0;
}
.pdp-main .product-set .product-add-to-cart .salesprice {
    color: @red;
    float: right;
}
.ui-dialog .pdp-main .product-set .product-add-to-cart .salesprice {
    margin-left: 2em;
}
.pdp-main .product-set .product-add-to-cart .product-price {
    width: 45%;
    float: left;
    text-align: right;
    margin-top: 20px;
}
.ui-dialog .pdp-main .product-set .product-add-to-cart .product-price {
    width: auto;
    float: right;
    margin-right: 1.5em;
    margin-top: 0;
}
.pdp-main .product-set .inventory input {
    float: right;
}
.pdp-main .product-set .product-add-to-cart button.button-yellow-ps {
    background-color: @blue;
    color: @white;
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: normal;
    text-transform: uppercase;
}
.pdp-dimensions-label {
    font-size: 1.2em;
    margin-top: 5px;
    flex-grow: 1;
}
.pdp-main .pdp-row-first {
    margin-top: 15px;
    padding-top: 30px;
    width: 100%;
    border-top: 1px solid @gray;
    display: flex;
}

.pdp-main .pdp-column-left {
    width: 50%;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    margin-top: 2px;
}
.pdp-main .pdp-column-right {
    display: flex;
    @media (max-width: @tablet-max) {
        flex-direction: column;
    }
}
.pdp-main .pdp-row-first .pdp-column-right .content-asset img {
    filter: invert(100%);
}
.pdp-main .pdp-row-first .pdp-column-right .content-asset > div {
    display: table;
    height: 145px;
    width: 455px;
}
.pdp-main .pdp-row-first .pdp-column-right .content-asset > div > div {
    display: table-cell;
    vertical-align: middle;
}
.pdp-main .pdp-row-first .bvReviewWrapper {
    width: 455px;
}
.pdp-main .pdp-row-first .bvReviewContainer {
    position: relative;
    padding: 0 65px;
}
.pdp-main .pdp-row-first .bvReviewLeftQuote {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}
.pdp-main .pdp-row-first .bvReviewRightQuote {
    position: absolute;
    display: block;
    bottom: 15px;
    right: 8px;
}
.pdp-main .pdp-row-first .bvReviewHeader {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 15px;
    padding-top: 5px;
    text-transform: uppercase;
}
.pdp-main .pdp-row-first .bvReviewDescription,
.pdp-main .pdp-row-first .bvReviewFooter {
    font-size: 12px;
    line-height: 15px;
    font-style: italic;
}
.pdp-main .pdp-row-first .bvReviewFooter {
    padding-top: 15px;
}

.pdp-main .pdp-row-third .pdp-column-left {
    margin-top: 20px;
}


.pdp-main .pdp-row-third,
.pdp-main .pdp-row-fourth {
    background: transparent url('../images/FullDivide_Feather.png') no-repeat left top;
    padding-top: 30px;
}
.pdp-main .pdp-row-first .pdp-column-right .content-asset {
    position: relative;
    padding: 5px;
}
.pdp-main .pdp-row-first .pdp-column-right .content-asset div div {
    position: relative;
}
.pdp-main .pdp-row-first .pdp-column-right .content-asset p {
    line-height: 1.1em;
}
.pdp-main .pdp-row-first .pdp-column-right .quote_left,
.pdp-main .pdp-row-first .pdp-column-right .quote_right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.pdp-main .pdp-row-first .pdp-column-right .quote_left {
    background: transparent url('../images/quote-left.png') top left no-repeat;
}
.pdp-main .pdp-row-first .pdp-column-right .quote_right {
    background: transparent url('../images/quote-right.png') 100% 70% no-repeat;
}
.pdp-main .pdp-attributes-table td {
    margin: 0;
    padding: 0;
    padding-top: 30px;
    font-size: 14px;
}
.pdp-main .pdp-attributes-table td p {
    letter-spacing: 0px;
}
.pdp-main .pdp-attributes-table td.label {
    text-align: right;
    padding-right: 15px;
    width: 1px;
    letter-spacing: 1px;
}
.pdp-main .BVRRProductSocialBookmarkGooglePlusOneButtonContainer {
    display: none;
}
.pdp-main .BVDI_PLProductImage {
    width: auto;
    height: auto;
}
.cat-product-slot-bottomHeader {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 30px;
    left: 170px;
}
.cat-product-slot-bottomDescription {
    font-size: 13px;
    position: absolute;
    top: 60px;
    left: 170px;
}
.pdp-buyNow-button {
    float: right;
    padding-right: 30px;
    padding-top: 10px;
}
.pdp-buyNow-button-disabled {
    opacity: 0.5;
    float: right;
    padding-right: 30px;
    padding-top: 10px;
}
#recommendations-carousel {
    overflow: hidden;
    width: 100%;
}
#recommendations-carousel .product-tile {
    float: left;
    position: relative;
    width: 200px;
}
#recommendations-carousel .product-tile .product-name {
    margin: 3px 0 2px;
    font-size: 11px;
}
#recommendations-carousel .product-tile .product-name h2 {
    font-size: 1.1em !important;
    font-weight: normal;
    margin-bottom: 5px;
}
#recommendations-carousel .product-tile .product-pricing .product-standard-price {
    text-decoration: line-through;
}
.pdp-main .recommendations {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
}
.pdp-main .recommendations img.divider {
    width: 100%;
}
.pdp-main .recommendations a.heroPrev,
.pdp-main .recommendations a.heroNext {
    position: absolute;
    top: 50%;
    height: 13px;
    width: 24px;
}
.pdp-main .recommendations a.heroPrev {
    background-image: url('../images/slider-small-left.png');
    left: 0;
}
.pdp-main .recommendations a.heroNext {
    background: transparent url('../images/slider-small-right.png') no-repeat right center;
    right: 0;
}
.pdp-detailsTable {
    width: auto;
    font-size: 13px;
    margin-top: 10px;
}
.pdp-detailsTable td:first-child {
    float: right;
}
.add-to-cart-button {
    float: right;
    margin-top: 10px;
    padding-right: 30px;
}
.add-to-cart-button-disabled {
    float: right;
    margin-top: 10px;
    opacity: 0.5;
    padding-right: 30px;
}
.pdp-dimensions-label {
    float: left;
    font-size: 12px;
    margin-top: 6px;
}
.pdp-dimensions-label span {
    white-space: nowrap;
}
.pdpForm fieldset {
    display: flex;
    flex-direction: column;
}
button.add-to-cart[disabled],
button.add-all-to-cart[disabled] {
    opacity: 0.5;
}
button.add-all-to-cart {
    display: none;
}
/* not available in simple ui. */

.js button.add-all-to-cart {
    display: inherit;
}
/* available in rich ui. */

.amazon-wishlist-btn div {
    display: none;
}
.amazon-wishlist-btn a {
    width: 34px;
    height: 34px;
    display: inline-block;
}
.pdp-slider {
    height: 145px;
    overflow: hidden;
}
.pdp-slider .slide {
    float: left;
}
.pdp-slider .slide img {
    width: 490px;
    padding: 0 5px;
}
.pdp-slider-prev {
    background: url(../images/LeftFeather.png) center center no-repeat;
    left: -14px;
}
.pdp-slider-next {
    background: url(../images/RightFeather.png) center center no-repeat;
    right: -14px;
}
.pdp-slider-prev,
.pdp-slider-next {
    width: 25px;
    height: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    background-size: 25px 15px;
    display: none!important;
}
.pdp-main .tooltip .tooltip-content {
    background-color: @white;
    border-radius: 0;
    color: @gray;
}
.pdp-main .tooltip .tooltip-content h3 {
    font-size: 16px;
    margin: 0;
    padding: 0;
    padding-bottom: 1em;
}
.button-price-box .mobile {
    display: none;
}
a.tooltipx {
    outline: none;
    text-decoration: none;
}
a.tooltipx > span {
    width: 200px;
    padding: 10px 20px;
    margin-top: 20px;
    margin-left: -85px;
    opacity: 0;
    visibility: hidden;
    z-index: 300;
    position: absolute;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 2px 2px 2px #999;
    -moz-box-shadow: 2px 2px 2px #999;
    box-shadow: 2px 2px 2px #999;
    -webkit-transition-property: opacity, margin-top, visibility, margin-left;
    -webkit-transition-duration: 0.4s, 0.3s, 0.4s, 0.3s;
    -webkit-transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    -moz-transition-property: opacity, margin-top, visibility, margin-left;
    -moz-transition-duration: 0.4s, 0.3s, 0.4s, 0.3s;
    -moz-transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    -o-transition-property: opacity, margin-top, visibility, margin-left;
    -o-transition-duration: 0.4s, 0.3s, 0.4s, 0.3s;
    -o-transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    transition-property: opacity, margin-top, visibility, margin-left;
    transition-duration: 0.4s, 0.3s, 0.4s, 0.3s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
}


a.tooltipx:hover {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 9999;
    color: red;
}
a.tooltipx:hover > span {
    opacity: 1;
    text-decoration: none;
    visibility: visible;
    overflow: visible;
    margin-top: 50px;
    display: inline;
    margin-left: -60px;
    z-index: 999;
    width: 300px;
    position: relative;
}
a.tooltipx > span {
    color: #ffffff;
    background: #6C7073;
    background: -moz-linear-gradient(top, #6C7073 0%, #6C7073 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6C7073), color-stop(100%, #6C7073));
    border: 1px solid #CFB57C;
}
/* bazaarvoice */
/* ------------------------------------- */

.pdp-main .BVRRRootElement {
    margin-top: 18px;
}
.pdp-main .BVRRRootElement a {
    color: @gray;
    font-family: var(--font-body) !important;
    font-size: 10px;
}
.pdp-main .BVRRRootElement a:hover {
    color: @gray;
}
.BVRRSocialBookmarkingLinks {
    display: none;
}
.pdp-main .BVRRRatingEntry div {
    float: left;
}
.pdp-main .BVRRRatingSummaryLinks {
    clear: none;
    padding-left: 3px;
    margin-top: 3px;
}
.pdp-main .BVRRRatingSummaryLinkRead {
    border: none;
    padding-right: 5px;
}
.pdp-main .BVRRRatingNormalOutOf {
    line-height: 23px;
}

.pdp-main #BVReviewsContainer {
    background-color: @blue;
}

.pdp-main .BVRRDisplayContentReview {
    padding: 30px !important;
}

.pdp-main .BVDILinkSpan {
    color: @white !important;
}

.pdp-main .product-review-links .BVRRRatingSummary .BVRRRatingNormalImage img {
    filter: invert(100%);
}

.pdp-main .product-review-links .BVRRRatingSummary {
    #BVRRRatingSummaryLinkWriteFirstID a {
        color: @gray !important;
    }

    #BVRRRatingSummaryLinkReadID a {
        color: @gray !important;
    }

    .BVRRRatingNormalOutOf .BVRRNumber {
        color: @gray !important;
    }
}

/* bonus products */
/* ------------------------------------- */

.bonus-product-quickview .ui-dialog-title {
    display: none;
}
.bonus-product-quickview .bonus-product-promo {
    color: @red;
}
.js .ui-dialog-content a.tooltip {
    color: @gray;
    font-size: 10px;
    text-decoration: underline;
}
.ui-dialog .bonus-discount-container .buttonbar {
    width: 100%;
    margin-top: 20px;
}
.select-bonus-btn,
.no-bonus-btn {
    float: left;
    margin: 0 5px 0 0;
}
.no-bonus-btn {
    margin-top: 8px;
}
.buttonbar {
    margin: 11px 0;
    overflow: hidden;
    width: 336px;
}
.bonus-product-list .bonus-product-item {
    border-bottom: 1px solid #E0E0E0;
    clear: both;
    margin-top: 10px;
}
.bonus-product-list .product-name {
    float: none;
    margin: 1em 0;
}
.bonus-product-list .product-add-to-cart button {
    margin: 0;
}
.bonus-product-list-footer {
    clear: both;
}
.bonus-product-list-footer button {
    float: right;
}
/* send to friend */
/* ------------------------------------- */

.send-to-friend-product {
    border-bottom: 1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 22%;
    overflow: hidden;
    padding: 20px 0;
}
.send-to-friend-product .product-image {
    float: left;
    width: 36%;
}
.send-to-friend-product .product-image img {
    max-width: 80%;
}
.send-to-friend-product .product-description {
    float: left;
    width: 63%;
}
.send-to-friend-product .product-description h2 {
    margin-right: 0;
}
.send-to-friend-product .product-description .value {
    font-weight: bold;
}
.send-to-friend-product .product-description h2 {
    font-family: Georgia;
    font-size: 1.2em;
    font-style: italic;
}
.send-to-friend-product .product-description dt {
    clear: both;
    float: left;
    margin: 10px 0 0 0;
    width: 100px;
}
.send-to-friend-product .product-description dd {
    float: left;
    margin: 10px 0 0 0;
    width: 200px;
}
#send-to-friend-main .send-to-friend-sender-label {
    padding-top: 0;
}
#send-to-friend-main .send-to-friend-sender {
    margin-left: 2%;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 49.5966%;
}
#send-to-friend-dialog .send-to-friend-login-message {
    padding: 2em 0em 2em;
}
#send-to-friend-main .dialog-required {
    float: none;
    text-align: right;
}
#send-to-friend-main input[type="text"],
#send-to-friend-main textarea {
    width: 64%;
}
#send-to-friend-main .label-inline label {
    font-size: 12px;
    padding: 0px;
}
#send-to-friend-main .form-caption {
    font-size: 12px;
    color: #fff;
}
#send-to-friend-main .label-inline input[type="checkbox"] {
    display: none;
}
#send-to-friend-main .label-inline input[type="checkbox"]+label {
    height: 17px;
    padding-left: 18px;
    padding-top: 9px;
}
#send-to-friend-main .label-inline input[type="checkbox"]:checked+label {
    height: 17px;
}
#send-to-friend-main .form-row-button {
    text-align: center;
}
#send-to-friend-dialog h2 {
    font-size: 14px;
}
#send-to-friend-dialog .close-button {
    float: right;
    margin-bottom: 30px;
}
#send-to-friend-dialog #send-to-friend-main dt {
    float: left;
    clear: both;
    width: 60px;
    text-align: right;
}
#send-to-friend-dialog #send-to-friend-main dd {
    float: left;
    margin: 0 0 15px 10px;
}
#send-to-friend-dialog .edit .send-to-friend-product {
    display: none;
}
/* size charts */
/* ------------------------------------- */

.sizinginformation {
    border: 0 none;
    padding: 10px;
}
.sizinginformation th {
    background: none repeat scroll 0 0 #F3F3F3;
}
.sizinginformation td,
.sizinginformation th {
    border-bottom: 1px solid #F3F3F3;
    padding: 10px;
}
.sizinginformation td {
    border-left: 1px solid #F3F3F3;
}
.sizinginformation .ui-tabs-nav {
    height: 33px;
}
.sizinginformation .sizing-charts {
    border: 0 none;
}
.sizinginformation .tips-list {
    padding: 0 !important;
}
.sizinginformation .tips-list li {
    list-style: none outside none !important;
}
/* shopping cart */
/* ------------------------------------- */

#cart-table {
    clear: both;
}
#cart-table th.section-header {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
#cart-table th:first-child {
    padding-left: 20px;
}
#cart-table td {
    padding-left: 0;
}
#cart-table td.item-image,
#cart-table .cart-promo td {
    padding: 20px;
}
#cart-table .item-quantity input,
.pt_checkout #cart-table .item-quantity-label {
    margin: 0;
    text-indent: 0;
    background-color: transparent;
    color: @gray;
    border: 1px solid @gray;
    text-align: center;
    padding: 0.6em 0.25em;
    font-weight: bold;
}
#cart-table .inventory .quantity{
    display: flex;
}
#cart-table .item-details a,
#cart-table .item-name a,
#cart-table .editdetails a {
    text-decoration: none;
}
#cart-table .item-edit-details a {
    text-decoration: none;
}
.pt_cart #cart-table .item-quantity .item-quantity-label {
    display: none;
}
#cart-table .item-user-actions a,
#cart-table .item-user-actions button,
ul.product-availability-list li {
    white-space: nowrap;
}
#cart-table .item-user-actions a {
    display: block;
    font-size: 12px;
    text-decoration: none;
    margin: 4px 0px;
}
#cart-table .item-user-actions button {
    display: block;
    color: @gray;
    font-weight: normal;
    margin: 0px 0px 4px;
}
#cart-table .price-promotion .price-sales {
    color: @red;
}
#cart-table .price-promotion .price-standard {
    margin: 0 0.25em 0 0;
    text-decoration: line-through;
}
#cart-table .item-total {
    word-wrap: break-word;
}
#cart-table .item-total,
#cart-table .item-price {
    font-size: 14px;
}
#cart-table .item-total .promo-adjustment {
    width: 120px;
}
#cart-table .price-unadjusted {
    color: @red;
    display: block;
    margin: 0 0 5px;
}
#cart-table .price-unadjusted span {
    text-decoration: line-through;
}
#cart-table .price-adjusted-total {
    color: @gray;
    display: block;
    margin: 5px 0 0;
}
#cart-table .price-adjusted-total span {
    color: @red;
}
#cart-table .gift-cert-delivery {
    color: @red;
}
#cart-table tr.rowbundle .item-quantity {
    text-align: center;
}
#cart-table .cart-promo {
    color: @red
}
#cart-table tr.cart-promo-tablerow {
    border-bottom: 0px;
}
#cart-table .product-availability-list,
#cart-table .product-availability-list li {
    max-width: 150px;
    white-space: normal;
}
#cart-table .product-availability-list {
	padding-top: 10px;
}
.cart-promo.cart-promo-approaching {
    padding-bottom: 25px;
}
.cart-promo a.tooltip {
    text-decoration: underline;
}
.cart-promo a.select-bonus {
    color: @red;
    text-decoration: underline;
}

.cart-footer {
    margin: 20px 0 0 20px;
}
.cart-footer-slot-mobile {
    display: none;
}
.cart-footer .cart-footer-slot {
    float: left;
    margin-top: 35px;
    clear: left;
}
.cart-coupon-code label,
.billing-coupon-code label {
    float: none;
    font-size: 10px;
    text-align: left;
    width: auto;
    line-height: 2em;
    text-indent: 0;
    padding-top: 0px;
}
.cart-coupon-code.form-row {
    clear: none;
    width: 30%;
    display: inline;
    position: relative;
    float: left;
    margin-top: -10px;
}
.cart-coupon-code span.error {
    margin-left: 0;
    padding-top: 10px;
    display: block;
    clear: both;
}
.cart-coupon-code .required-indicator {
    display: none;
}
.cart-coupon-code .input-text {
    background-color: @white;
    border: 1px solid @gray;
    color: @gray;
    width: 75%;
    margin: 0;
    float: left;
    border-radius: 0;
}
.cart-coupon-code #add-coupon,
.billing-coupon-code button[type="submit"] {
    background-color: transparent;
    border: none;
    color: @gray;
    text-transform: uppercase;
    padding: 8px 10px;
    font-weight: bold;
}
.cart-coupon-code #add-coupon {
    float: right;
    position: absolute;
    right: -15px;
    top: 20px;
}
.cart-coupon-code div.error {
    clear: both;
    padding-top: 10px;
    display: block;
}
.pt_checkout .billing-coupon-code {
    margin-top: 10px;
}
.pt_checkout .billing-coupon-code input[type="text"] {
    margin-left: 0;
    width: 23%;
    background-color: @white;
    border: 1px solid @gray;
}
.cart-order-totals {
    float: right;
    clear: right;
    margin-top: 35px;
    width: 50%;
}
#update-cart {
    float: right;
    border: 1px solid @gray;
    background-color: transparent;
    color: @gray;
    padding: 3px 5px;
    font-weight: normal;
    position: absolute;
    right: 0;
    bottom: -43px;
}
.cart-order-totals #shipping-method-list {
    clear: both;
    text-align: right;
    padding-top: 5px;
}
.cart-order-totals #shipping-method-list p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    letter-spacing: 2px;
}
.cart-order-totals #shipping-method-list .form-row {
    margin-bottom: 8px;
}
.cart-order-totals #shipping-method-list .form-row .required-indicator {
    display: none;
}
.cart-order-totals #shipping-method-list .form-row label {
    font-size: 12px;
    font-weight: normal;
    float: none;
    display: inline-block;
    width: auto;
    margin: 0;
}
.cart-order-totals #shipping-method-list .input-text {
    border: 1px solid @gray;
    background-color: @white;
    color: @gray;
    width: 70px;
    text-align: center;
    border-radius: 0;
}
.cart-order-totals #shipping-method-list button {
	margin: 0 0 10px 0;
}
.cart-order-totals .selectboxit-container {
	margin-left: 2%;
}
.cart-order-totals #shipping {
	margin-top: 20px;
}
.cart-order-totals #shipping-method-list .error-message {
    margin: 10px 0;
    padding: 5px;
    width: auto;
    display: inline-block;
    color: @error;
}
#shipping-method-list span.standard-shipping {
    text-decoration: line-through;
}
#shipping-method-list span.discount-shipping {
    color: @red;
}
.cart-order-totals .order-totals-table {
	margin-top: 25px;
}
.order-totals-table th {
    display: none;
}
.order-totals-table td {
    padding: 5px 0;
    text-align: right;
    min-width: 88px;
}
.order-totals-table .order-total {
    font-weight: 700;
    font-size: 12px;
}
.order-totals-item {
    width: 60%;
}
.order-totals-value {
    width: 40%;
}
tr.discount {
    color: @red;
}
#secondary .order-totals-table .order-shipping a {
    display: none;
}
.secondaryNavFeather {
    width: 100%;
    position: relative;
    top: -10px;
}
.cart-action-checkout {
    float: right;
    margin-top: 30px;
}
.cart-action-continue-shopping button.button-text {
    font-size: 10px;
    color: @gray;
    margin-top: 47px;
}
.promo-adjustment {
    color: @red;
}
ul.product-availability-list {
    margin: 0;
    padding: 0;
}
ul.product-availability-list li {
    list-style: none outside none;
    margin: 4px 0;
}
ul.product-availability-list .on-order,
ul.product-availability-list .not-available {
    color: @red;
    font-size: 0.9em;
}
.account-welcome .welcome-message {
    font-family: Georgia, serif;
    font-size: 1.2em;
    font-style: italic;
}
.account-welcome .not-user {
    margin: 10px 0;
}
.last-visited h2 {
    font-family: Georgia, serif;
    font-size: 1.2em;
    font-style: italic;
    font-weight: normal;
}
.last-visited .jcarousel-clip {
    margin: 0 20px;
    overflow: hidden;
    width: 167px;
}
.last-visited .jcarousel-item {
    width: 167px;
}
.last-visited .jcarousel-next {
    background: url("../images/interface/icon-jcarousel-horiz-arrow-right.png") no-repeat scroll left top transparent;
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 10px;
}
.last-visited .jcarousel-prev {
    background: url("../images/interface/icon-jcarousel-horiz-arrow-left.png") no-repeat scroll left top transparent;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 10px;
}
.last-visited .jcarousel-prev-disabled,
.last-visited .jcarousel-next-disabled {
    background-image: none;
}
span.save-cart-error,
span.retrieve-cart-error {
    display: none;
}
.save-cart-popup .btn,
.retrieve-cart-popup .btn {
    float: right;
}
.save-cart-popup .content-asset,
.retrieve-cart-popup .content-asset {
    font-size: 13px;
    margin-bottom: 25px;
}
.cart-footer .selectboxit-arrow-container {
    background: none;
}
.pt_cart .primary-focus .primary-content.empty {
    border: none;
}
.pt_cart .primary-content {
    border-right: none;
    width: 100%;
    padding-right: 0;
}
.paypal-button {
    background: none repeat scroll 0 0 transparent;
    float: right;
    padding-left: 15px;
}
#paypal-text {
    float: left;
    padding: 5px 10px 0px 0px;
    color: @gray;
}
.paypalcheckout {
    float: left;
    clear: both;
    width: 90%;
    padding-top: 20px;
}
.paypal-form {
    padding-right: 12%;
}
/* checkout */
/* ------------------------------------- */

.pt_cart .error-message,
.pt_checkout .error-message {
    border: none;
    background-color: transparent;
    padding-left: 0px;
}
.pt_cart form table th.section-header,
.pt_checkout #cart-table th.section-header,
.pt_order-confirmation .item-list th.section-header,
.pt_order-confirmation .shipment-list th.section-header {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    vertical-align: bottom;
}
.pt_order-confirmation .item-list th.section-header {
    padding-bottom: 7px;
}
.pt_order-confirmation .shipment-list {
    margin-top: 10px;
}
.pt_order-confirmation .shipment-list th.section-header:last-child {
    padding-left: 20px;
}
.pt_order-confirmation .shipment-list thead {
    border-bottom: 1px solid #adadad;
}
.pt_order-confirmation .shipment-list tr td {
    padding-left: 0;
    border-bottom: 1px solid #adadad;
}
.pt_order-confirmation .shipment-list tr:last-child td {
    border-bottom: none;
}
.pt_order-confirmation .shipment-list td.order-shipment-details {
    border: none;
    padding-left: 20px;
}
.pt_order-confirmation .shipment-list .content-quantity {
    width: 15%;
}
.pt_order-confirmation .shipment-list .content-description .name,
.pt_order-confirmation .shipment-list .content-quantity,
.pt_order-confirmation .shipment-list .content-price {
    font-size: 14px;
}
.pt_order-confirmation .shipment-list .content-description .name {
    margin-bottom: 10px;
}
.pt_order-confirmation .shipment-list .content-description .name a {
    text-decoration: none;
}
#cart-table th.section-header:first-child,
.pt_order-confirmation .shipment-list th.section-header:first-child {
    padding-left: 20px;
}
#cart-table td.item-image,
.pt_order-confirmation .shipment-list td.content-image {
    padding-left: 20px;
    width: 15%
}
.pt_order-confirmation .shipment-list td.content-image img {
    width: 130px;
}
#cart-table .editdetails {
	padding-top: 2px;
}
.checkout-progress-indicator {
    overflow: hidden;
    text-align: center;
    clear: both;
    font-size: 14px;
    text-transform: uppercase;
}
.checkout-progress-indicator div {
    display: inline;
}
.checkout-progress-indicator span,
.checkout-progress-indicator a {
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
}
.checkout-progress-indicator a:hover {
    text-decoration: none;
}
.checkout-progress-indicator .active {
    font-weight: 700;
}
.checkout-progress-indicator .step-3 {
    background: none repeat scroll 0 0 transparent;
    padding-right: 0;
}
.checkout-progress-indicator div.step:after {
    content: "\2014";
    color: #afafaf;
    padding: 0 20px;
}
.checkout-progress-indicator.multishipprogress div.step:after {
    padding: 0 5px;
}
.checkout-progress-indicator div.step:last-child:after {
    content: "";
    padding: 0;
}
.select-address {
    padding: 2em 0;
}
.shipping-method {
    padding: 22px 0 0 28%;
}
.shipping-method label {
    display: inline;
    float: none !important;
    padding-right: 5px !important;
}
.shipping-method .shipping-description {
    display: inline;
}
.redemption {
    color: @red;
}
.redemption span.error {
    margin-left: 0;
}
.payment-method-options {
    padding-left: 24%;
}
.payment-method-options .form-row {
    clear: none;
    float: left;
    width: 30%;
}
.payment-method-options label {
    width: auto;
}
.payment-method {
    clear: both;
    display: none;
}
.payment-method-expanded {
    display: block;
}
.cvn-tip {
    float: left;
    margin: -35px 0 0 34%;
    width: auto;
}
.cvn-tip-error {
    float: left;
    margin: -78px 0 0 40%;
    width: auto;
}
.month,
.year,
.day {
    clear: none;
    float: left;
    margin: 0 0 2% 2%;
}
.month select,
.year select,
.day select {
    margin: 0;
    width: auto;
}
.month .form-caption {
    margin-left: 0;
}
.cvn input {
    width: 50px !important;
}
.bml-terms-and-conditions {
    border: 1px solid #AAAAAA;
    font-size: 0.8em;
    height: 400px;
    overflow: auto;
    padding: 1em;
}
.bml-table-col-1 {
    width: 25%;
}
.checkout-mini-cart {
    background: none repeat scroll 0 0 #EFEFEF;
    max-height: 330px;
    overflow: auto;
}
.checkout-order-totals {
    background: none repeat scroll 0 0 #EFEFEF;
    padding: 0.5em 1em;
    border-top: 1px solid #a9a9a9;
    text-align: right;
    font-size: 11px;
}
.checkout-order-totals .order-totals-table td {
    padding: 5px 0 0;
}
.checkout-order-totals .order-totals-table .order-total td {
    padding-top: 10px;
}
.place-order-totals {
    clear: both;
    padding-top: 20px;
}
.place-order-totals .order-totals-table {
    float: right;
    width: 50%;
}
.order-component-block {
    margin: 20px 0;
    padding-top: 30px;
}
.pt_checkout #secondary .mini-payment-instrument + .mini-payment-instrument .section-header a.section-header-note {
    padding-top: 25px;
}
.order-component-block .section-header a {
    float: right;
    font-weight: normal;
}
.order-component-block .details {
    padding-top: 15px;
    font-size: 11px;
}
.order-summary-footer {
    position: relative;
}
.order-summary-footer h2 {
    font-family: Georgia, serif;
    font-size: 2em;
    font-style: italic;
    font-weight: normal;
}
.order-summary-footer .form-row {
    display: flex;
    justify-content: flex-end;
}
.summary-help {
    float: right;
    font-size: 0.8em;
    margin: 1em 0;
}
.submit-order {
    clear: both;
    display: block;
    padding: 20px 0 0;
}
.submit-order button {
    margin-left: 1.5em;
}
.pt_order-confirmation h1 {
    text-transform: uppercase;
    font-size: 28px;
    margin: 0 0 15px 0;
}
.pt_order-confirmation .order-shipment-table {
    margin: 0 0 20px;
}
.pt_order-confirmation .order-shipment-table .order-shipment-details {
    border-left: 1px solid #E0E0E0;
}
.pt_order-confirmation a.print-page {
    float: right;
    font-size: 1.17em;
}
.confirmation-message {
    border: 1px solid @gray;
    padding: 40px;
}
.email-confirmation-message {
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.confirmation-message-header {
    font-size: 26px;
    text-transform: uppercase;
    padding-bottom: 10px;
}
.order-discount {
    color: #990000;
}
.pt_order-confirmation .login-box h2.section-header {
    font-size: 16px;
    text-transform: uppercase;
}
.pt_order-confirmation .login-box-content {
    font-size: 10px;
}
.pt_order-confirmation .login-box-content .dialog-required {
    float: none;
    margin: 10px 0 8px 0;
}
.pt_order-confirmation .login-box-content .form-row {
    margin-top: 5px;
    margin-bottom: 0;
    text-align: left;
}
.pt_order-confirmation .login-box-content .form-row label {
    font-weight: normal;
    font-size: 12px;
    text-transform: none;
}
.pt_order-confirmation .login-box-content input[type="text"],
.pt_order-confirmation .login-box-content input[type="password"] {
    background-color: #fff;
    margin: 0;
    width: 100%;
    width: 93%;
    padding-left: 3%;
    padding-right: 3%;
}
.pt_order-confirmation .login-box-content button {
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    margin-top: 25px;
    width: 100%;
    display: block;
}
.pt_order-confirmation #continue-shopping {
    margin-top: 17px;
}
.pt_checkout .form-label-text.cc-exp-label {
    margin-left: 2%;
    width: 20%;
    padding-right: 0;
}
.pt_checkout .tooltip-content .shipping-method-cost {
    display: none;
}
.discount {
    color: @red;
}
.gift-cert-used {
    padding: 10px 0;
    margin-left: 7%;
}
.order-detail-options {
    margin: 20px 0 0;
    padding: 8px 0 6px 0;
    text-align: right;
}
.summary .edit {
    position: absolute;
    text-transform: uppercase;
    right: 10px;
    font-size: 0.9em;
    line-height: 1.5em;
}
.pt_checkout #main {
    padding: 0 14px;
}
.pt_checkout .checkout-billing .payment-method .ccnumber .form-caption.error-message {
    color: @error;
}
.cart-empty {
    clear: both;
    padding-top: 25px;
}
#checkout-form button.cart-save {
    font-size: 14px;
}
.pt_checkout input[type="text"],
.pt_checkout input[type="password"],
.pt_checkout textarea {
    color: @gray;
    background-color: @white;
    border: 1px solid @gray;
}
.pt_checkout a.tooltip {
    font-size: 10px;
    text-decoration: underline;
}
.pt_checkout a.tooltip .shipping-title {
    font-weight: bold;
    padding: 5px 0px;
}
.pt_checkout a.tooltip .shippingtotal,
.pt_checkout a.tooltip .surcharge-product,
.pt_checkout a.tooltip .shipping-method-cost,
.pt_checkout a.tooltip .order-shipping-discount {
    padding: 5px 0px;
}
.pt_checkout a.tooltip .order-shipping-discount {
    color: #4B4B4B;
}
.pt_checkout #shipping-method-list {
    border-top: 1px solid #afafae;
}
.pt_checkout #shipping-method-list .form-indent {
    margin-left: 10%;
}
.pt_checkout #shipping-method-list .form-indent .form-caption {
    margin-left: 6px;
}
#shipping-method-list .label-inline input.input-radio {
    margin: 1em 1em 1em 2%;
}
.pt_checkout #shipping-method-list input[type=radio] + label p {
    margin-left: -40px;
}
.pt_checkout .form-row-button {
    width: auto;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
}
.pt_checkout a.return-to-cart {
    margin-top: 20px;
    font-size: 10px;
    display: inline-block;
    text-decoration: none;
}
.pt_checkout .checkout-shipping a.return-to-cart {
    margin-top: 20px;
    text-decoration: none;
}
.pt_checkout button.button-yellow {
    float: right;
}
#wrapper.pt_cart,
#wrapper.pt_checkout,
#wrapper.pt_order-confirmation {
    background-color: transparent;
    border: none;
    width: 1000px;
    max-width: 1000px;
}
.pt_cart #main,
.pt_checkout #main,
.pt_order-confirmation #main {
    margin-left: -13%;
    padding: 0 13%;
    width: 100%;
}
#cart-items-form {
    clear: both;
    padding-top: 28px;
    position: relative;
}
#checkout-header {
    height: 134px;
    text-align: center;
    position: relative;
}
#cart-footer-mobile {
    display: none;
}
#checkout-header-main {
    display: none;
}
#cart-banner {
    float: right;
    margin-top: 11px;
}
#cart-table .item-image img,
.checkoutmultishipping .item-image img {
    max-width: 80px;
    min-width: 70px;
}
#cart-table .item-name,
#cart-table .item-links {
    display: none;
}
#cart-footer {
    margin: 20px auto 40px auto;
    clear: both;
}
#cart-footer a {
    color: @gray;
    text-decoration: underline;
}
#cart-footer #continue-shopping button {
    display: none;
    margin-top: 15px;
    background-position-x: -15px;
    background-size: 110% 100%;
}
.pt_order-confirmation #cart-footer #continue-shopping button {
    display: block;
}
#cart-footer ul {
    display: inline;
    margin: 0;
    padding: 0;
}
#cart-footer ul li {
    display: inline;
}
#cart-footer-desktop ul li:after {
    content: ","
}
#cart-footer-desktop ul li:last-child:before {
    content: "& ";
}
#cart-footer-desktop ul li:last-child:after {
    content: ".";
}
#cart-footer-mobile {
    text-align: center;
}
#cart-footer-mobile ul {
    display: block;
    margin-top: 10px;
}
#cart-footer-mobile a {
    text-decoration: none;
    text-transform: capitalize;
}
#cart-footer-mobile ul li:after {
    content: " | ";
}
#cart-footer-mobile ul li:last-child:after {
    content: "";
}
#cart-footer .logo-footer {
    text-align: center;
    margin-top: 25px;
}
.pt_cart .breadcrumb,
.pt_checkout .breadcrumb,
.pt_order-confirmation .breadcrumb {
    margin-top: 0;
    margin-bottom: 40px;
    padding-left: 0;
    padding-top: 10px;
}
.pt_order-confirmation .breadcrumb {
    margin-bottom: 40px;
}
.pt_cart h1,
.pt_checkout #main > h1,
.pt_checkout .header-separator > h1 {
    font-style: normal;
    font-size: 30px;
    text-transform: uppercase;
    float: left;
    margin: 0;
}
.pt_order-confirmation .header-separator {
    border-bottom: 1px solid #ababab;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
.pt_order-confirmation .order-confirmation-details {
    padding-top: 5px;
    padding-bottom: 15px;
}
.pt_order-confirmation .order-confirmation-details .content-header {
    font-size: 16px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}
.pt_order-confirmation .item-list th {
    padding-right: 20px;
}
.pt_order-confirmation .item-list td {
    padding-left: 0;
    padding-top: 10px;
}
.pt_order-confirmation .item-list td.order-information {
    width: 25%;
}
.pt_order-confirmation .item-list th:last-child,
.pt_order-confirmation .item-list td:last-child {
    padding-right: 0;
}
.pt_order-confirmation .item-list .order-information .order-number {
    font-size: 18px;
    color: @red;
    margin-top: 10px;
}
.pt_order-confirmation .order-totals-table td.name {
    text-align: left;
}
.pt_order-confirmation .order-totals-table tr.order-subtotal {
    font-weight: normal;
}
.pt_order-confirmation .order-totals-table tr td {
    padding: 5px;
}
.pt_order-confirmation .order-totals-table tr:first-child td {
    padding-top: 0;
}
.pt_order-confirmation .order-totals-table tr:last-child {
    border-bottom: none;
}
.pt_order-confirmation .product-availability-list,
.pt_order-confirmation .product-availability-list li {
    max-width: 224px;
    white-space: normal;
}
.order-shipment-details .shipping-method {
    padding-left: 0px;
}
.order-shipment-gift-message-header {
    font-weight: bold;
    font-size: 1.2em;
    padding-bottom: 0px;
}
.order-shipment-gift-message {
    font-size: 1.1em;
}
.pt_customer-service #main {
    background-size: 100% 100%;
    width: 100%;
    padding-right: 10%;
}
.pt_customer-service .mobile-contentnav,
.pt_blog .mobile-contentnav {
    display: none;
}
.pt_checkout .checkout-shipping {
    clear: both;
}
.pt_checkout .checkout-shipping legend {
    width: 50%;
    float: left;
}
.pt_checkout .checkout-shipping legend,
.pt_checkout .checkout-billing legend {
    background: none;
    border-bottom: none;
    padding: 20px 0;
    font-size: 14px;
    font-weight: normal;
}
.pt_checkout .checkout-billing legend.not-first {
    border-top: 1px solid #a9a9a8;
}
.pt_checkout .checkout-billing fieldset.billing-payment-methods .form-row-button {
    margin-bottom: 0;
}
.pt_checkout .checkout-billing .billing-giftcertificate-section {
    margin-top: 30px;
}
.pt_checkout .checkout-billing .billing-giftcertificate-section button.button-text {
    color: @gray;
    font-size: 10px;
    margin-top: 20px;
}
.pt_checkout .checkout-billing .payment-method .ccnumber .form-caption {
    color: #gray;
    margin-left: 24%;
}
.pt_checkout .checkout-billing .payment-method .ccnumber .error-message {
    margin-left: 24%;
}
.pt_checkout .checkout-billing .payment-method .month .error-message {
    width: 396px;
    box-sizing: border-box;
    margin-top: 7px;
    padding: 0px;
    font-weight: normal;
}
.pt_checkout .checkout-billing .payment-method .year.error {
    margin-left: -285px;
}
.pt_checkout .checkout-billing .form-indent.checkout-billing-coupon-indent {
    margin-left: 0;
    margin-bottom: 25px;
    width: 60%;
}
.pt_checkout .checkout-billing .form-indent.checkout-billing-coupon-indent .content-asset {
    font-size: 10px;
}
.pt_checkout .checkout-billing #PaymentMethod_Secure {
    padding-top: 20px;
}
.pt_checkout .checkout-billing #PaymentMethod_Secure table {
    margin-left: 24.1%;
    width: auto;
}
.pt_checkout .checkout-billing #PaymentMethod_Secure table td {
    padding: 0;
    text-align: left;
}
.pt_checkout .checkout-billing #PaymentMethod_Secure table td img {
    margin-top: -2px;
}
.pt_checkout .header-separator {
    border-bottom: 1px solid #adadad;
    padding-bottom: 15px;
    margin-bottom: 28px;
}
.pt_checkout .header-separator .content-asset {
    float: right;
    margin-top: 10px;
}
.pt_checkout .header-separator .content-asset h1.content-header {
    font-size: 12px;
    font-style: normal;
    padding-top: 15px;
    margin-bottom: 0 !important;
}
.pt_checkout .header-separator .content-asset h1.content-header span {
    float: none;
    font-size: 12px;
}
.pt_cart #primary .item-list tr .section-header:first-child {
    padding-left: 20px;
}
.pt_cart #primary .item-list .section-header,
.pt_checkout #secondary .section-header {
    border: none;
    font-size: 12px;
    font-weight: 500;
    padding-block-end: 10px;
    text-transform: uppercase;
}
.pt_checkout #secondary .section-header a.section-header-note {
    margin-right: -50px;
    float: right;
    position: static;
    text-transform: uppercase;
    color: @gray;
    font-size: 12px;
    padding-top: 3px;
    text-decoration: none;
}
.pt_checkout #secondary h3.section-header {
    border-bottom: 1px solid #adadad;
    padding-right: 50px;
}
.pt_checkout #secondary h3.section-header.order-summary-title {
    padding-right: 0px;
}
.pt_checkout #secondary .checkout-mini-cart,
.pt_checkout #secondary .checkout-order-totals {
    background-color: transparent;
    padding: 0;
}
.pt_checkout #secondary .checkout-mini-cart a {
    color: @gray;
    font-style: normal;
    font-size: 10px;
}
.pt_checkout .checkout-billing .privacy-policy {
    margin-left: 29.75%;
    margin-top: -13px;
    font-size: 10px;
}
.pt_checkout .checkout-billing .privacy-policy a {
    color: @gray;
}
.ui-dialog.checkout-address-edit {
    padding-right: 0;
}
.ui-dialog.checkout-address-edit h1 {
    font-weight: normal;
}
.ui-dialog.checkout-address-edit .dialog-content {
    padding-top: 10px;
}
.ui-dialog.checkout-address-edit label {
    width: 17%;
}
.ui-dialog.checkout-address-edit span.error {
    margin-left: 20%;
}
.ui-dialog.checkout-address-edit .select-address .form-row.form-row-button {
    display: none;
}
.ui-dialog.checkout-address-edit .form-row.form-row-button {
    margin-top: 35px;
    margin-right: 25%;
    width: auto;
}
.ui-dialog.checkout-address-edit .form-row.form-row-button .button-yellow {
    float: right;
    padding-left: 10px;
    padding-right: 10px;
}
.ui-dialog.checkout-address-edit .form-row.form-row-button .button-white {
    float: right;
    margin-right: 8%;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 7px;
}
.ui-dialog.checkout-address-edit .dialog-required {
    margin-right: 24.5%;
}
.ui-dialog.checkout-address-edit .form-caption {
    display: none;
}
.ui-dialog.checkout-address-edit .form-field-tooltip {
    width: 23%;
    margin-top: -35px;
}
.ui-dialog.checkout-address-edit .form-field-tooltip a {
    text-decoration: underline;
    font-size: 10px;
}
.ui-dialog.checkout-address-edit .form-field-tooltip.with-helper-text {
    margin-top: -59px;
}
.pt_checkout #PaymentMethod_CREDIT_CARD span.error {
    margin-left: 24%;
}
.pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error {
    margin: 1% 2% 2% 23.5%
}
.pt_checkout #PaymentMethod_CREDIT_CARD .cvn span.error-message {
    padding-top: 20px;
    margin-left: 24.3%;
}
.pt_checkout span.error {
    margin: 1% 2% 2% 24%;
}
.checkout-login-dialog .login-box-content input[type="text"],
.checkout-login-dialog .login-box-content input[type="password"] {
    width: 100%;
    color: #000;
    background-color: #fff;
    border: 1px solid #555;
    margin: 0;
    box-sizing: border-box;
}
.checkout-login-dialog .login-box-content .form-row label {
    text-align: left;
}
.checkout-login-dialog .tab {
    text-transform: uppercase;
    border: 2px solid #ccc;
    color: #ccc;
    display: inline-block;
    padding: 3px 6px;
    font-size: 16px;
    cursor: pointer;
}
.checkout-login-dialog .tab.active {
    border: 2px solid @gray;
    color: @gray
}
.checkout-login-dialog .tab-content {
    display: none;
    margin-top: 20px;
}
.checkout-login-dialog .tab-content.active {
    display: block;
}
.checkout-login-dialog .section-header {
    display: none;
}
.checkout-login-dialog .remember-me {
    margin: 0;
}
.checkout-login-dialog #RegistrationForm legend {
    background: none;
    border: none;
}
/* checkout login intercept */
/* ------------------------------------- */

.pt_checkoutsimple #header,
.pt_checkoutsimple #navigation,
.pt_checkoutsimple .navigationContainer {
    display: none;
}
.pt_checkoutsimple #cart-footer ul {
    display: none;
}
.checkout-simple {
    padding-bottom: 30px;
}
.checkout-simple .checkout-message {
    font-size: 14px;
    border-bottom: 1px solid #b0b0af;
    margin-top: 5px;
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.checkout-simple .col-1 {
    float: right;
    width: 39%;
    padding-right: 7%;
    padding-left: 4%;
    border-left: 1px solid #b0b0af;
}
.checkout-simple .col-2 {
    float: left;
    width: 40%;
    padding-left: 7.5%;
}
.checkout-simple .section-header {
    font-size: 20px;
}
.checkout-simple .button-white {
    margin-top: 30px;
    padding-left: 5px;
    padding-right: 5px;
}
.checkout-simple .login-box .login-box {
    margin-bottom: 88px;
}
.checkout-simple .col-1 form {
    padding-bottom: 30px;
}
.checkout-simple .col-1 .login-box-content div.Facebook {
    margin-top: 30px;
}
.checkout-simple form .form-row .required-indicator {
    display: none;
}
.checkout-simple form .form-row .input-text,
.checkout-simple form .form-row .input-text-pw {
    background-color: @gray;
    width: 70%;
}
.checkout-simple form .form-row label {
    float: none;
    display: inline;
}
.checkout-simple form .form-row.remember-me {
    margin-left: 27%;
}
.checkout-simple .login-box-content.returning-customers button {
    margin-top: 25px;
}
.checkout-simple .account-benefit-container {
    margin-top: 30px;
}
#password-reset-mobile {
    display: none;
}
.pt_checkoutsimple .mobile-logo {
    display: none;
}
/* Checkbox */

.squaredTwo {
    width: 28px;
    height: 28px;
    border: 1px solid #fff;
    position: relative;
}
.squaredTwo label {
    cursor: pointer;
    position: absolute;
    width: 350px;
    height: 20px;
    left: 30px;
    text-align: left;
}
.squaredTwo label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    background: url(../images/checkboxcheck.png) top left no-repeat;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -1px;
    left: -31px;
    font-size: 24px;
    display: none;
}
.squaredTwo label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}
.squaredTwo input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}
.checkout-progress-indicator div a,
.checkout-progress-indicator div span {
    background-repeat: no-repeat;
    background-position: left center;
    background-color: transparent;
    padding-left: 35px;
}
.checkout-progress-indicator div.inactive a,
.checkout-progress-indicator div.inactive span {
    background-image: url("../images/dropdownenu - feather.png");
    color: #afafaf;
}
.checkout-progress-indicator div.active a,
.checkout-progress-indicator div.active span {
    background-image: url("../images/dropdownenu - feather.png");
}
/* multishipping elements */
/* ------------------------------------- */

.selectbox {
    width: 100%;
}
.editaddress {
    padding-left: 2%;
    font-size: 10px;
    margin-top: 10px;
}
.formactions {
    margin-top: 20px;
    text-align: right;
}
table.item-list .multi-shipment-row {
    border-bottom: none;
}
.minishipments-method {
    padding-top: 10px;
}
.multishipprogress .step-4 {
    background: none repeat scroll 0 0 transparent;
    padding-right: 0;
}
.checkout-multi-shipping .cart-row .gift-message-text {
    display: none;
}
.checkout-multi-shipping .formactions {
    text-align: left;
    margin-top: 30px;
}
.pt_checkout #cart-table {
    margin-top: 30px;
}

.pt_checkout #cart-table .cart-row .shipment-label {
    font-weight: 700;
    margin-top: 35px;
    font-size: 1.4em;
}
.pt_checkout .checkoutmultishipping .section-header {
    font-size: 14px;
    font-weight: bold;
    padding: 22px 0 8px 20px;
    text-transform: uppercase;
    white-space: nowrap;
}
.pt_checkout .checkoutmultishipping .quantitycolumn div {
    border: 1px solid @gray;
    padding: 5px 10px;
}
.pt_checkout .checkoutmultishipping .product-list-item .product-price {
    display: inline;
}
#cart-table tr th,
#cart-table tr td {
    padding-left: 0;
}
#cart-table tr th:first-child,
#cart-table tr td.item-image,
#cart-table tr td:first-child {
    padding-left: 20px;
}
#cart-table .item-image img {
    width: 100%;
}
#cart-table .item-image {
    width: 74px;
}
#cart-table .item-details {
    max-width: 242px;
}
#cart-table .item-quantity-details {
    max-width: 150px;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.shipment-header {
    border-bottom: none;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.shipment-header th {
    font-weight: normal;
    padding-left: 0;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr td.section-header {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr td select {
    padding: 0;
    width: 77%;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr td.section-header-quantity {
    width: 20%;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr td.section-header-address,
.pt_checkout .checkout.multiship .checkoutmultishipping tr td.item-quantity-details {
    padding-left: 20px;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr td.item-quantity {
    font-size: 14px;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.cart-row.select-shipping-method,
.pt_checkout .checkout.multiship .checkoutmultishipping tr.cart-row.shipping-price-adjustments {
    border: none;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.cart-row.select-shipping-method td {
    padding-top: 0;
    padding-bottom: 0;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.cart-row.select-shipping-method td {
    padding-top: 0;
    padding-bottom: 0;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.is-gift td {
    padding-top: 0;
    padding-bottom: 0;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.is-gift td .gift-message-text {
    padding-top: 20px;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.is-gift td .gift-message-text label {
    padding-top: 0;
    font-size: 12px;
    width: 12%;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr.is-gift td .gift-message-text .char-count {
    font-size: 10px;
    padding-top: 5px;
    margin-left: 14%;
}
.pt_checkout .checkout.multiship .checkoutmultishipping tr td .row-label {
    vertical-align: middle;
}
.remove-coupon {
    color: @gray;
    font-weight: normal;
}
/* account */
/* ------------------------------------- */

.pt_accountlogin .col-1 {
    float: right;
}
.pt_accountlogin .col-2 {
    float: left;
}
.pt_accountlogin .primary-content h2 {
    font-size: 19px;
    font-weight: 500;
}
.pt_accountlogin .primary-content .dialog-required {
    margin-right: 0px;
}
.pt_account #myaccount-content-slot .contentframeImage {
    top: 0;
}
.pt_accountlogin .col-1 {
    margin-left: 6px;
    border-left: 1px solid #aeaead;
    padding-left: 13px;
}
.pt_account #secondary {
    position: relative;
    z-index: 1;
    top: 37px;
}
.pt_account .breadcrumb {
    position: absolute;
}
.pt_account .primary-content h1 {
    border-bottom: 1px solid #9d9d9c;
    padding: 10px 0;
    margin: 5px 0 15px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
}
.pt_account .primary-content #myaccount-container h1 {
    margin: 0;
    padding-top: 35px;
}
.pt_account #myaccount-content-slot,
.pt_account #myaccount-container,
.pt_account #addresses {
    margin-left: -23.5%;
    padding-left: 23.5%;
    padding-right: 15%;
}
.pt_account #myaccount-content-slot {
    min-height: 113px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    text-indent: -23%;
    vertical-align: middle;
    overflow: visible;
    width: 100%;
}
.pt_account #myaccount-container,
.pt_account #addresses,
#wrapper.pt_order #main,
#wrapper.pt_account #main,
.pt_accountlogin #main,
#wrapper.pt_wish-list #main {
    background-size: 100% 100%;
    width: 100%;
    padding-right: 15%;
    position: relative;
    z-index: 0;
}
.pt_account #myaccount-container > .content-asset {
    float: left;
    width: 54%;
}
.pt_account #myaccount-container {
    width: 100%;
    height: 100%;
}
.pt_account #myaccount-container {
    background: none;
    background-position: -150px !important;
    background-size: 1302px 611px !important;
}
.pt_account #addresses {
    background: none;
}
.pt_account #myaccount-container > .content-asset > ul {
    margin: 0;
    padding: 0;
}
.pt_account #myaccount-container > .content-asset li {
    font-size: 15px;
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #b6b9b8;
}
.pt_account #myaccount-container > .content-asset li:last-child {
    border-bottom: none;
}
.pt_account #myaccount-container > .content-asset li a {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
    text-decoration: none;
}
.pt_account #myaccount-container #my-account-landing-slot {
    float: right;
    width: 46%;
}
.pt_account .my-account-banner-spacing {
    margin: 27px 0 15px 0 !important;
}
#NewPasswordForm .form-row-button {
    text-align: right;
    padding-top: 30px;
    padding-bottom: 15px;
    width: 55%;
}
/* Addresses */

.pt_account .section-header {
    font-size: 14px !important;
    margin-top: 20px;
}
.pt_account .address-create {
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 0;
    position: static;
    float: right;
}
.pt_account ul.address-list li.default h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
}
.pt_account ul.address-list li .mini-address-name {
    font-size: 14px;
    font-weight: 500;
}
.pt_account ul.address-list li address {
    font-weight: normal;
    font-size: 12px;
    font-style: normal;
}
.pt_account ul.address-list li .mini-address-type {
    text-transform: capitalize;
}
.pt_account ul.address-list li .address-list-links,
.pt_account ul.address-list li .address-list-links a {
    font-size: 14px;
    color: @red;
}
.address-create-dialog #dialog-container {
    padding-top: 35px;
}
.address-create-dialog #dialog-container legend {
    background: none;
    border-bottom: none;
    border-top: 1px solid #9f9f9d;
    padding: 25px 0 0 0;
    width: 100%;
    font-size: 14px;
    font-weight: normal;
}
.address-create-dialog.ui-dialog .dialog-required {
    margin: 20px 25% 15px 0;
}
.address-create-dialog input[type="text"].error {
    border-color: @error;
}
.address-create-dialog .form-field-tooltip {
    margin-top: -33px;
    width: 23%;
    font-size: 10px;
    text-decoration: underline;
}
.address-create-dialog .form-caption {
    display: none;
}
.address-create-dialog .form-row-button {
    text-align: right;
    width: 76%;
    margin: 35px 0 0 0;
    padding: 0;
}
.address-create-dialog .apply-button,
.address-create-dialog .delete-button {
    padding: 7px 20px;
}
.address-create-dialog .cancel-button {
    padding: 7px 10px;
    margin-right: 20px;
    vertical-align: top;
}
.address-create-dialog input[type=radio].radio-yellow + span.error {
    margin-right: 25%;
    margin-top: 10px;
}
/* Personal Data */

.pt_account legend {
    background: none;
    border-bottom: none;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    padding: 15px 0 30px 0;
    width: 100%;
}
.pt_account legend.visually-hidden {
    width: 1px;
    padding: 0;
}
.pt_account #RegistrationForm fieldset {
    padding-right: 23.5%;
    border-bottom: 1px solid #aeaead;
}
.pt_account #RegistrationForm .dialog-required {
    margin-right: 0;
}
.pt_account .registration-selection .spacer {
    display: inline-block;
    height: 1px;
    width: 50px;
}
.pt_account #RegistrationForm .form-row-button .form-caption {
    float: left;
    margin-left: 0;
}
.pt_account #RegistrationForm button {
    float: right;
}
.pt_account #RegistrationForm .form-row-button {
    margin: 0;
    padding: 20px 0;
    width: 100%;
}
#PasswordResetForm fieldset {
    position: relative;
}
#PasswordResetForm button {
    float: right;
    position: absolute;
    top: 0px;
    left: 63%;
}
h1 .account-logout {
    font-size: 0.6em;
    text-transform: lowercase;
}
.registration-selection input,
.registration-selection label {
    display: inline;
    float: none;
}
#dialog-container[style] {
    height: auto !important;
}
.privacy-policy-dialog .content-asset h1 {
    display: none;
}
/* Password Reset Dialog */

.ui-dialog.password-reset .dialog-content {
    padding-top: 0;
}
.ui-dialog.password-reset h1 {
    padding-bottom: 15px;
    border-bottom: 1px solid #9a9a99;
    margin: 15px 0 25px 0;
}
.ui-dialog.password-reset .title-sub {
    font-size: 14px;
    padding-bottom: 20px;
}
#PasswordResetForm fieldset {
    margin-left: 15%;
    margin-top: 20px;
}
#PasswordResetForm .required-indicator {
    display: none;
}
#PasswordResetForm input[type="text"] {
    margin-right: 10px;
}
#PasswordResetForm span.error {
    margin: 0;
    display: block;
    width: 100%;
    margin-left: 50px;
    margin-top: 10px;
}
.ui-dialog.password-reset button {
    float: right;
    margin-top: -4px;
    padding: 10px 20px;
}
h1 .account-logout {
    font-size: 30px;
    text-transform: lowercase;
}
.registration-selection input,
.registration-selection label {
    display: inline;
    float: none;
    font-size: 16px;
}
.registration-selection {
    border-bottom: 1px solid #9d9d9c;
    padding-bottom: 13px;
}
.registration-selection input[type=radio].radio-yellow + label,
.registration-selection input[type=radio].radio-yellow + span.error + label {
    margin-left: 0;
}
.registration-selection input[type=radio].radio-yellow + label span.indicator,
.registration-selection input[type=radio].radio-yellow + span.error + label span.indicator {
    margin-left: 20px;
    margin-right: 0;
}
.registration-selection label:first-of-type {
    margin-right: 60px;
}
#myaccount-container .facebook-registration {
    padding: 15px 0;
}
@media (min-width: 768px) {

    #myaccount-container form-row:not(.squaredTwo) label {
        width: 25%;
    }
}
#myaccount-container input[type="text"],
#myaccount-container input[type="password"] {
    width: 70.5%;
}
#myaccount-container #RegistrationForm fieldset {
    padding-bottom: 55px;
}
#myaccount-container #RegistrationForm fieldset:first-of-type {
    padding-bottom: 15px;
}
#myaccount-container #RegistrationForm .form-row.squaredTwo {
    margin-top: 25px;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer {
    padding: 30px 0 25px 0;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer .form-caption {
    margin-left: 0;
    margin-top: 0;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer .form-caption a {
    text-decoration: underline;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer .form-caption div {
    padding-top: 5px;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer .form-row.form-row-button {
    float: right;
    width: auto;
    margin: 0;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer .form-row.form-row-button .button-yellow {
    padding: 10px 20px;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer .form-row.form-row-button a.button-white {
    font-weight: bold;
    padding: 8px;
    margin-right: 30px;
}
#myaccount-container #RegistrationForm .myaccount-container-form-footer .form-row.form-row-button a:hover {
    text-decoration: none;
}
ul.address-list {
    padding: 0 0 0 25px;
    margin-top: 0;
}
ul.address-list li {
    float: left;
    list-style: none outside none;
    margin: 0;
    min-height: 135px;
    padding: 1em 0;
    width: 30%;
}
ul.address-list li.default {
    float: none;
    width: auto;
}
ul.address-list .mini-address-title {
    font-style: italic;
}
ul.payment-list {
    overflow: hidden;
    padding: 0;
}
ul.payment-list li {
    float: left;
    list-style: none outside none;
    margin: 0;
    min-height: 110px;
    width: 33%;
}
/* Order History */

#wrapper.pt_order {
    width: 100%;
}
#wrapper.pt_order #main h1 {
    border-bottom: 1px solid #b1b1b0;
    padding-bottom: 7px;
}
.pt_order #main ul.search-result-items {
    font-size: 13px;
    float: left;
}
.pt_order #main ul.search-result-items > li {
    border-bottom: 1px solid #b1b1b0;
    float: left;
    padding: 30px 0 0;
    width: 100%;
}
.pt_order #main ul.search-result-items > li:last-child {
    border-bottom: 0;
}
#wrapper.pt_order #main {
    width: 1000px;
    padding-right: 0;
    margin: 0 auto;
    box-sizing: border-box;
}
.pt_order #dwfrm_orders .search-result-options {
    float: left;
}
.pt_order #dwfrm_orders .search-result-options table {
    width: auto;
}
.pt_order #dwfrm_orders .search-result-options table td {
    font-size: 13px;
}
.pt_order .pagination {
    margin-top: 0;
}
.pt_order .pagination li {
    padding: 0 0.5em;
}
.pt_order .pagination .results-hits {
    padding: 0;
}
.pt_order .pagination .current-page-label {
    margin-left: 3em;
}
.pt_order address {
    font-style: normal;
}
.order-history-header {
    padding-right: 25px;
}
.order-history-header button {
    float: right;
}
.order-history-header a.button-yellow {
    text-decoration: none;
}
.order-history-header .order-date {
    float: left;
    margin: 2px 0;
    width: 60%;
}
.order-history-header .order-status {
    float: left;
    margin: 2px 0;
    width: 60%;
}
.order-history-header .order-number {
    float: left;
    margin: 2px 0;
    width: 60%;
}
.order-history-items {
    margin: 20px 0;
}
.order-history-table th {
    padding: 3px 0;
}
.order-history-table td {
    padding: 5px 10px 5px 0;
}
.order-history-table ul {
    margin: 0;
    padding: 0;
}
.order-history-table li {
    list-style: none outside none;
}
.order-history-table th.order-shipped-to {
    width: 33%;
}
.order-history-table th.order-items {
    width: 33%;
}
.order-history-table th.order-total-price {
    width: 33%;
}
.order-history-table .toggle {
    cursor: pointer;
    display: block;
}
.js .order-history-table li.hidden {
    display: none;
}
.order-history-header a {
    font: -webkit-small-control;
    cursor: pointer;
    color: @red;
    float: right;
    padding: 0.6em 2em 0.5em;
    font-size: 20px;
    font-weight: normal;
}
.order-history-header a:hover {
    color: @red;
    text-decoration: none;
}
.order-history-items {
    float: left;
    width: 100%;
}
/* Order Details */

#wrapper.pt_order #main h2.content-header {
    font-size: 15px;
    text-transform: uppercase;
}
#wrapper.pt_order #main .section-header {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 10px 10px 0;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
}
#wrapper.pt_order #main table.item-list tr {
    border-bottom: none;
}
#wrapper.pt_order #main table.item-list td {
    padding: 5px 0;
}
#wrapper.pt_order #main table.item-list td div {
    padding: 3px 0;
}
#wrapper.pt_order #main table.item-list .order-number {
    font-size: 17px;
}
#wrapper.pt_order #main table.item-list .order-subtotal {
    font-weight: normal;
}
#wrapper.pt_order #main table.item-list table.order-totals-table tr:first-child td {
    padding-top: 0;
}
#wrapper.pt_order #main table.item-list table.order-totals-table tr:last-child td {
    border-bottom: none;
}
#wrapper.pt_order #main table.item-list table.order-totals-table td {
    padding: 5px;
    border-bottom: 1px solid #aeaeae;
}
#wrapper.pt_order #main table.item-list table.order-totals-table td.name {
    text-align: left;
}
#wrapper.pt_order #main table.item-list table.order-totals-table tr:last-child {
    border-bottom: none;
}
#wrapper.pt_order #main table.shipment-list {
    margin-top: 15px;
}
#wrapper.pt_order #main table.shipment-list td {
    margin: 0;
    padding: 15px 0;
    border-bottom: 1px solid #adadad;
}
#wrapper.pt_order #main table.shipment-list thead th:last-child,
#wrapper.pt_order #main table.shipment-list .order-shipment-details {
    padding-left: 15px;
}
#wrapper.pt_order #main table.shipment-list td.content-image {
    width: 14%;
}
#wrapper.pt_order #main table.shipment-list td.content-image img {
    max-width: 233px;
    padding-right: 20px;
}
#wrapper.pt_order #main table.shipment-list td.content-description {
    width: 25%;
    padding-right: 10px;
}
#wrapper.pt_order #main table.shipment-list td.content-quantity,
#wrapper.pt_order #main table.shipment-list td.content-price {
    font-size: 14px;
}
#wrapper.pt_order #main table.shipment-list .content-header {
    padding: 20px 0 10px 0;
    font-size: 14px;
    border-bottom: 1px solid #aeaeae;
    text-transform: uppercase;
}
#wrapper.pt_order #main table.shipment-list .product-list-item .name {
    font-size: 13px;
    margin-bottom: 5px;
}
#wrapper.pt_order #main .return-to-history {
    float: left;
    margin-top: 1em;
}
#wrapper.pt_order #main .return-to-shopping {
    float: right;
    margin-top: 1em;
}
/* wishlist */
/* ------------------------------------- */

.pt_wish-list .primary-content h1 {
    border-bottom: 1px solid #afafaf;
    padding-bottom: 10px;
}
.pt_wish-list .form-inline form {
    background: none;
}
.pt_wish-list .list-table-header {
    padding: 2em;
}
.pt_wish-list #main {
    position: relative;
    z-index: 0;
}
.pt_wish-list .list-table-header p {
    color: #999999;
    float: right;
    margin: 0;
    overflow: hidden;
    width: 40%;
}
.pt_wish-list .primary-content .list-table-header h2 {
    float: left;
}
.pt_wish-list .item-details .product-list-item {
    max-width: 210px;
}
.img-send-facebook {
    width: 34px;
    height: 34px;
}
.img-send-twitter {
    width: 34px;
    height: 34px;
    filter: invert(1);
}
.wishlist-page #primary {
    width: 810px;
}
.social-icons a,
.social-icons a:hover {
    text-decoration: none;
}
img.amazon-social-icon {
    width: 34px;
    height: 34px;
    filter: invert();
}
.facebook img {
    width: 34px;
    height: 34px;
    filter: invert();
}
.twitter img {
    width: 34px;
    height: 34px;
    filter: invert();
}
.google-plus img {
    width: 34px;
    height: 34px;
    filter: invert();
}
.pinterestShare img {
    width: 34px;
    height: 34px;
    filter: invert();
}
.pt_wish-list .input-text {
    background: none repeat scroll 0 0 @white;
    border: 1px solid @gray;
    color: @gray;
}
.wishlist-page .no-items {
    margin-top: 2em;
}
.wishlist-page .no-items .message {
    display: inline-block;
}
.wishlist-page .no-items .button-other {
    color: white;
    float: right;
    margin-left: 20px;
}
.wishlist-page .list-table-header .private-public {
    float: right;
}
.wishlist-page .empty-divide {
    margin-top: 24px;
}
.wishlist-page .private-public-fieldset {
    display: inline-block;
    width: 100%;
    margin-top: 33px;
}
.wishlist-page .giftCert {
    border-bottom: 1px solid #afafaf;
    padding: 3em 0;
}
.wishlist-page .giftCert .description {
    width: 70%;
    display: inline-block;
    font-size: 12px;
}
.wishlist-page .giftCert .addGiftCert {
    float: right;
}
.wishlist-page .search-header,
.pt_wish-list .modify-header {
    margin: 0;
}
.pt_wish-list .find-fieldset {
    margin-top: 29px;
}
.pt_wish-list .find-fieldset .form-row.find {
    margin-right: 1%;
}
.pt_wish-list .find-fieldset .form-row.form-row-button {
    margin: 0;
    width: auto;
}
.pt_wish-list .find-fieldset .input-text {
    width: 154px;
    margin-left: 8px;
}
.pt_wish-list .find-fieldset .form-row.find.email {
    line-height: 30px;
    margin-right: 0;
}
.pt_wish-list .list-table-header {
    padding-top: 10px;
}
.pt_wish-list .search-divide {
    margin-top: 29px;
}
.pt_wish-list .find-fieldset label {
    width: 50px;
    height: 32px;
}
.wishlist-page #editAddress {
    background: none repeat scroll 0 0 @white;
    float: left;
    color: @gray;
    -webkit-appearance: none;
}
.wishlist-page #editAddress option:after {
    content: "DOWN";
}
.wishlist-page .label.editAddress {
    float: left;
    padding: 0.9em 0;
    color: @gray;
    width: 30%;
}
.wishlist-page .list-table-header .form-row.label-above {
    margin-top: 29px;
}
.wishlist-page .option-priority {
    display: none;
}
.wishlist-page .option-quantity-desired input {
    background-color: initial;
}
.wishlist-page .item-list {
    width: 100%;
    clear: both;
}
.wishlist-page .item-list .item-container .item-details .wishlist-edit-details {
    padding-top: 10px;
}
.wishlist-page .item-list .item-container .item-details .wishlist-edit-details a {
    text-decoration: none;
}
.wishlist-page .item-list .item-container .item-details .product-list-item .name {
    margin-bottom: 0px !important;
}
.wishlist-page .item-list .item-container .item-details .product-list-item .name a {
    text-decoration: none;
}
.wishlist-page .item-list .item-container .item-image img {
    width: 80px;
    height: 80px;
}
.wishlist-page td.item-add-to-cart .option-add-to-cart label {
    color: @gray;
    font-size: 12pt;
    font-weight: initial;
    width: auto;
    padding-top: 4px;
    text-transform: uppercase;
}
.wishlist-page td.item-add-to-cart .option-add-to-cart input {
    text-align: center;
    background: none;
    border: 1px solid @gray;
    height: 28px;
    width: 28px;
    padding: 0;
}
.wishlist-page fieldset.item-dashboard {
    color: @gray;
}
.wishlist-page .option-add-to-cart label {
    width: 100%;
}
.wishlist-page .item-list .item-add-to-cart {
    width: auto;
    display: inline-block;
    float: right;
    clear: right;
    padding-top: 0;
    margin-right: 20px;
}
.wishlist-page .item-list td {
    float: left;
}
.wishlist-page .item-list td.item-image {
    padding-right: 0px;
}
.wishlist-page .selectboxit-container {
    width: 70%;
}
.wishlist-page .selectboxit-container #editAddressSelectBoxIt {
    width: 100% !important;
}
.wishlist-page td.item-dashboard .option-date-added .value {
    color: #fff;
    font-weight: initial;
}
.wishlist-page .option-add-to-cart .container .quantity,
.wishlist-page .option-add-to-cart .container .submit {
    display: inline-block;
}
.wishlist-page .option-add-to-cart .container .quantity {
    width: 75px;
}
.wishlist-page .send-to-friend {
    color: @gray;
    float: left;
    text-decoration: underline;
    display: inline-block;
    margin-left: 1.5%;
    margin-right: 1.5%;
    margin-top: 7px;
}
.wishlist-page .send-list .description {
    color: @gray;
    float: left;
    width: 70%;
}
.wishlist-page .send-list .description a {
    color: @gray;
}
.wishlist-page .send-list .description a::before {
    content: "\A";
    white-space: pre; 
}
.wishlist-page .item-add-to-cart .option-add-to-cart .container {
    width: 280px;
}
.wishlist-page .item-add-to-cart .option-add-to-cart .container .quantity {
    margin-top: 5px;
    float: left;
}
.wishlist-page .item-add-to-cart .option-add-to-cart .container .quantity label {
    margin-right: 10px;
}
.wishlist-page .item-add-to-cart .option-add-to-cart .container .submit {
    float: right;
}
.wishlist-page .item-add-to-cart .option-add-to-cart .container.public .quantity {
    margin-bottom: 18px;
}
.wishlist-page .item-add-to-cart .option-add-to-cart .container.public .submit {
    clear: both;
}
.wishlist-page .item-add-to-cart .option-add-to-cart .container.public {
    width: auto;
    width: initial;
    display: inline-block;
}
.wishlist-page .item-list .item-container .item-dashboard {
    font-size: 13px;
    float: right;
    padding-right: 3px;
}
.wishlist-page .item-dashboard .item-option.option-toggle-public .form-row.squaredTwo {
    display: block;
    margin-bottom: 0.5em;
}
.wishlist-page .option-toggle-public .squaredTwo input {
    float: left;
    margin: 0;
}
.wishlist-page .option-toggle-public .squaredTwo label {
    width: 140px !important;
    float: left;
    padding-top: 0.5em;
    padding-left: 1em;
}
.wishlist-page .send-list .social-icons {
    display: inline-block;
    float: left;
    margin-right: 1em;
}
.wishlist-page .send-list {
    margin-top: 10px;
    border-bottom: 1px solid #afafaf;
    display: inline-block;
    padding-bottom: 10px;
}
.wishlist-page table.item-list td {
    padding: 17px;
}
.wishlist-page #editAddressSelectBoxItOptions {
    width: 100%;
}
.pt_wish-list .wish-list-results-table tr {
    border-bottom: 1px solid #afafaf;
}
.pt_wish-list .wish-list-results-table .table-headers th {
    padding-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}
.pt_wish-list .wish-list-results-table .table-headers .last-name {
    width: 25%;
}
.pt_wish-list .wish-list-results-table .table-headers .first-name {
    width: 25%;
}
.pt_wish-list .wish-list-results-table .table-headers .location {
    width: 40%;
}
.pt_wish-list .wish-list-results-table .table-headers .view {
    width: 10%;
}
.pt_wish-list .wish-list-results-table .table-body .view {
    padding-top: 18px;
}
.pt_wish-list .wish-list-results-table .table-body .text {
    line-height: 35px;
    font-size: 16px;
}
.pt_wish-list .form-inline form {
    width: 100%;
}
.pt_wish-list .wishlist-search-header {
    margin-bottom: 23px;
}
.pt_wish-list .search-divide {
    display: none;
}
.wishlist-page table.public .item-details {
    width: 30%;
}
.wishlist-page table.public td.item-dashboard {
    width: 30%;
}
.wishlist-page table.public .item-add-to-cart {
    width: 20%;
    min-width: 182px;
}
.wishlist-page table.public.item-list td {
    padding: 11px;
    box-sizing: border-box;
}
.wishlist-page table.public .is-in-stock {
    margin-bottom: 12px;
}
.pt_wish-list .landing.col-1 label {
    width: 50px;
    padding-top: 0;
}
.pt_wish-list .landing.col-1 .form-row {
    width: 222px;
    float: left;
    clear: none;
    margin-top: 10px;
}
.pt_wish-list .landing.col-1,
.pt_wish-list .landing.col-2 {
    width: 100%;
    float: left;
}
.pt_wish-list .landing.col-1 .form-row-button {
    margin: 0;
    width: auto;
}
.pt_wish-list .landing.col-1 .form-row .input-text {
    width: 150px;
    margin: 0;
}
.pt_wish-list .landing.col-1 .form-row.email span {
    line-height: 33px;
}
.pt_wish-list .landing.col-1 .form-row-button button {
   	margin: 12px 0 0 10px;
}
.pt_wish-list .landing.col-1 .search-header {
    border-bottom: 1px solid #afafaf;
    padding-bottom: 10px;
}
.pt_wish-list .landing.col-1 .section-header,
.pt_wish-list .landing.col-1 .section-body {
    display: none;
}
.pt_wish-list .landing.col-1 .login-general {
    margin: 0;
}
.pt_wish-list .landing.col-2 .login-account,
.pt_wish-list .landing.col-2 .login-create-account {
    float: left;
    width: 48%;
}
.pt_wish-list .landing.col-2 .login-account {
    padding-right: 1.5%;
    border-right: 1px solid #afafaf;
}
.pt_wish-list .landing.col-2 .login-create-account {
    margin-left: 1.5%;
}
.pt_wish-list .landing.col-2 .your-wish-list {
    border-bottom: 1px solid #afafaf;
    padding-top: 26px;
    padding-bottom: 10px;
}
.pt_wish-list .landing.col-2 .returning-customers .form-row.required label,
.pt_wish-list .landing.col-2 .returning-customers .form-row.required label {
    width: 110px;
    text-align: right;
}
.pt_wish-list .landing.col-2 .input-text,
.pt_wish-list .landing.col-2 .input-text-pw {
    width: 65%;
}
.pt_wish-list .landing.col-2 .remember-me.squaredTwo {
    margin-left: 31.75%;
}
.pt_wish-list .login-account .dialog-required {
    margin-right: 0;
}

.pt_wish-list h2 {
    font-size: 16px;
}
.pt_wish-list #primary h2 {
    font-weight: normal;
}
.pt_wish-list .returning-customers .form-row-button {
    margin: 0;
}
.pt_wish-list .find-fieldset {
    border-bottom: 1px solid #afafaf;
    padding-bottom: 25px;
}
.pt_wish-list .find-fieldset .search-divide {
    display: none;
}
.pt_wish-list .landing.col-2 .create-acct .content-asset {
    padding-top: 0;
}
.pt_wish-list .landing.col-2 .create-benefits {
    font-size: 14px;
}
.pt_wish-list .landing.col-2 .create-benefits .fl {
    margin-top: 10px;
}
.pt_wish-list .landing.col-2 .login-create-account .create-benefits .content-asset {
    padding-top: 0;
}
/* gift registry */
/* ------------------------------------- */

.pt_gift-registry .create-new-registry button {
    float: right;
    margin-top: 10px;
}
.pt_gift-registry .add-gift-cert {
    margin-bottom: -1px;
    overflow: auto;
}
.pt_gift-registry .hdr-options {
    width: 5%;
}
.page-content-tab-navigaton button {
    background: none repeat scroll 0 0 #D9D9D9;
    border-color: #EFEFEF;
    border-width: 1px;
    color: #999999;
    font-size: 1.1em;
    font-style: italic;
    font-weight: 400;
    margin: 0 0 -1px;
}
.page-content-tab-navigaton button.selected {
    background: none repeat scroll 0 0 #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}
.page-content-tab-wrapper {
    border: 1px solid #EFEFEF;
    padding: 5px;
}
.gift-reg-purchases .item-purchased-by dd {
    float: left;
    font-weight: bold;
    margin: 0 1em 0 0;
    position: relative;
    width: auto;
}
.gift-reg-purchases .item-purchased-by dt {
    clear: both;
    float: left;
    margin: 0 1em 0 0;
    position: relative;
    width: auto;
}
.gift-reg-purchases .quantity-purchased {
    color: #fff;
}
.gift-registry-advanced {
    clear: both;
}
.gift-registry-advanced .toggle-content {
    padding: 0px;
}
.gift-registry-advanced .event-month {
    clear: both;
}
.gift-registry-advanced .event-city {
    clear: both;
}
.pt_gift-registry .primary-content .list-table-header h2 {
    float: left;
}
.pt_gift-registry .form-inline form {
    background: none;
}
.pt_gift-registry .breadcrumb {
    padding-left: 5px;
}
.pt_gift-registry .secondary-navigation {
    padding-left: 5px;
}
.pt_gift-registry #secondary .account-nav-asset {
    margin-left: 5px;
    background: url("../images/accordion-divider.png") repeat-x scroll left top transparent;
}
.pt_gift-registry .col-1,
.pt_gift-registry .col-2 {
    width: 100%;
}
.pt_gift-registry .col-2 .login-account {
    width: 48%;
    float: left;
    padding-right: 1.5%;
    border-right: 1px solid #afafaf
}
.pt_gift-registry .col-2 .login-create-account {
    width: 48%;
    float: left;
    margin-left: 1.5%;
}

.pt_gift-registry button.button-secondary {
    background: transparent none;
}
.pt_gift-registry .login-box-content {
    margin-top: 15px;
}
.pt_gift-registry .registry-main .gift-registry-status-button {
    text-align: right;
}
.pt_gift-registry .registry-main .gift-registry-status-button button {
    margin: 1.5em 0.5em;
    padding: 8px 20px;
}
.pt_gift-registry#wrapper {
    max-width: 1010px;
    width: 100%;
    position: relative;
    z-index: 0;
}
.pt_gift-registry #main {
    background-size: 100% 100%;
    width: 100%;
    padding-right: 10%;
    position: relative;
    z-index: 0;
}
.pt_gift-registry .event-search button.button-base {
    margin: 0;
    margin-top: -3px;
    padding: 7px 25px;
}
.pt_gift-registry .event-search label {
    width: 53px;
    float: left;
    padding: 0;
    margin-right: 8px;
    font-weight: normal;
    font-size: 11px;
}
.pt_gift-registry .event-search .form-row {
    display: inline-block;
    float: left;
    clear: none;
    width: 28.5%;
}
.pt_gift-registry .event-search .find {
    width: auto;
    width: initial;
    margin-top: 21px;
}
/* Need both width here to support IE */

.pt_gift-registry .event-search .event-city {
    clear: both;
}
.pt_gift-registry .event-search .input-text {
    max-width: 170px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    float: left;
}
.pt_gift-registry .event-search .selectboxit-container {
    width: 100%;
    max-width: 170px;
    float: left;
}
.pt_gift-registry .event-search .selectboxit-container .selectboxit-options {
    width: 100%;
}
.pt_gift-registry .event-search .selectboxit-container .input-select {
    margin-right: 8px;
    width: 100% !important;
}
.pt_gift-registry .page-content .primary-content {
    width: 83%;
}
.pt_gift-registry .gift-registry-advanced .toggle-content .search-row {
    width: 100%;
    display: inline-block;
}
.pt_gift-registry .event-search .event-country,
.pt_gift-registry .event-search .event-year {
    width: 35%;
}
.pt_gift-registry .event-country label {
    width: 80px;
}
.pt_gift-registry .gift-registry-advanced .form-row.event-year label {
    margin-left: 30px;
}
.pt_gift-registry .event-search .toggle-content .form-row.event-name {
    width: 30%;
}
.pt_gift-registry .event-search .toggle-content .form-row.event-month {
    width: 30%;
}
.pt_gift-registry .event-search .toggle-content .form-row.event-city {
    width: 30%;
}
.pt_gift-registry .event-search .toggle-content .form-row.event-state {
    width: 30%;
}
.pt_gift-registry .login-account .login-box-content .form-row.remember-me {
    margin-left: 28%;
}
.pt_gift-registry .login-account .login-box-content .form-row.remember-me label {
    width: 160px;
}
.pt_gift-registry .login-account .form-row label {
    width: 25%;
}
.pt_gift-registry .login-account .form-row .input-text,
.pt_gift-registry .login-account .form-row .input-text-pw {
    width: 69%;
}
.pt_gift-registry .your-gift-reg {
    border-bottom: 1px solid #afafaf;
}
.pt_gift-registry .login-account .dialog-required {
    margin-right: 0;
}
.pt_gift-registry .login-search-gift .section-header {
    display: none;
}
.pt_gift-registry .login-search-gift .login-box-content p {
    display: none;
}
.pt_gift-registry #primary .title {
    border-bottom: 1px solid #afafaf;
    padding-bottom: 10px;
    margin: 0;
}
.pt_gift-registry .registry-page-header .contentframeImage {
    width: 1302px;
    top: -37px;
    margin-left: 16px;
}
/* Creation confirmation */

.pt_gift-registry .confirm {
    margin-left: 2.5%;
    letter-spacing: 0.05em;
}
.pt_gift-registry .confirm .verify {
    font-size: 1.166em;
    padding-bottom: 25px;
    border-bottom: 1px solid #afafaf;
    margin-bottom: 25px;
}
.pt_gift-registry .confirm .confirm-details {
    display: table;
}
.pt_gift-registry .confirm .confirm-details dl {
    clear: none;
    width: 31%;
    display: table-cell;
    border-left: 1px solid #afafaf;
    padding-left: 2.5%;
    margin-top: 5px;
}
.pt_gift-registry .confirm .confirm-details dl:first-of-type {
    border-left: none;
    padding-left: 0;
}
.pt_gift-registry .confirm .confirm-details dl h3 {
    display: block;
    margin-bottom: 10px;
}
.pt_gift-registry .confirm .confirm-details dl dt {
    width: 40%;
    margin-top: 15px;
    margin-right: 5%;
}
.pt_gift-registry .confirm .confirm-details dl dd {
    margin-top: 15px;
}
.pt_gift-registry .confirm .form-row-button {
    width: auto;
    margin: 0;
    padding-top: 30px;
}
.pt_gift-registry .confirm .form-row-button button.submit,
.pt_gift-registry .shipping-info .form-row-button button.submit {
    float: right;
    padding: 8px 22px;
}
.pt_gift-registry .confirm .form-row-button button.cancel {
    padding: 8px 22px;
}
.pt_gift-registry .registry-main .page-content-tab-wrapper form.gift-registry-list fieldset .item-list tr.first {
    border-bottom: 1px solid #adadad;
}
.pt_gift-registry .registry-main .page-content-tab-wrapper fieldset .item-list tr.first {
    border-bottom: 0px solid #adadad;
}
/* Gift Registry Start */

.pt_gift-registry .start {
    width: 99%;
}
.pt_gift-registry .start .input-text {
    background-color: @white;
    border: 1px solid @gray;
}
.pt_gift-registry .start label {
    width: 50px;
    font-size: 11px;
    text-transform: uppercase;
}
.pt_gift-registry .start .event-search .event-country,
.pt_gift-registry .start .event-search .event-year {
    width: 35%;
}
.pt_gift-registry .start .event-country label {
    width: 80px;
}
.pt_gift-registry .start .gift-registry-advanced {
    margin-top: 24px;
    display: inline-block;
    margin-bottom: 15px;
}
.pt_gift-registry .start .search-row.headers {
    margin-top: 24px;
}
.pt_gift-registry .start .search-row.content {
    margin-top: 17px;
}
.pt_gift-registry .start .event {
    font-size: 14px;
    line-height: 33px;
    padding-left: 0;
    padding-right: 1em;
}
.pt_gift-registry .start .hdr-name {
    width: 21.8%;
}
.pt_gift-registry .start .hdr-type {
    width: 20.5%;
}
.pt_gift-registry .start .hdr-header {
    width: 14.7%;
}
.pt_gift-registry .start .hdr-location {
    width: 24%;
}
.pt_gift-registry .start .hdr-details {
    width: 13%;
}
.pt_gift-registry .start .hdr-options {
    width: 6%;
}
.pt_gift-registry .start .section-header {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}
.pt_gift-registry .start .event-details img {
    margin-left: -14px;
}
.pt_gift-registry .start .event-details a {
    display: inline-block;
    overflow: visible;
    width: 100%;
}
.pt_gift-registry .start .delete-registry {
}
.pt_gift-registry .start .delete-registry a {
    padding: 2em;
}
.pt_gift-registry .start .delete-registry a img {
    margin-top: 8px;
}
.pt_gift-registry .start .search {
    border-bottom: 1px solid #afafaf;
    margin-bottom: 36px;
    margin-top: 15px;
}
.pt_gift-registry .primary-content h1 {
    font-size: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #afafaf;
    margin-bottom: 0px;
}
.pt_gift-registry .start .create-new-registry button {
    margin-top: 29px;
}
.pt_gift-registry .gift-registry-advanced .toggle {
    font-size: 12px;
    color: @gray;
    text-decoration: none;
}
.pt_gift-registry .label-indent {
    display: block;
    font-size: 14px;
    width: 22%;
    text-align: right;
    margin-bottom: 22px;
}
/* Purchases */

.pt_gift-registry .purchases .item-list.gift-reg-purchases .item-image {
    width: 15%;
}
.pt_gift-registry .purchases .item-list.gift-reg-purchases .item-details {
    width: 29%;
    font-size: 14px;
}
.pt_gift-registry .purchases .item-list.gift-reg-purchases .item-purchased-by {
    width: 24%;
}
.pt_gift-registry .purchases .item-list.gift-reg-purchases .item-purchase-details {
    width: 32%;
}
.pt_gift-registry .purchases .item-list.gift-reg-purchases .item-purchased-by div {
    font-size: 14px;
    margin-bottom: 9px;
}
.pt_gift-registry .purchases .item-list.gift-reg-purchases .item-purchase-details div {
    font-size: 14px;
    margin-bottom: 9px;
}
.pt_gift-registry .purchases .item-list.gift-reg-purchases .product-list-item .name {
    font-size: 16px;
}
.pt_gift-registry .purchases .product-list-item .name {
    margin-bottom: 12px;
}
.pt_gift-registry .purchases .item-details .product-availability-list {
    margin-top: 12px;
}
.pt_gift-registry .purchases .squaredTwo input[type=checkbox]:checked + label:after {
    opacity: 1;
}
.pt_gift-registry .purchases .item-image {
    width: 15%;
}
.pt_gift-registry .purchases .item-details {
    width: 29%;
}
.pt_gift-registry .purchases .item-purchased-by {
    width: 56%;
}
/* split it up and make this 24% */

.pt_gift-registry .purchases .item-purchased-by-details {
    width: 32%;
}
.pt_gift-registry .primary-content h1.purchase-header {
    margin-bottom: 0;
}
.pt_gift-registry .purchases .purchases-header {
    font-size: 16px;
    margin-top: 34px;
    margin-bottom: 0;
}
.pt_gift-registry .purchases .gift-reg-purchases td {
    padding-top: 26px;
    padding-bottom: 26px;
    box-sizing: border-box;
}
.pt_gift-registry .purchases .notavailable {
    margin-top: 10px;
}
/* shipping info */

.pt_gift-registry .shipping-info .select-address .form-row-button {
    display: none;
}
.pt_gift-registry .tooltip {
    font-size: 12px;
    text-decoration: underline;
    color: @gray;
}
.pt_gift-registry .page-content-tab-wrapper {
    border: none;
}
.pt_gift-registry .page-content-tab-navigaton {
    border-bottom: 1px solid #afafaf;
}
.pt_gift-registry .shipping-info .selectboxit-container {
    margin-left: 2%;
    box-sizing: border-box;
    width: 48%;
}
.pt_gift-registry .shipping-info .selectboxit-container .selectboxit-options {
    max-height: 240px;
}
.pt_gift-registry .shipping-info .selectboxit-container span,
.pt_gift-registry .shipping-info .selectboxit-container .selectboxit-options a {
    background-color: #fff;
    color: #000;
}
.pt_gift-registry .shipping-info .selectboxit-default-arrow {
    border-top: 4px solid #000;
}
.pt_gift-registry .shipping-info .selectboxit-btn.selectboxit-enabled:hover,
.pt_gift-registry .selectboxit-btn.selectboxit-enabled:focus,
.pt_gift-registry .selectboxit-btn.selectboxit-enabled:active {
    background-color: #fff;
}
.pt_gift-registry .shipping-info .selectboxit.input-select {
    width: 100% !important;
}
.pt_gift-registry .shipping-info .select-address {
    padding: 0;
}
.pt_gift-registry .shipping-info .post-header {
    margin-bottom: 19px;
    margin-top: 34px;
}
.pt_gift-registry .shipping-info legend {
    margin-top: 21px;
    margin-bottom: 23px;
    display: inline-block;
    float: left;
}
.pt_gift-registry .shipping-info legend h2 {
    margin: 0 0 0 32px;
    display: inline-block;
}
.pt_gift-registry .shipping-info .form-caption,
.pt_gift-registry .event-info .form-caption {
    display: none;
}
.pt_gift-registry .shipping-info .with-helper-text {
    margin-top: -40px;
    width: 26%;
}
.pt_gift-registry .shipping-info .dialog-required {
    margin-right: 0;
}
.pt_gift-registry .shipping-info label {
    font-size: 12px;
    padding: 9px 0 0 0;
}
.pt_gift-registry span.button-white {
    font-weight: bold;
    padding: 8px;
    display: inline-block;
    color: @blue;
    background-image: url('../images/button-yellow.png');
}
.pt_gift-registry .shipping-info .form-row-button {
    width: auto;
    margin-left: 0;
}
.pt_gift-registry .shipping-info .form-row-button button.cancel {
    padding: 8px 22px;
}
.pt_gift-registry .shipping-info .simple-submit-container {
    display: none;
}
.pt_gift-registry .primary-content h1.address-header {
    margin-bottom: 0;
}
.pt_gift-registry .shipping-info .address-before {
    border-bottom: 1px solid #afafaf;
    padding-bottom: 25px;
}
.pt_gift-registry .shipping-info .form-row {
    margin-bottom: 18px;
}
.pt_gift-registry .shipping-info .form-row.squaredTwo {
    margin-top: 6px;
    box-sizing: border-box;
    float: left;
}
.pt_gift-registry .event-info .form-row.event-date {
    position: relative;
}
.pt_gift-registry .event-info .form-row.event-date label {
    padding-top: 0;
}
.pt_gift-registry .event-info .form-row.event-date .form-caption {
    display: block;
    position: absolute;
    left: -100px;
    top: 10px;
    font-size: 11px;
}
/* event info */

.pt_gift-registry .page-content-tab-navigaton button,
.pt_gift-registry .button {
    background: none;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    padding: 7px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    margin: 0;
    border: none;
    color: @gray;
    text-transform: uppercase;
}
.pt_gift-registry .page-content-tab-navigaton button:hover {
    background: none repeat scroll 0 0 #979797;
}
.pt_gift-registry .page-content-tab-navigaton .selected {
    background: none repeat scroll 0 0 #979797;
    border: none;
}
.pt_gift-registry .event-info .selectboxit-container {
    margin-left: 2%;
    box-sizing: border-box;
    width: 48%;
}
.pt_gift-registry .event-info .selectboxit-container span,
.pt_gift-registry .event-info .selectboxit-container .selectboxit-options a {
    background-color: #fff;
    color: #000;
}
.pt_gift-registry .event-info legend {
    margin-top: 21px;
    margin-bottom: 23px;
    display: inline-block;
    float: left;
}
.pt_gift-registry .event-info legend h2 {
    margin: 0;
    display: inline-block;
}
.pt_gift-registry .event-info .form-row-button {
    width: 15%;
    margin-left: 0;
    float: right;
    margin-right: -23px;
}
.pt_gift-registry .event-info .input-text,
.pt_gift-registry .shipping-info .input-text {
    width: 48%;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid @gray;
    color: #000;
}
.pt_gift-registry .event-info label {
    font-size: 12px;
    padding: 9px 0 0 0;
}
.pt_gift-registry .event-info .selectboxit-default-arrow {
    border-top: 4px solid #000;
}
.pt_gift-registry .event-info .selectboxit.input-select {
    width: 100% !important;
}
.pt_gift-registry .event-info .selectboxit-container .selectboxit-options {
    max-height: 240px;
}
.pt_gift-registry .event-info .second-participant {
    margin-top: 42px;
}
.pt_gift-registry .primary-content h1.event-header {
    margin-bottom: 0;
}
.pt_gift-registry .event-info .event-city {
    border-bottom: 1px solid #afafaf;
    padding-bottom: 40px;
}
.pt_gift-registry .event-info .form-row {
    margin-bottom: 18px;
}
.pt_gift-registry h2 {
    font-size: 14px;
    text-transform: none;
}
.pt_gift-registry .search-row.headers {
    margin-top: 24px;
}
.pt_gift-registry .search-row.content {
    margin-top: 17px;
}
.pt_gift-registry legend {
    background: none;
    border: none;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;
}
.pt_gift-registry .page-content-tab-wrapper {
    border: none;
    padding: 5px;
}
.pt_gift-registry .page-content-tab-wrapper fieldset {
    margin-bottom: 20px;
}
.pt_gift-registry .page-content-tab-navigaton button,
.pt_gift-registry .button {
    background: none;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    padding: 7px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    margin: 0;
    border: none;
    color: @gray;
    text-transform: uppercase;
}
.pt_gift-registry .page-content-tab-navigaton button:hover {
    background: none repeat scroll 0 0 #979797;
}
.pt_gift-registry .page-content-tab-navigaton .selected {
    background: none repeat scroll 0 0 #979797;
    border: none;
}
.pt_gift-registry .send-list {
    margin-top: 10px;
    border-bottom: 1px solid #afafaf;
    display: inline-block;
    padding-bottom: 10px;
}
.pt_gift-registry .add-gift-cert {
    margin-top: 17px;
    border-bottom: 1px solid #afafaf;
    padding-bottom: 22px;
    float: right;
}
.pt_gift-registry .description {
    width: 68%;
    display: inline-block;
    font-size: 12px;
}
.pt_gift-registry .add-gift-cert button {
    float: right;
    padding: 8px 20px;
}
.pt_gift-registry legend {
    background: none;
    border: none;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;
}
.pt_gift-registry .item-list {
    width: 100%;
    clear: both;
}
.pt_gift-registry td.item-add-to-cart .option-add-to-cart label {
    color: @gray;
    font-size: 12pt;
    font-weight: initial;
    width: auto;
    padding-top: 4px;
    text-transform: uppercase;
}
.pt_gift-registry td.item-add-to-cart .option-add-to-cart input {
    text-align: center;
    background: none;
    border: 1px solid @gray;
    height: 28px;
    width: 28px;
    padding: 0;
}
.pt_gift-registry fieldset.item-dashboard {
    color: #fff;
}
.pt_gift-registry .option-add-to-cart .add-to-cart-registry {
    color: @white;
    padding: 0.5em;
}
.pt_gift-registry .option-add-to-cart label {
    width: 100%;
}
.pt_gift-registry .item-list .item-add-to-cart {
    width: auto;
    display: inline-block;
    float: right;
    padding-top: 0;
}
.pt_gift-registry .item-list td {
    float: left;
}
.pt_gift-registry .start .item-list td {
    float: none;
}
.pt_gift-registry .selectboxit-container {
    width: 70%;
}
.pt_gift-registry .selectboxit-container #editAddressSelectBoxIt {
    width: 100% !important;
}
.pt_gift-registry td.item-dashboard .option-date-added .value {
    color: @gray;
    font-weight: initial;
}
.pt_gift-registry .option-add-to-cart .container .quantity,
.pt_gift-registry .option-add-to-cart .container .submit {
    display: inline-block;
}
.pt_gift-registry .option-add-to-cart .container .quantity {
    width: 80px;
}
.pt_gift-registry .send-to-friend {
    float: left;
    text-decoration: underline;
    display: inline-block;
    margin-left: 1.5%;
    margin-right: 1.5%;
    text-transform: none;
}
.pt_gift-registry .send-list .description a {
}
.pt_gift-registry .item-add-to-cart .option-add-to-cart .container {
    width: 100%;
    display: inline-block;
}
.pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity {
    margin-top: 5px;
    float: left;
    margin-left: 64px;
}
.pt_gift-registry .item-add-to-cart .option-add-to-cart .container .quantity label {
    margin-right: 10px;
}
.pt_gift-registry .item-add-to-cart .option-add-to-cart .container .submit {
    float: right;
}
.pt_gift-registry .item-add-to-cart .option-add-to-cart .container.public .quantity {
    margin-bottom: 18px;
}
.pt_gift-registry .item-add-to-cart .option-add-to-cart .container.public .submit {
    clear: both;
}
.pt_gift-registry .item-add-to-cart .option-add-to-cart .container.public {
    width: auto;
    width: initial;
    display: inline-block;
}
.pt_gift-registry .item-list .item-container .item-dashboard {
    font-size: 13px;
}
.pt_gift-registry .item-dashboard .item-option.option-toggle-public {
    height: 20px;
}
.pt_gift-registry .item-dashboard .item-option.option-toggle-public .form-row.squaredTwo {
    display: inline-block;
    width: 18px;
    height: 18px;
}
.pt_gift-registry .option-toggle-public .squaredTwo input {
    float: left;
}
.pt_gift-registry .option-toggle-public .squaredTwo label {
    left: 24px;
    padding-top: 0;
    text-transform: none;
    width: max-content !important;
    float: left;
}
.pt_gift-registry .option-toggle-public .squaredTwo label:after {
    font-size: 14px;
    top: 0px;
    left: -20px;
    content: '\2713';
    background: none;
}
.pt_gift-registry .send-list .social-icons {
    display: inline-block;
    float: left;
    margin-right: 1em;
}
.social-icons .img-send-facebook {
    border-radius: 90px;
    filter: invert(1);
}
.pt_gift-registry .send-list {
    margin-top: 10px;
    border-bottom: 1px solid #afafaf;
    display: inline-block;
    padding-bottom: 10px;
}
.pt_gift-registry table.item-list td {
    padding: 18px;
}
.pt_gift-registry #editAddressSelectBoxItOptions {
    width: 100%;
}
.pt_gift-registry table .item-details {
    width: 30%;
}
.pt_gift-registry table td.item-dashboard {
    width: 30%;
}
.pt_gift-registry table .item-add-to-cart {
    width: 20%;
    min-width: 182px;
}
.pt_gift-registry table .is-in-stock {
    margin-bottom: 12px;
}
.pt_gift-registry .registry-results-table .section-header {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}
.pt_gift-registry .login-search-gift .event-search span.error {
    text-align: left;
    margin-left: 63px;
}
.pt_gift-registry .event-search span.error {
    width: 100%;
    float: left;
    margin-left: 58px;
}
.pt_gift-registry span.error {
    display: inline-block;
    margin: 10px 0;
    width: 25.5%;
    float: right;
}
.pt_gift-registry .registry-main .selectboxit.input-select {
    width: 99% !important;
    box-sizing: border-box;
    margin-left: 17px;
}
.pt_gift-registry .registry-main .selectboxit-container {
    width: 57%;
}
.registry-main .heading {
    width: 48%;
    display: inline-block;
    float: left;
}
.registry-main .dialog-required {
    width: 48%;
    margin: 0;
    padding: 0;
    text-align: right;
}
.registry-main .event-info legend {
    width: 100%;
    display: inline-block;
    float: left;
    margin-top: 21px;
    margin-bottom: 35px;
}
.registry-main .participant legend {
    width: 100%;
    display: inline-block;
    float: left;
    margin-top: 21px;
    margin-bottom: 35px;
}
.registry-main .page-content-tab-wrapper label {
    width: 22%;
    padding: 0.5em 0 0 0;
}
.registry-main .selectboxit-options.selectboxit-list {
    width: 99%;
    margin-left: 16px;
}
.registry-main .form-row {
    margin: 0 0 17px;
}
.registry-main .heading,
.registry-main .participant h2 {
    font-size: 14px;
    text-transform: none;
}
.registry-main .participant h2 {
    width: 22%;
    text-align: right;
    margin-top: 0;
    margin-bottom: 23px;
    display: inline-block;
}
.registry-main .item-add-to-cart fieldset {
    border: none;
    margin-bottom: 31px;
}
.registry-main .item-list .item-add-to-cart {
    padding-bottom: 0;
}
.registry-main .participant .form-row-button {
    display: inline-block;
    width: auto;
    float: right;
    margin-right: 20%;
}
.pt_gift-registry .notavailable {
    color: @red;
}
.pt_gift-registry .registry-main .notavailable {
    width: 61%;
}
.pt_gift-registry .registry-main .item-availability .notavailable {
    width: 100%;
}
.pt_gift-registry .registry-main .item-container .item-image {
    box-sizing: border-box;
}
.pt_gift-registry .registry-main .item-container .item-details {
    width: 39.8%;
    box-sizing: border-box;
}
.pt_gift-registry .registry-main .item-container .item-availability {
    width: 20.5%;
    box-sizing: border-box;
}
.pt_gift-registry .registry-main .item-container .item-dashboard {
    width: 25%;
    box-sizing: border-box;
}
.pt_gift-registry .registry-main .item-container .item-add-to-cart {
    width: 45.1%;
    box-sizing: border-box;
    padding-top: 10px;
}
.pt_gift-registry .registry-main .item-container .item-details {
    font-size: 13px;
}
.pt_gift-registry .registry-main .item-list td {
    padding-top: 28px;
}
.pt_gift-registry .registry-main .item-container .product-list-item .name {
    font-size: 16px;
}
.pt_gift-registry .registry-main .item-container .product-list-item .name a {
    text-decoration: none;
}
.pt_gift-registry .registry-main .item-container .item-details .product-list-item .not-available {
    width: 56%;
}
.pt_gift-registry .registry-main .item-container .item-add-to-cart .option-add-to-cart {
    display: inline-block;
    float: right;
    width: 100%;
}
.pt_gift-registry .registry-main h1.content-header {
    margin-bottom: 0;
}
/* Global Gift Registry */

.pt_gift-registry {
    width: 100%;
}
.pt_gift-registry .input-text {
    background-color: @white;
    color: @gray;
}
.pt_gift-registry .login-search-gift .dialog-required {
    margin: 0;
    float: none;
    text-align: right;
}
.pt_gift-registry .login-search-gift .dialog-required em {
    font-size: 12px;
}
.pt_gift-registry .event-search {
    width: 100%;
    clear: both;
    margin-top: 15px;
}
.pt_gift-registry .event-search .form-row {
    margin: 0;
}
.pt_gift-registry .event-search .form-row.form-row-button {
    float: right;
    max-width: 15%;
    width: auto;
    margin: 0 -10px 0 0;
}
.pt_gift-registry .form-row.create-new-registry {
    margin-right: -10px;
}
.pt_gift-registry .event-search .event-country,
.pt_gift-registry .event-search .event-year {
    width: 35%;
}
.pt_gift-registry .event-country label {
    width: 80px;
}
.pt_gift-registry .gift-registry-advanced {
    margin-top: 24px;
    display: inline-block;
    width: 100%;
}
.pt_gift-registry .search-row.headers {
    margin-top: 24px;
}
.pt_gift-registry .search-row.content {
    margin-top: 17px;
}
.pt_gift-registry .event {
    font-size: 16px;
    line-height: 33px;
    padding-left: 0;
    padding-right: 0;
}
.pt_gift-registry .gift-registry-advanced .toggle {
    font-size: 12px;
    color: @gray;
    text-decoration: underline;
    cursor: pointer;
}
.pt_gift-registry .search {
    border-bottom: 1px solid #afafaf;
    margin-bottom: 36px;
}
.pt_gift-registry .primary-content h1 {
    font-size: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #afafaf;
    margin-bottom: 25px;
}
.pt_gift-registry .primary-content h3 {
    font-size: 1.1667em;
    font-weight: normal;
    display: inline-block;
    margin: 0;
}
.pt_gift-registry .event-info-container {
    padding-left: 2%;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #afafaf;
}
.pt_gift-registry .event-information-user-info {
    margin: 0 0 32px;
}
.pt_gift-registry .event-information-event-info {
    margin: 0 0 26px;
}
.pt_gift-registry .item-list .item-image {
    width: 12%;
}
.pt_gift-registry .item-list .item-details {
    width: 45.37%
}
.pt_gift-registry .item-list .item-dashboard {
    width: 30.93%;
}
.pt_gift-registry td.item-dashboard .option-add-to-cart input {
    text-align: center;
    background: none;
    border: 1px solid @gray;
    height: 28px;
    width: 28px;
    padding: 0;
}
.pt_gift-registry .option-priority {
    display: none;
}
.pt_gift-registry .product-list-item .name {
    margin-bottom: 19px;
}
.pt_gift-registry .item-details .product-availability-list {
    margin-top: 19px;
}
.pt_gift-registry .option-add-to-cart .qty {
    font-size: 16px;
}
.pt_gift-registry .form-inline form {
    background: none;
    padding: 0;
    width: 100%;
}
.pt_gift-registry td.item-dashboard {
    color: @gray;
}
.pt_gift-registry td.item-dashboard .option-date-added .value {
    color: @gray;
}
.pt_gift-registry td.item-dashboard .option-add-to-cart label {
    color: @gray;
}
.pt_gift-registry .list-table-header .details {
    border-top: 1px solid #afafaf;
    padding-top: 36px;
    margin-top: 22px;
}
.pt_gift-registry td.item-details .product-list-item .value {
    font-weight: 400;
}
.pt_gift-registry .option-quantity-desired .input-text {
    background: none;
}
.pt_gift-registry .option-quantity-desired label {
    text-transform: none;
}
/* Search results */

.pt_gift-registry .registry-results-table.item-list td {
    float: none;
    padding: 20px 0;
    line-height: 33px;
}
.pt_gift-registry .registry-results-table.item-list .event-view {
    line-height: 0;
}
.pt_gift-registry .registry-results-table .last-name {
    width: 16%;
}
.pt_gift-registry .registry-results-table .first-name {
    width: 17%;
}
.pt_gift-registry .registry-results-table .event-type {
    width: 20%;
}
.pt_gift-registry .registry-results-table .date {
    width: 15%;
}
.pt_gift-registry .registry-results-table .location {
    width: 21%;
}
.pt_gift-registry .registry-results-table .details {
    width: 11%;
}
.pt_gift-registry .registry-results-table .section-header {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.pt_gift-registry .primary-content h1.search-results {
    border-top: 1px solid #afafaf;
    padding-top: 36px;
    margin-top: 20px;
}
.pt_gift-registry .registry-results-table .row-results {
    font-size: 16px;
}
/* Public view */

.pt_gift-registry .public-view .list-table-header .print-page {
    display: none;
}
.pt_gift-registry .public-view .item-list .item-image,
.pt_gift-registry .public-view .item-list .item-details,
.pt_gift-registry .public-view .item-list .item-dashboard {
    box-sizing: border-box;
}
.pt_gift-registry .public-view .item-list .item-details {
    width: 57.38%;
}
.pt_gift-registry .public-view .item-list .item-dashboard {
    width: 27.93%;
}
.pt_gift-registry .public-view .option-add-to-cart {
    margin-top: 21px;
}
.pt_gift-registry .public-view .option-add-to-cart .add-to-cart {
    margin-top: 21px;
}
/* gift certificates */
/* ------------------------------------- */

.gift-certificate-purchase .label-above {
    width: 100%;
    margin-top: 18px;
}
.gift-certificate-purchase .label-above label {
    font-size: 12px;
    margin-right: 15px;
    text-align: right;
    width: 25.4%;
    box-sizing: border-box;
    display: inline-block;
    min-width: 206px;
}
.gift-certificate-purchase .label-above input {
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
}
.gift-certificate-purchase .label-above textarea {
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
}
.gift-certificate-purchase .button-fancy-medium {
    display: inline-block;
    margin-left: 12px;
}
.gift-certificate-purchase .cert-amount {
    width: 54%;
    display: inline-block;
    float: left;
}
.gift-certificate-purchase .cert-amount input {
    min-width: 206px;
    max-width: 25.4%;
}
.gift-certificate-purchase .add-to-cart {
    margin: 0;
    width: 100%;
}
.gift-certificate-purchase span.error {
    float: left;
    margin-left: 224px;
    min-width: 206px;
    color: @error;
    display: inline-block;
}
.gift-certificate-purchase .add-to-cart span.error {
    margin-top: 19px;
}
.gift-certificate-purchase .message {
    margin-top: 24px;
}
.gift-certificate-purchase .char-count {
    margin-left: 224px;
    margin-top: 10px;
}
.gift-certificate-purchase .main-form {
    border-bottom: 1px solid #afafaf;
}
.gift-certificate-purchase .main-form .dialog-required {
    width: 77%;
    float: left;
    text-align: right;
}
.gift-check-balance {
    margin-top: 27px;
}
.gift-check-balance h2 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 18px;
}
.gift-check-balance .description {
    margin-bottom: 24px;
}
.gift-cert-check-balance .gift-cert-balance-submit {
    margin-left: 35px;
    width: auto;
    display: inline-block;
    float: left;
    clear: none;
}
.gift-cert-check-balance .gift-cert-balance {
    width: 184px;
    display: inline-block;
    float: left;
}
.gift-cert-check-balance .gift-cert-balance label {
    display: none;
}
.gift-cert-check-balance input {
    width: 100%;
    box-sizing: border-box;
}
fieldset.gift-cert-check-balance {
    border-bottom: 1px solid #afafaf;
    padding-bottom: 35px;
}
.pt_account .terms-of-use {
    padding-top: 35px;
}
.pt_account .terms-of-use p {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 18px;
}
/* login pages */
/* ------------------------------------- */

.login-account {
    margin: 0 0 48px;
}
.login-box h3 {
    margin: 0;
}
.login-box #dwfrm_oauthlogin select#OAuthProvider {
    display: block;
    margin-bottom: 1em;
    width: 80%;
}
/* category landing page */
/* ------------------------------------- */

.bottom-banner-container {
    overflow: hidden;
}
.bottom-banner-container .bottom-banner-cell {
    float: left;
    overflow: hidden;
    text-align: center;
}
.bottom-banner-container .banner-cell-1 {
    width: 33%;
}
.bottom-banner-container .banner-cell-2 {
    width: 34%;
}
.bottom-banner-container .banner-cell-3 {
    width: 33%;
}
/* store locator */
/* ------------------------------------- */

.store-locator-header {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    font-family: Georgia, serif;
    font-size: 1.75em;
    font-style: italic;
    font-weight: 400;
    margin: 20px 0 0;
    padding: 20px 0;
}
.store-information {
    background: none repeat scroll 0 0 #E8E8E8;
    border-bottom: 1px solid #FFFFFF;
}
.store-name {
    font-weight: bold;
    margin: 0 0 10px;
}
.store-address,
.store-map {
    border-bottom: 1px solid #E8E8E8;
}
.store-locator-details {
    float: left;
    width: 44%;
}
.store-locator-details img {
    max-width: 100%;
}
.store-locator-details h1,
.store-locator-details p {
    margin: 0.25em 0;
}
.store-locator-details-map {
    float: right;
    width: 50%;
}
.store-locator-details-map img {
    max-width: 100%;
}
/* homepage elements */
/* ------------------------------------- */
/* Responsive Left Nav Styles */
/* -------------------------- */

#nav-checkbox {
    display: none;
}
#searchForm #searchFormMobile .leftNav-toggleButton {
    display: none;
}

#searchForm #searchFormMobile #contactUsButton {
    display: none;
}
#searchForm #searchFormMobile #miniCartButton {
    display: none;
}
/* /END Responsive Left Nav Styles */

.pt_storefront .mobile-menu {
    display: none;
}
#leftColumn {
    width: 49%;
    float: left;
}
#rightColumn {
    width: 49%;
    float: right;
}
#leftColumn .fullFeatherDivider {
    margin: 9px 0;
}
.contentWrapper {
    background: #676767;
    padding: 0px;
}
.centeredFeather {
    position: relative;
    top: -14px;
}
.promoTextArea {
    padding: 0px 0px 10px 10px;
    margin-top: -22px;
}
.promoTextHeadline {
    color: #DCDCDC;
    font-size: 24px;
    text-transform: uppercase;
}
.promoTextContent {
    color: #CBCBCB;
    font-size: 22px;
    margin-top: -15px;
}
.fullFeatherDivider {
    width: 100%;
}
#rightColumn .fullFeatherDivider {
    width: 100%;
    padding: 10px 0px;
}
#fullFeatherDividerSpacing {
    padding: 10px 0px;
}
#cat-landing-middle1 {
    padding: 15px 0px;
}
#cat-landing-middle2 {
    padding: 15px 0px;
}
.fullFeatherDividerBig {
    width: 100%;
    margin: 23px 0px;
}
.halfFeatherDivider {
    width: 50%;
}
#recommendationsArrowContainer {
    position: relative;
    top: 0;
    left: 480px;
    width: 50%;
    text-align: center;
}
#recommendationsArrows {
    margin: 0 auto;
    min-width: 300px;
    max-width: 510px;
    position: relative;
    left: 0
}
#recommendationsArrows a.heroPrev,
a.heroNext {
    position: absolute;
    width: 55px;
    height: 82px;
    display: block;
    text-indent: -9999px
}
#recommendationsArrows a.heroPrev {
    top: -155px;
    left: 0;
    background: url('../images/LeftFeather.png') no-repeat -1px 0;
}
#recommendationsArrows a.heroNext {
    top: -155px;
    right: -40px;
    background: url('../images/RightFeather.png') no-repeat -8px 0;
}
a.heroPrev,
a.heroNext {
    position: absolute;
    width: 55px;
    height: 82px;
    display: block;
    text-indent: -9999px;
    top: 50%;
}
a.heroPrev {
    left: 11.5%;
    background: url('../images/LeftFeather.png') no-repeat 1px 0;
}
a.heroNext {
    right: 11.5%;
    background: url('../images/RightFeather.png') no-repeat 4px 0;
}
#homepage-slides {
    padding: 0;
    width: 100%;
}
#homepage-slider-container {
    position: relative;
}
#homepage-slider {
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: none;
}
#homepage-slider > div {
    float: left;
    position: relative;
}
#homepage-slider div.slide-mobile {
    display: none;
}
#homepage-slider div.slide-text-white {
    color: #fff
}
#homepage-slider div.slide-text-white p {
    text-shadow: 0px 0px 15px rgba(0, 9, 0, 1);
}
#homepage-slider div.slide-text-gray {
    color: #646464;
}
#homepage-slider p.slide-text-header {
    font-size: 5em;
}
#homepage-slider p.slide-text-large {
    font-size: 3.333em;
    padding: 10px 30px;
}
#homepage-slider p.slide-text-medium {
    font-size: 2em;
    padding: 10px 35px;
}
#homepage-slider p.slide-text-small {
    font-size: 1.333em;
    padding: 10px 35px;
}
#homepage-slider a.button-base {
    font-size: 1.65em;
    font-weight: bold;
    padding: 10px 20px;
    text-decoration: none;
    margin: 22px 30px 0 30px;
    display: block;
    float: left;
}
#homepage-slider a.button-gray {
    color: rgba(100, 100, 100, 0.6);
}
#homepage-slider a.button-white {
    color: rgba(255, 255, 255, 0.6);
}
#homepage-slider div.slide-text {
    position: absolute;
}
#homepage-slider div.slide-align-left div.slide-text,
#homepage-slider > div div.slide-align-left div.slide-text-white,
#homepage-slider > div div.slide-align-left div.slide-text-gray {
    position: absolute;
    left: 15%;
    top: 13%;
    height: 87%;
}
#homepage-slider div.slide-align-right div.slide-text,
#homepage-slider > div div.slide-align-right div.slide-text-white,
#homepage-slider > div div.slide-align-right div.slide-text-gray {
    position: absolute;
    right: 15%;
    top: 13%;
    height: 87%;
}
#homepage-slider div.slide-align-center div.slide-text,
#homepage-slider > div div.slide-align-center div.slide-text-white,
#homepage-slider > div div.slide-align-center div.slide-text-gray {
    position: absolute;
    left: 32%;
    top: 30%;
    height: 70%;
}
#homepage-slider div.slide-align-right a.button-base {
    float: right;
}
#homepage-slider div.slide-align-center a.button-base {
    margin-top: 10px;
    float: right;
}
#homepage-slider div div.slide-align-center p.slide-text-large {
    padding: 5px 30px;
}
#homepage-slider div.slide-align-center p.slide-text-medium {
    padding: 10px 30px;
}
#homepage-slider .slide {
    list-style: none outside none;
    margin: 0;
    max-width: 100% !important;
    padding: 0;
    width: 100%;
}
#homepage-slider .slide img {
    height: auto;
    max-width: 100% !important;
}
#homepage-slider .slide a h2 {
    border: 0 none;
    clip: rect(0pt, 0pt, 0pt, 0pt);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
#homepage-slider .slide a span {
    position: absolute;
    text-indent: -999em;
}
.underneath-slider-slot {
    margin-top: -10px;
    position: relative;
}
.mobile .underneath-slider-slot {
    display: none;
}
.home-middle-left {
    float: left;
}
#home-promo-shop-shoes {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: url("../images/homepage/home-promo-shoes.png");
    background-origin: padding-box;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 413px;
    margin: auto;
    max-width: 320px;
    position: relative;
    width: 100%;
}
#home-promo-shop-shoes h2 {
    position: absolute;
    text-indent: -999em;
}
#home-promo-shop-shoes ul {
    padding: 30% 0 0;
    text-align: center;
}
#home-promo-shop-shoes li {
    border-right: 1px solid #3EBAEA;
    display: inline;
    list-style: none outside none;
    margin: 0 5px 0 0;
    padding: 0 5px 0 0;
    text-transform: uppercase;
}
#home-promo-shop-shoes li:last-child {
    border: 0 none;
}
#home-promo-shop-shoes li a {
    color: #3EBAEA;
}
#home-promo-shop-shoes span {
    font-style: italic;
    margin: 0 10px 0 0;
}
#home-promo-free-shipping {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: url("../images/homepage/home-promo-free-shipping.png");
    background-origin: padding-box;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 413px;
    margin: auto;
    max-width: 319px;
    text-indent: -999em;
    width: 100%;
}
.home-middle-slots {
    padding-top: 10px;
}
.home-middle-right {
    clear: both;
    margin: 0;
    width: auto;
}
.home-middle-right h2 {
    background: url("../images/homepage/hdr-this-weeks-deals.png") no-repeat scroll center center transparent;
    border-bottom: 2px solid #01A4E5;
    height: 54px;
    padding: 30px 0;
    text-indent: -999em;
}
.home-middle-right .vertical-carousel {
    margin: 0;
    padding: 0;
}
.home-middle-right .vertical-carousel li {
    float: left;
    list-style: none outside none;
    padding: 0;
    width: 25%;
}
.home-middle-right .vertical-carousel li .capture-product-id {
    display: none;
}
.home-middle-right .vertical-carousel li .product-price .product-standard-price {
    text-decoration: line-through;
}
.home-middle-right .product-tile {
    border: 0 none;
    margin: auto;
    min-height: 255px;
    width: 90%;
}
.home-middle-right .product-tile img {
    max-width: 90%;
}
.home-middle {
    clear: both;
    overflow: hidden;
    padding: 2em 0;
    margin-top: -20px;
}
.home-bottom {
    padding-bottom: 30px;
    padding-top: 5px;
}
.home-bottom-leftTop {
    padding-bottom: 15px;
}
.home-bottom-leftBottom {
    padding-top: 13px;
    padding-bottom: 5px;
}
.home-bottom-rightBottom {
    padding: 5px 0px;
}
#email-alert-signup {
    display: block;
    margin: 0 0 0 33px;
    width: 910px;
}
#email-alert-signup label {
    color: #8F8F8F;
    float: left;
    padding: 0.5em 0 !important;
    text-transform: uppercase;
    width: 50%;
}
#email-alert-signup input[type="text"] {
    float: left;
    padding: 0.7em 0.5em;
    width: 30%;
}
#email-alert-signup input[type="image"] {
    float: left;
    padding: 0.1em;
}
.mobileMenu-Homepage {
    display: none;
}
.sale-link-container {
    width: 100%;
}
.mobile-link {
    display: none;
}
/* JavaScript specific styles */

.js .loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.js .loader-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.2;
}
.js .loader-indicator {
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 64px;
    height: 64px;
    background: url("../images/loading-small.gif") no-repeat;
}
.js #quickviewbutton {
    background: transparent url(../images/quickview-button.png) center center no-repeat;
    display: none;
    position: absolute;
    top: 92%;
    right: 0;
    height: 17px;
    padding: 10px 0;
    width: 88px;
    height: 17px;
    z-index: 8;
}
.js .ie7 #quickviewbutton {
    position: absolute;
    left: 0;
    width: 200px;
}

@media screen and (min-width: @desktop-min) {
    .js .product-image:hover #quickviewbutton {
        display: block;
    }    
}

/* SEARCH SUGGESTION
--------------------------------------------------------------------------------------- */

.js #suggestions {
    background-color: @white;
    color: @gray;
    cursor: pointer;
    padding: 0;
    display: none;
    position: absolute;
    z-index: 8184;
    top: 50px !important;
}
.js #suggestions div {
    clear: both;
    height: 23px;
    padding: 5px;
    overflow: hidden;
    white-space: nowrap;
}
.js #suggestions div:hover {
    background-color: @white;
    color: @gray;
}
.js #suggestions div div.suggestionterm {
    float: left;
    overflow: hidden;
    width: 110px;
}
.js #suggestions div span.hits {
    line-height: 1.8em;
    text-align: right;
    margin-left: 3px;
}
.js .mini-cart-product {
    position: relative;
}
.js .mini-cart-toggler {
    background: url(../images/minicart_expanded.png) repeat scroll 0 0 transparent;
    cursor: pointer;
    height: 13px;
    left: 5px;
    position: absolute;
    top: 10px;
    width: 13px;
}
.js div.collapsed .mini-cart-toggler {
    background: url(../images/minicart_collapsed.png) repeat scroll 0 0 transparent;
}
/* quick view */
/* --------------------------------------------------------------------------------------- */

.js .ui-dialog.bonus-product-add-to-cart {
    padding: 0 15px;
}
.js .ui-dialog.bonus-product-add-to-cart .ui-dialog-titlebar-close {
    right: -15px;
}
.js .ui-dialog.bonus-product-add-to-cart .ui-dialog-title {
    display: none;
}
.js .bonus-product-add-to-cart .pdp-main .promotion {
    color: @red;
}
.js .ui-dialog-content a {
    color: @blue;
}
.js .ui-dialog-content .pdp-main .pdp-top {
    width: auto;
    padding: 0;
    margin-left: 0;
    margin-top: 0;
    clear: both;
}
.js .ui-dialog-content .product-col-1 {
    padding: 13px 1%;
    width: 39%;

    @media (max-width: @mobile-max) {
        width: 100%;
    }
}
.js .ui-dialog-content .product-col-1 .product-image {
    max-width: 100%;
}
.js .ui-dialog-content .product-col-2 {
    margin-bottom: 0;
    padding: 13px 0 0;
    width: 58%;

    @media (max-width: @mobile-max) {
        width: 100%;
    }
}
.js .bonus-product-add-to-cart .ui-dialog-content .product-col-1 {
    padding: 0;
    margin: 0;
    width: 43%;
}
.js .bonus-product-add-to-cart .ui-dialog-content .product-col-2 {
    padding: 0;
    width: 55%;
    float: right;
}
.js .bonus-product-add-to-cart .pdp-main .pdp-main .attribute .label {
    font-size: 12px;
}
.js .bonus-product-add-to-cart .pdp-main .product-add-to-cart .inventory label {
    font-size: 12px;
}
.js .ui-dialog.bonus-product-add-to-cart .pdp-main .attribute .pdp-dimensions-label {
    padding: 0;
    font-size: 12px;
}
.js .ui-dialog.bonus-product-add-to-cart .pdp-main .product-number {
    color: #fff;
    margin-top: 10px;
    font-size: 10px;
}
.js .bonus-product-add-to-cart .pdp-main .product-add-to-cart {
    background: none;
}
.js .bonus-product-add-to-cart .pdp-main .product-add-to-cart button {
    background: transparent url('../images/button-yellow.png') no-repeat center;
}
.js .bonus-product-add-to-cart .pdp-main .product-add-to-cart button[disabled] {
    color: #bfbfbf;
    background-image: url('../images/button-gray.png');
}
.js .bonus-product-add-to-cart .pdp-main .bonus-product-list-footer {
    color: @blue;
    margin-top: 25px;
}
.js .bonus-product-add-to-cart .pdp-main .bonus-product-summary {
    color: #fff;
}
.js .bonus-product-add-to-cart .pdp-main .bonus-product-summary a.remove-link {
    display: inline-block;
    width: 7px;
    height: 8px;
    background-color: #afafaf;
    border: 1px solid #a1a1a1;
    padding: 1px 0 0 1px;
    margin: 2px 0 0 0;
    color: #000;
    line-height: 8px;
    top: 0;
    vertical-align: top;
}
.js .bonus-product-add-to-cart .pdp-main .bonus-product-summary li .item-name {
    display: block;
}
.js .bonus-product-add-to-cart .pdp-main .bonus-product-summary li.selected-bonus-item {
    clear: left;
    margin-top: 5px;
}
.js .bonus-product-add-to-cart .pdp-main .bonus-product-summary li.selected-bonus-item .selected-item-line {
    display: inline-block;
}
.js .bonus-product-add-to-cart .pdp-main .bonus-product-summary li ul.item-attributes li {
    display: block;
}
.js .ui-dialog-content .pdp-main h1.product-name {
    margin: 0em 0 0.1em;
    line-height: 28px;
}
.js .ui-dialog-content .pdp-main .product-desc {
    font-size: 12px;
    padding: 10px 0 25px 0;
    background: url("../images/pdp-white-divider.png") no-repeat center bottom;
}
.js .ui-dialog-content .pdp-main .product-set-list .product-set-item {
    margin: 1em 0;
}
.js .ui-dialog-content .pdp-main .product-set-list .product-set-image {
    width: 25%;
}
.js .ui-dialog-content .pdp-main .product-set-list .product-name {
    margin: 0;
}
.js .ui-dialog-content .pdp-main .product-set-list .block-add-to-cart .price {
    font-size: 1.5em;
    padding: 0.4em 0 0;
    width: 130px;
}
.js .zoomPad {
    position: relative;
    float: left;
    z-index: 99;
    cursor: crosshair;
    width: 100%;
}
.js .zoomPad img {
    outline: 1px solid @blue;
}
.js .zoomPreload {
    -moz-opacity: 0.8;
    opacity: 0.8;
    color: #333;
    font-size: 12px;
    font-family: Tahoma;
    text-decoration: none;
    border: 1px solid #CCC;
    background-color: #FFF;
    padding: 8px;
    text-align: center;
    background-image: url(../images/zoomloader.gif);
    background-repeat: no-repeat;
    background-position: 43px 30px;
    z-index: 110;
    width: 90px;
    height: 43px;
    position: absolute;
    top: 0px;
    left: 0px;
    *width: 100px;
    *height: 49px;
}
.js .zoomPup {
    overflow: hidden;
    background-color: #FFF;
    -moz-opacity: 0.6;
    opacity: 0.6;
    z-index: 120;
    position: absolute;
    border: 1px solid #CCC;
    z-index: 101;
    cursor: crosshair;
}
.js .zoomOverlay {
    position: absolute;
    left: 0px;
    top: 0px;
    background: #FFF;
    /*opacity:0.5;*/

    z-index: 5000;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 101;
}
.js .zoomWindow {
    position: absolute;
    left: 100% !important;
    top: 40px !important;
    background: #FFF;
    z-index: 6000;
    height: auto;
}
.js .zoomWrapper {
    position: relative;
    border: 1px solid #999;
    z-index: 110;
}
.js .zoomWrapperTitle {
    display: block;
    background: #999;
    color: #FFF;
    height: 18px;
    line-height: 18px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 120;
    -moz-opacity: 0.6;
    opacity: 0.6;
}
.js .zoomWrapperImage {
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 110;
}
.js .zoomWrapperImage img {
    border: 0px;
    display: block;
    max-width: unset;
    position: absolute;
    z-index: 101;
}
.js .zoomIframe {
    z-index: -1;
    filter: alpha(opacity=0);
    -moz-opacity: 0.80;
    opacity: 0.80;
    position: absolute;
    display: block;
}
/* homepage assets
--------------------------------------------------------------------------------------- */

.js .homepage-slides {
    height: 550px;
}
.js #homepage-slider .jcarousel-item {
    max-width: 100%;
}
.js #homepage-slider .jcarousel-control {
    position: absolute;
    right: 10px;
    bottom: 20px;
}
.js #homepage-slider .jcarousel-control a {
    background: none repeat scroll 0 0 #E0E0E0;
    border: 1px solid #cccccc;
    display: block;
    float: left;
    height: 15px;
    margin: 0 3px;
    text-indent: -999em;
    width: 6px;
}
.js #homepage-slider .jcarousel-control a.active {
    background-color: @white;
}
.js .home-middle-left,
.js .home-middle-center,
.js .home-middle-right {
    clear: none;
    float: left;
    width: 32.4%;
    padding: 4px;
}
.js .home-middle-left {
    padding-left: 0px;
}
.js .home-middle-center {
    padding-left: 9px;
    padding-right: 9px;
}
.js .home-middle-right {
    padding-right: 0px;
}
.js .home-middle-right .jcarousel-clip {
    height: 282px;
    overflow: hidden;
}
.js .home-middle-right .vertical-carousel li {
    height: 282px;
    float: none;
    width: 300px;
}
.js .home-middle-right .product-tile {
    min-height: 0;
}
.js .home-middle-right .jcarousel-prev {
    background: url('../images/interface/icon-vertical-carousel-prev.png') left top no-repeat;
    cursor: pointer;
    height: 12px;
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 52px;
    z-index: 999;
}
.js .home-middle-right .jcarousel-next {
    background: url('../images/interface/icon-vertical-carousel-next.png') left top no-repeat;
    cursor: pointer;
    height: 12px;
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 52px;
    z-index: 999;
}
.js .home-middle-right .jcarousel-prev-disabled,
.home-middle-right .jcarousel-next-disabled {
    display: none !important;
}
.js button.select {
    display: none
}
.select-address button.select {
    display: none
}
/* Multi Inventory */
/* --------------------------------------------------------------------------------------- */

.ui-dialog #listings-nav {
    float: right;
    margin-top: 2%;
}
.ui-dialog #listings-nav a {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
}
.ui-dialog #listings-nav a.active {
    font-weight: bold;
}
.pdp-main .product-add-to-cart .availability .label {
    position: relative;
}
.pdp-main .product-add-to-cart .online-stock span {
    float: left;
}
.pdp-main .product-add-to-cart .store-stock {
    display: block;
    clear: left;
}
.update-location {
    color: #22B0E8;
    padding-left: 10px;
    cursor: pointer;
}
div.store-list {
    border-color: #CCCCCC;
    border-style: dashed;
    border-width: 1px 0;
    height: 150px;
    overflow: hidden;
    padding: 0;
    position: relative;
}
div.store-list ul.store-list {
    position: absolute;
    width: 1920px;
}
ul.store-list {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
ul.store-list .selected {
    background-color: #DDF0DF;
}
.store-tile {
    border-left: 1px dashed #CCCCCC;
    border-right: 1px dashed #CCCCCC;
    float: left;
    height: 134px;
    list-style: none outside none;
    margin: 0 2px;
    padding: 8px;
    position: relative;
    width: 154px;
}
.store-tile .store-tile-address {
    display: block;
}
.store-tile .store-tile-city {
    margin: 0 5px 0 0;
}
.store-tile .store-tile-state {
    margin: 0 5px 0 0;
}
.store-tile .store-tile-postalCode {
    display: block;
}
.store-tile .store-tile-status {
    display: block;
    font-size: 0.9em;
    margin: 5px 0;
}
.not-available .store-tile-status,
.out-of-stock .store-tile-status {
    color: @red;
}
.In-Stock .store-tile-status {
    color: #019328;
}
.store-tile .store-tile-map-this {
    display: block;
    font-size: 0.9em;
    margin: 5px 0;
}
.store-tile .select-store-button {
    bottom: 5px;
    left: 6px;
    position: absolute;
}
.store-stock ul.expanded {
    height: auto;
}
.store-stock ul.store-list {
    margin: 20px 0 5px;
}
.store-stock ul.store-list .extended-list {
    display: none;
}
.store-stock ul.expanded .extended-list {
    display: block;
}
.store-stock ul.store-list .selected {
    background-color: #FFFFFF;
    font-weight: bold;
}
.store-stock .store-tile {
    border-color: #CEBDB3;
    border-style: dotted;
    border-width: 1px 0 0;
    clear: both;
    float: none;
    font-size: 0.9em;
    height: auto;
    line-height: 1.5em;
    overflow: hidden;
    padding: 5px 0 5px 55%;
    text-align: right;
    width: auto;
}
.store-stock .store-tile .store-tile-address {
    color: #0167AF;
    display: inline-block;
    margin: 0 0px 0 0;
}
.store-stock .store-tile .store-tile-city {
    color: #0167AF;
    margin: 0 3px 0 0;
}
.store-stock .store-tile .store-tile-state {
    color: #0167AF;
}
.store-stock .store-tile .store-tile-postalCode {
    display: none;
}
.store-stock .store-tile .store-tile-status {
    left: 0;
    margin: 0;
    position: absolute;
    text-align: left;
    top: 3px;
    width: 80px;
}
.store-stock .In-Stock .store-tile-status {
    left: 13px;
}
.store-stock .store-tile .store-tile-map-this {
    display: none;
}
.store-stock .store-tile .select-store-button {
    display: none;
}
.more-stores {
    color: #0167AF;
    cursor: pointer;
    font-size: 0.8em;
    margin: 0 0 0 2px;
    padding: 0 0 0 12px;
}
.multishipprogress .step-4 {
    background: none repeat scroll 0 0 transparent;
    padding-right: 0;
}
.multishipaddressestable {
    margin: 9px 0px 20px 0px;
    width: 706px;
}
.multi-ship-shipments-table {
    margin: 0 0 20px;
    width: 706px;
}
.multi-ship-shipments-table th.section-header {
    font-size: 10px;
    font-weight: bold;
    padding: 9px 6px 11px;
}
.multi-ship-shipments-table .shipping-address .details {
    padding: 0 10px 0 22px;
}
.multi-ship-shipments-table .gift-options label {
    float: left;
    padding: 0;
}
.multi-ship-shipments-table .gift-options input {
    margin: 0 10px;
}
.multi-shipping-addresses {
    margin-bottom: 20px !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
}
.multi-shipping-addresses .shipping-address {
    text-align: right;
}
.multi-shipping-addresses .shipping-address select {
    margin: 4px 0 0;
    width: 260px;
}
.header-product-shipping {
    width: 276px;
}
.header-qty-shipping {
    width: 45px;
}
.header-location-shipping {
    text-indent: 70px;
    width: 307px;
}
.header-shipment-index {
    width: 409px;
}
.header-shipment-qty {
    width: 27px;
}
.header-shipment-details {
    text-indent: 22px;
}
.multi-shipping-methods {
    position: absolute;
    top: 30px;
}
.multi-shipping-methods .shipping-methods .form-row {
    clear: none;
    float: left;
    display: none;
}
.multi-shipping-methods .shipping-methods select {
    width: 250px;
    margin: -5px 0 0 15px;
}
.multi-shipping-option {
    border-bottom: 2px dotted #CEBDB3;
    left: 0;
    padding: 0 0 12px;
    position: absolute;
    text-indent: 10px;
    top: 48px;
    width: 95%;
}
.multi-shipping-addresses .shipping-qty,
.multi-ship-shipments-table .shipping-qty {
    background: none repeat scroll 0 0 #E9E9E9;
}
.multiship span.label {
    font-weight: bold;
}
.hide {
    display: none;
}
.loading {
    background: url("../images/loading-progress-98x22.gif") no-repeat scroll left center transparent;
}
.store-in-stock {
    color: #019328;
}
#cart-table .item-delivery-options .store-in-stock {
    color: #333333;
    font-family: Arial, sans-serif;
    font-size: 12px;
}
.store-error {
    color: @error;
}
#cart-table .item-delivery-options .store-error {
    color: @error;
}
#cart-table .item-delivery-options {
    padding: 1em 0 1em 0;
    width: 100px;
}
.selected-store-address,
.set-preferred-store,
.selected-store-availability {
    font-size: 0.8em;
    font-weight: 400;
    margin-left: 0px;
}
.set-preferred-store {
    cursor: pointer;
}
#cart-table .form-row .radio-url {
    display: inline;
}
.label-inline input {
    float: left;
}
.instore-shipping-option {
    width: 100%;
}
.pt_checkout div.instore-shipment-header {
    width: 100%;
}
.pt_checkout div.instore-shipment-method {
    width: 100%;
    padding: 1em;
}
.pt_checkout div.instore-shipment-method span {
    font-weight: bold;
}
.pt_checkout div.instore-shipment-address {
    width: 40%;
    float: left;
    padding-left: 1em;
}
.pt_checkout div.instore-shipment-messagetxt {
    width: 50%;
    float: right;
}
.ui-dialog #preferred-store-panel button#set-user-zip {
    margin-left: 3%;
}
.ui-dialog #preferred-store-panel #userZip {
    margin-left: 12%;
    margin-top: 5%;
}
.ui-dialog #preferred-store-panel .error-message {
    margin-left: 12%;
    margin-top: 1%;
}
#preferred-store-panel div.store-list {
    margin-top: 1%;
}
#preferred-store-panel button.set-preferred-store {
    margin-top: 2%;
}
#preferred-store-panel button.close {
    margin-top: 2%;
}
.checkout-shipping fieldset .instore-continue-button {
    margin-left: 61.5%
}
#cart-table .item-delivery-options label {
    display: inline;
    margin-left: 2%;
}
/* Search Suggest Beta */
/* --------------------------------------------------------------------------------------- */

#search-suggestions {
    background-color: #FFFFFF;
    border: 1px solid #88c14b;
    top: 34px;
    right: 0px;
    position: absolute;
    z-index: 8184;
}
.search-suggestion-wrapper {
    width: 300px;
}
.search-suggestion-wrapper-full {
    width: 621px;
}
.search-suggestion-contents {
    position: relative;
}
.search-suggestion-left-panel,
.search-suggestion-right-panel {
    width: 300px;
    float: left;
}
.search-suggestion-right-panel {
    border-left: solid 1px #88c14b;
    margin-left: -1px;
}
.search-suggestion-left-panel {
    border-right: solid 1px #88c14b;
}
.search-suggestion-left-panel-hitgroup-header {
    height: auto;
    font-weight: bold;
    margin: 0 0 8px 0;
}
.search-suggestion-left-panel-hitgroup {
    color: #555555;
    margin: 10px 0 10px 10px;
}
.search-suggestion-left-panel-hitgroup-link {
    margin-left: 1em;
}
.search-suggestion-normal-link {} .search-suggestion-left-panel-hit {
    margin-left: 1em;
}
.search-suggestion-normal-link:hover,
.search-suggestion-left-panel-hitgroup-link:hover,
.search-suggestion-left-panel-hit:hover {
    text-decoration: underline;
}
.search-suggestion-right-panel-product {
    height: 75px;
    margin-top: 1em;
    margin-left: 1em;
}
.search-suggestion-right-panel-product-last {
    height: 75px;
    margin: 1em;
}
.search-suggestion-right-panel-product-hover {
    background-color: red;
}
.search-suggestion-right-panel-product-image {
    display: inline;
    width: 25%;
    display: inline-block;
    margin-left: 1px;
}
.search-suggestion-right-panel-product-image img {
    width: 50px;
    height: 50px;
}
.search-suggestion-right-panel-product-details {
    color: #333333;
    height: auto;
    display: inline;
    width: 70%;
    float: right;
    text-align: right;
}
.search-suggestion-right-panel-phrasewrapper {} .search-phrase-suggestion {
    margin-top: 1em;
    margin-left: 1em;
}
.search-suggestion-right-panel .whole-phrase {
    font-style: italic;
}
.search-suggestion-right-panel .search-suggest-guess {
    text-decoration: underline;
    font-weight: bold;
}
.search-suggestion-right-panel .first-term-italic {
    font-style: italic;
}
.search-suggestion-right-panel .first-term-bold {
    font-weight: bold;
}
.search-suggestion-left-panel-parent-category {
    color: #999999
}
/* EMAIL SIGNUP HOPUP */

.emailsignup-dialog {
    margin: 0;
    padding: 0 !important;
}
.emailsignup-dialog .ui-dialog-titlebar {
    background-color: #002d62;
    cursor: auto;
}
.emailsignup-dialog .dialog-content {
    padding: 0 30px 35px 30px;
    color: #FFF;
    background-color: #002d62;
    text-align: left;
}
.emailsignup-dialog .emailsignup-confirmation {
    display: none;
}
.emailsignup-dialog p.head {
    padding-bottom: 25px;
    font-family: 'proxima_nova_rgbold';
    font-size: 36px;
    text-transform: uppercase;
    margin: 0;
    line-height: 36px;
}
.emailsignup-dialog p.body {
    padding-bottom: 15px;
    font-family: 'proxima_nova_rgregular';
    font-size: 21px;
    margin: 0;
}
.emailsignup-dialog .ui-dialog-titlebar-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    background-color: transparent;
    text-align: center;
    line-height: 30px;
    margin: 0;
}
.emailsignup-dialog .ui-dialog-titlebar-close span {
    background-image: none;
    color: #002d62;
    text-indent: 0;
    font-size: 35px;
    margin: 0;
    height: auto;
    width: auto;
    line-height: 1;
    top: 0;
    left: 0
}
.emailsignup-dialog span.ui-icon-closethick {
    background-image: url('../images/interface/btn-close-white.png') !important;
}
.emailsignup-form {
    padding: 10px;
}
.emailsignup-form-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 36px;
}
.emailsignup-form button,
a.buttonstyle {
    cursor: pointer;
    display: inline;
    float: right;
    padding: 10px 10px;
}
.emailsignup-form .button,
a.buttonstyle {
    background: none repeat scroll 0 0 #FFFFFF;
    border-color: #CCCCCC #CCCCCC #D0D0D0;
    border-style: solid;
    border-width: 1px 1px 4px;
    color: #3EBAEA;
    cursor: pointer;
    display: inline;
    font-size: 1.1em;
    font-weight: 700;
    padding: 0.6em 2em 0.5em;
    text-align: center;
}
.emailsignup-form a {
    -webkit-border-radius: 1.2em;
    -moz-border-radius: 1.2em;
    border-radius: 1.2em;
    display: inline-block;
    font-family: Din, Arial, sans-serif;
    text-transform: uppercase;
    border: 1px solid white;
    color: #FFF;
    padding: 0.5em;
    padding-bottom: 0.2em;
    font-weight: bold;
    font-size: 0.875em;
    white-space: nowrap;
}
.emailsignup-form a:hover {
    text-decoration: none;
    border: 2px solid white;
    margin-left: 0;
    margin-top: -1px;
}
.emailsignup-form input[type="email"] {
    width: 50%;
    padding: 1%;
    margin-bottom: 10px;
}
.emailsignup-form input[type="email"] {
    width: 50%;
    padding: 1%;
    margin-bottom: 10px;
}
.emailsignup-form input[type="email"] {
    width: 50%;
    padding: 1%;
    margin-bottom: 10px;
}
.emailsignup-form label,
.form-label-text {
    display: block;
    float: left;
    padding: 0.75em 0 0;
    text-align: right;
    width: 30%;
    padding-right: 2%;
}
.emailsignup-form .emailsignup-error {
    display: none;
    margin: 10px 0 0 0;
    color: #E61938;
}
.emailsignup-form .formHeader {
    font-size: 28px;
    text-transform: uppercase;
    margin: 0px;
    padding-bottom: 10px;
}
.emailsignup-form .topDescription {
    padding: 2% 0%;
    font-size: 14px;
}
.emailsignup-form .bottomDescription {
    padding: 2% 0%;
    font-size: 12px;
}
.emailsignup-form .dialog-required {
    font-size: 10px;
    padding: 10px 0;
}
.emailsignup-form .dialog-required em {
    color: @red;
}
.emailsignup-dialog .emailsignuperror {
    float: left;
    width: 65%;
    color: @error;
    /*display: none;*/

    padding-left: 20px;
    font-size: 0.875em;
    line-height: 30px;
}
.emailsignup-dialog label {
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    font-weight: normal;
    display: block;
    padding-bottom: 5px;
}
.emailsignup-dialog label.error {
    float: left;
    background-color: transparent;
    line-height: 30px;
    font-family: 'proxima_nova_ltsemibold';
    padding: 0;
}
.emailsignup-dialog input.text-error {
    border: 1px solid #DB172D;
    background-color: #fcc;
}
.emailsignup-dialog .emailsignup-loading {
    display: none;
    padding-top: 10px;
}
#emailSignUp-header {
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 10px;
}
/* 404 Page */

.pt_error .error-page-search button {
    display: inline-block;
    float: left;
    margin-top: 1px;
}
.pt_error .input-text.search {
    float: left;
}
.pt_error .error-page-footer {
    float: left;
    margin-top: 10px;
}
/* Multi-currency */
/* --------------------------------------------------------------------------------------- */

.menu-utility-user .mc-class .currency-converter {
    height: 12px;
    width: 100%;
    padding: 0;
    border: none;
}
/* Back to top button */

.back-to-top.container {
    display: none;
    width: 105px;
    height: 105px;
    position: fixed;
    top: 300px;
    right: 10px;
    z-index: 9999;
}
.back-to-top.container#top {
    width: 100%;
    height: 100%;
}
/* Customer Service Pages */

.pt_customer-service h1.visually-hidden {
    display: none;
}
.pt_customer-service h1 {
    font-size: 24px;
    border-bottom: 1px solid #afafaf;
    padding-block: 0.3em;
}
.pt_customer-service h1:not(:first-child) {
    margin-block-start: .5em;
}
.pt_customer-service .customer-service-section > h1 {
    font-size: 20px;
    border-bottom: none;
}
#background-cs {
    width: 1302px;
    margin: 0 auto;
    background-repeat: no-repeat;
}
.pt_customer-service .page-content {
    background-size: 100% 100%;
    width: 100%;
    padding-right: 10%;
    position: relative;
    z-index: 0;
}
.pt_customer-service .dialog-required.contactus {
    width: 50%;
    box-sizing: border-box;
    text-align: right;
    margin-right: 0;
    margin-bottom: 20px;
}
.pt_customer-service #ContactUsForm.contactus {
    width: 100%;
    float: left;

    .form-row.form-row-button {
        display: flex;
    }
}

.pt_customer-service ul.left-navigation {
    padding: 0;
    list-style: none;
}
.pt_customer-service .left-navigation li {
    margin-bottom: 27px;
}
.pt_customer-service .customer-service-section .customer-service-list {
    width: 428px;
    list-style: none;
    padding-left: 0;
}
.pt_customer-service .customer-service-section .customer-service-list .description {
    height: 81px;
    margin-left: 2px;
}
.pt_customer-service .customer-service-list li a {
    font-size: 20px;
    text-transform: uppercase;
}
.pt_customer-service .customer-service-list li .description {
    font-size: 15px;
}
.pt_customer-service table {
    border-color: #fff;
}
.pt_customer-service table td {
    padding: .4em;
}
.pt_customer-service table th {
    padding: 0 2px 0 4px;
    width: 10px;
}
.pt_customer-service table.shipping-list {
    border-color: #fff;
}
.pt_customer-service table.shipping-list td {
    padding: .4em;
    height: auto;
}
.pt_customer-service table.shipping-list th {
    padding: 5px 0 0 4px;
    font-weight: bold;
    vertical-align: top;
    width: 60px;
}
.pt_customer-service .shipping-list-container {
    padding-right: 20px;
}
div.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.retrieve-cart-popup.ui-draggable {
    padding-top: 42px;
}
.retrieve-cart-popup .ui-dialog-titlebar {
    padding-left: 0;
}
.retrieve-cart-popup .ui-dialog-titlebar a.ui-dialog-titlebar-close.ui-corner-all {
    top: -37px;
}
.retrieve-cart-popup #dialog-container {
    border-top: 1px solid #afafaf;
    padding-top: 20px;
    margin-top: 10px;
}
/* Storefront Toolkit Override */

.x-panel {
    color: black;
}
.dw-sf-search-hit-explain-display-product-note {
    color: black;
    font-size: .8em;
}

.footerWrapper {
    width: 100%;
    background-color: var(--color-gentle-base);
    color: @black;
}

.selectboxit-container span {
    background-color: @white;
}

.selectboxit-text {
    color: @gray;
}

.selectboxit-default-icon.selectboxit-option-icon.selectboxit-container {
    border-top: 4px solid @gray;
}

.cart-order-totals .selectboxit-default-arrow {
    border-top: 4px solid @gray;
}

.view-details.button-other {
    color: @white !important;
}

#pcf_promo36text-3b {
    color: @blue;
}

.cart-action-retrieve-cart .button-base {
    float: right;
}

#dwfrm_login_register .form-row-button {
    text-align: center;
}

//listrak recommender
.ltkContainer {
    background-color: rgb(229, 227, 222);
    margin: 0 auto;
    padding: 30px 0 0;
    margin-bottom: 20px;
}

.ltkTitle {
    padding: 0px 30px;
    max-width: 1400px;
    margin: 0 auto;
    h2 {
        font-size: 2.5em;
        text-align: center;
        margin: 0;
    }
}

.pdp-main .ltkTitle {
    padding: 30px 30px 0;
}

.ltkRecommender {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 5px;

    img {
        width: 100%;
        height: 100%;
    }

    div {
        display: inline-block;
        margin: 10px;
    }

    a {
        display: block;
        text-decoration: none;
        padding-top: 8px;
        color: @blue;
        max-width: 200px;
        max-height: 200px;

        &:hover {
            color: @lightgray;
        }
    }
}

.pt_storefront .ltkRecommender {
    div {
        @media (min-width: @desktop-min) {
            margin: 25px;
        }
    }

    a {
        @media (min-width: @desktop-min) {
            max-width: 250px;
            max-height: 250px;
        }
    }
}

// Mobile slot for email sign up
.mobile.email-signup {
    background-color: @white;
    margin: 10px;
    max-width: 80px;
    line-height: 25px;
    padding: 10px;
    a {
        color: @gray;
        &:hover {
            color: @lightgray;
        }
    }
}


// Search results no hits
.pt_product-search-noresult {
    .breadcrumb {
        padding-left: 10px;
    }
    .page-content .primary-content {
        width: auto !important;
        margin: 0;
        padding: 0 10px !important;
        float: none;

        img {
            width: 100%;
        }
    }
}
.grecaptcha-badge {
    visibility: hidden;
}

.checkout-page.captcha-enabled .grecaptcha-badge {
    visibility: visible;
}
.grecaptcha-notice {
    color: #5f5f5f;
    background-color: #e8e8e8;
    width: 50%;
    margin: 50px auto 0;
    padding: 10px;
    font-size: 0.9em;
}
.pt_customer-service {
    .recaptcha-error {
        font-weight: bold;
        background-color: #d0d0d0;
        padding: 10px;
        margin: 20px 0;
        width: 80%;
    }
    .ot-sdk-show-settings {
        background-color: var(--color-cta);
        margin: 20px 0;
        border: 1px solid @white !important;
        color: @white !important;
        font-size: 1em !important;
        &:hover {
            background-color: var(--color-cta) !important;
            opacity: .9;
        }
    }
}

.item-quantity .inventory {
        input {
            width: 40px;
            padding: 0;
            border-top: 1px solid @blue;
            border-bottom: 1px solid @blue;
        }

        label {
            padding-right: 15px;
        }
}

form.contactus {
    
    label {
        width: 100%;

        @media (min-width: 1024px) {
            width: 26%;
        }

    }


    .form-row-button {
        margin-left: 27.5%;
    }

    .message {
        margin-bottom: 2em;
    }
}