/* Less Media Mixins */

.desktop(@rules) {
    @media screen and (min-width: 1024px) {
        @rules();
    }
}

.tablet(@rules) {
    @media screen and (min-width: 768px) {
        @rules();
    }
}