/*Modal Styles*/

.ui-dialog {
	top: 35px;
	left: 50% !important;
	transform: translateX(-50%);

	&.email-signup-dialog {
		padding: 20px;
		max-width: 385px;

		.ui-dialog-title {
			display: none;
		}

		.ui-dialog-titlebar-close {
			right: 10px;
			top: 9px;
			z-index: 100;
			height: 27px;
			width: 27px;

			.ui-icon-closethick {
				background: url("../images/close.png");
				height: 27px;
				width: 27px;
			}
		}

		.ui-widget-header {
			padding: 0;
			height: 0;
		}

		.dialog-content {
			padding: 0;
	
			@media (max-width: @tablet-max) {
				margin: 0;
			}
		}

		fieldset {
			min-inline-size: auto;
		}

		.emailsignup-form {
			padding: 20px;
			border: 1px solid @gray;
			text-align: center;

			#ltkpopup-subheadline {
				font-family: "Arrow";
				font-size: 25px;
				text-transform: uppercase;
				margin: 13px 0;
			}
	
			#ltkpopup-headline {
				font-family: "Centure Gothic";
				font-size: 40px;
				margin: 0;
			}

			#ltkpopup-content-para {
				padding: 20px;
			}

			.dialog-required {
				visibility: hidden;
				height: 0;
			}

			label {
				position: absolute;
				height: 0;
				width: 0;
				visibility: hidden;
			}

			span {
				&.error {
					width: 278px;
					max-width: 278px;
					text-align: left;
					margin: 5px auto;
				}
			}

			input[type="text"] {
				width: 265px;
				max-width: 265px;
				text-align: center;
				margin: 0;
				border: 1px solid @darkgray;
			}

			.select {
				margin: 0;
				padding: .7em .5em;
				width: 265px;
				text-align: center;
				color: @darkgray;
				text-align-last: center;
				border: 1px solid @darkgray;

				@media (min-width: @tablet-min) {
					width: 279px;
				}
			}

			button {
				padding: 10px;
				float: none;
				text-transform: capitalize;
				//LTK button overrides
				background: none;
				color: @darkgray;
	
				&::after {
					font-family: fontawesome;
					content: '\f0da';
				}
			}
		}

		.emailsignup-error {
			color: @error;
			padding-bottom: 20px;
			text-align: center;
		}

		.birthdate-error {
			color: @error;
			margin-top: -10px;
			padding-left: 186px;
			padding-bottom: 20px;
		}
	}
	&.quickview {
		position: fixed;
		top: 15%;
		z-index: 1002;
	
		.ui-dialog-titlebar {
			padding: 20px 20px 0!important;
		}
	
		@media (min-width: @desktop-min) {
			left: 50%;
			transform: translateX(-50%);
			max-height: 80vh;
			overflow-y: scroll;
		}
	}
}

@media screen and (max-width: @tablet-max) {
	.ui-dialog[style] {
		width: 85% !important;
		left: 7.5% !important;
		transform: none;
		box-sizing: border-box;

		&.email-signup-dialog {
			box-sizing: content-box;
			left: 50% !important;
			top: 50% !important;
			transform: translate(-50%, -50%);
		}
		&.quickview {
			.product-thumbnails ul {
				flex-wrap: wrap;
			}
		}
	}
}

@media (max-width: @mobile-max) {
	.dialog-active {
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}

	.ui-dialog[style] {
		position: fixed;
		width: 92% !important;
		left: 4% !important;
		top: 50%;
		transform: translate(0, -50%);
		max-height: 96%;
		margin-bottom: 20px;
		overflow: auto;
		padding: 0;

        label,
        select,
		input[type="text"],
		input[type="password"] {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            box-sizing: border-box;
        }
        label {
            margin-bottom: 10px;
        }
		input[type="text"],
		input[type="password"] {
			padding-left: 10px;
			padding-right: 10px;
		}
        .ui-dialog-content {
            margin: 15px 20px;
        }
        .dialog-required {
            margin-right: 0;
        }
        .ui-dialog-titlebar-close {
            right: 0;
        }
		.emailsignup-form {
			button {
				float: none;
				width: 100%;
				margin-top: 20px;
			}
		}
		&.checkout-address-edit {
			.form-row-button {
				margin-right: 0;
				width: 100%;
				.button-row-wrapper {
					width: 182px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		&.retrieve-cart-popup {
			.ui-dialog-titlebar {
				padding: 0 40px;
				span {
					font-size: 20px;
					text-align: center;
					padding-left: 25px;
    				padding-right: 10px;
				}
			}
			.content-asset {
				p {
					text-align: center;
				}
			}
		}
		&.address-create-dialog {
			#dialog-container {
				padding-top: 0;
				margin-top: 0;
			}
			.form-row-button {
				width: 100%;
				text-align: center;

				button {
					margin: 0 auto 30px auto;
				}
			}
		}
		&.quickview {
			.ui-dialog-content {
				padding: 0;
			}
			.pdpForm {
				padding-bottom: 16px;
			}
			.product-thumbnails ul {
				flex-wrap: nowrap;
			}
			.product-col-2 {
				margin: 0;
				padding: 0;
			}
			#product-content h1.product-name {
				padding-top: 0;
			}
		}
	}
}
