/*Footer Styles*/
#footer .container {
    padding: 5px;
}

@media (max-width: @tablet-max) {
    #footer {
        .container {
            padding: 5px 1.25%;
            flex-grow: 1;
        }

        overflow: hidden;
        .ul-footer {
            max-width: 135px;
            li {
                font-size: 12px;
                padding-bottom: 10px;
                overflow-wrap: break-word;

                a {
                    font-size: 12px;
                    display: block;
                    width: 100%;

                    &:hover {
                        font-size: 12px;
                    }
                }
            }
        }
        .header {
            font-size: 13px;
        }
        .footer-content {
            padding: 10px;
        }

        .social-footer-box {
            float: left;

            .ul-footer {
                li {
                    display: flex;
                    align-content: flex-start;
                    a {
                        display: inline;
                    }
                }
            }
            .container {
                width: 100%;
            }
            .container.facebook-like {
                width: 25.5%;
                position: absolute;
                margin-left: 32.5%;
                margin-top: -28px;

                iframe {
                    float: left;
                }
            }
        }
    }
    #cart-footer {
        padding: 10px 0 30px 0;
        img {
            padding: 20px 0;
        }
    }
}

@media (max-width: @mobile-max) {
    #footer {
        padding: 10px 0 40px 0;

        .mobile-container {
            margin-top: 20px;
        }
        img {
            padding: 20px 0;
        }
        .ul-footer-mobile > .container {
            float: none;
        }
    }
}

footer .footer-content {
    display: flex;
    justify-content: space-evenly;
    max-width: 1400px;
    margin: 0 auto;
}
