/* Homepage Styles */
.pt_storefront {

  #main {
    max-width: 100%;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }

  .html-slot-container h2 {
    color: var(--color-accent);
  }
}

#homepage-slider-container {
  .flex-direction-nav {
    .flex-next, .flex-prev {
      opacity: 1;
      font-size: 0;
      width: 60px;
    }

    .flex-prev {
      left: 13%;
      background: url('../images/LeftFeather.png') no-repeat 1px 0;
    }

    .flex-next {
      right: 12.5%;
      background: url('../images/RightFeather.png') no-repeat 4px 0;
    }
  }

  .banner {
    position: absolute;
    top: 46px;
    right: 16%;
  }

  .banner-heading {
    font-family: "Arrow Bold";
    font-size: 66px;
    text-transform: uppercase;
  }

  .banner-title {
    font-family: "Arrow Bold";
    font-size: 40px;
    white-space: nowrap;
    line-height: 40px;
    padding-left: 90px;
  }

  .banner-callout {
    font-family: "Centure Gothic";
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    white-space: nowrap;
    line-height: 14px;
    padding-top: 8px;
    padding-left: 90px;
  }

  #textOverlayWrapper {
    img {
      width: 100%;
    }
  }
}

.homepage-static-hero {
  a,
  h1,
  p {
    display: block;
    text-decoration: none;
  }
}

.homepage-static-hero,
.home-page-bottom-asset {
  font-family: 'Gotham Book', Arial, sans-serif;
  font-weight: normal;

  h1,
  h2,
  p {
    margin: 0;
    font-weight: normal;
  }

  h2 {
    font-size: 34px;
    line-height: 1em;
    margin: 5px 0;
  }

  p {
    font-size: 18px;
    line-height: 1em;
  }

  a {
    text-decoration: none;
  }

  span {
    float: none;
    font-size: inherit;
  }

  .black {
    color: #000;
  }

  .italic {
    font-style: italic;
  }

  .yellow-text {
    color: @red;
  }

  .script-heading {
    font-family: 'KG Chelsea Market Script';
  }

  .text-container {
    margin: 20px;
    position: absolute;
  }

  .center-text,
  .lefttop-text,
  .leftbottom-text,
  .righttop-text,
  .rightbottom-text,
  .rightcenter-text {
    width: 100%;
    position: absolute;
  }

  .center-text {
    text-align: center;

    h1,
    p {
      width: 100%;
    }
  }

  .leftbottom-text,
  .lefttop-text,
  .rightbottom-text,
  .rightcenter-text {
    margin: 20px;
    width: 80%;
  }

  .leftbottom-text {
    bottom: 0;
  }

  .lefttop-text {
    top: 0;
  }

  .righttop-text {
    top: 0;
    left: 50%;
  }

  .rightbottom-text {
    bottom: 0;
    right: 0;
    text-align: right;
  }

  .rightcenter-text {
    right: 0;
  }

  .absolute-centered,
  .absolute-centered-y {
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
  }

  .absolute-centered {
    left: 50%;
    transform: translateX(-50%);
  }

  .absolute-centered-y {
    top: 50%;
    transform: translateY(-50%);
  }

  .left-block {
    float: left;
  }

  .right-block {
    float: right;
  }

  .overlay-img {
    position: absolute;

    &.rightbottom-img {
      bottom: 0;
      right: 0;
    }
  }

  .button-learnmore {
    p {
      font-size: 10px;
      line-height: 18px;
      display: inline;
    }

    .arrow {
      font-size: 10px;
      margin-left: 3px;
    }
  }

  .background,
  .image-block {
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
}

.home-page-bottom {
  .home-bottom-center {
    padding-top: 135px;
    position: relative;

    &.home-bottom-nooverlay {
      padding-top: 92px;
    }
  }

  .home-bottom-top {
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 60px;
  }

  .home-bottom-leftColumn {
    float: left;
    width: 32.1%;

    .home-bottom-leftTop,
    .home-bottom-leftBottom {
      padding: 0;
    }

    .home-bottom-leftTop {
      padding-bottom: 10px;
    }
  }

  .home-bottom-rightColumn {
    float: right;
    width: 67.4%;

    .home-page-bottom-asset {
      padding-left: 0.5%;

      .background img,
      .text-container {
        margin-left: .25%;
      }
    }
  }

  .home-bottom-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0 50px 0;
  }

  .home-page-bottom-asset {
    position: relative;

    sup {
      top: -3px;
      left: 4px;
      font-size: 60%;
    }

    .border-yellow-top,
    .border-yellow-bottom {
      height: 1px;
      width: 100%;
    }

    .image-block {
      position: relative;

      img {
        vertical-align: bottom;
      }
    }

    .linkblock {
      background-color: rgba(176, 176, 176, 0.2);
      padding: 10px;

      p {
        font-size: 14px;
      }
    }
  }
}

.home-page-bottom-redesign2 {
  margin-top: 20px;
  margin-bottom: 44px;

  .homepage-static-hero,
  .home-page-bottom-asset {
    sup {
      top: 0;
      left: 0;
      font-size: 100%;
    }

    .linkblock {
      padding: 10px 10px 6px 10px;
    }
  }

  .lefttop-text {
    margin: 28px;
  }

  .home-bottom-row2 {
    flex-wrap: wrap;
    padding: 10px 0 0 0;

    .linkblock {
      min-height: 33px;
    }

    .linkblock-text {
      p {
        line-height: 1.2em;
      }
    }

    .button-learnmore {
      p {
        line-height: 1.2em;
      }
    }

    .image-block {
      img {
        vertical-align: bottom;
      }
    }

    .home-bottom-bottomLeft {
      width: 57%;
    }

    .home-bottom-bottomMiddle {
      width: 20%;
    }

    .home-bottom-bottomRight {
      width: 20%;
    }
  }

  .home-bottom-row3 {
    width: 100%;
    margin-top: 30px;
  }

  .home-bottom-row4 {
    margin-top: 25px;

    .home-bottom-bottomLeft,
    .home-bottom-bottomRight {
      width: 49.5%;
    }

    .home-bottom-bottomLeft {
      float: left;
    }

    .home-bottom-bottomRight {
      float: right;
    }
  }
}

.home-page-bottom-redesign3 {
  .home-bottom-leftColumn {
    position: absolute;
    bottom: 34%;

    .home-bottom-leftTop {
      padding-bottom: 15px;
    }
  }

  .home-bottom-top {
    left: 0;
  }

  .home-bottom-bottom {
    .linkblock {
      min-height: 33px;
      padding: 10px 10px 6px;
    }

    .button-learnmore,
    .linkblock-text {
      p {
        line-height: 1.2em;
      }
    }
  }
}

.underneath-slider-slot {
  margin-top: -18px;
  text-align: center;

  img {
    margin-left: -8px;
  }
}

.home-middle-slots {
  .textOverlayWrapper {
    a {
      text-decoration: none;
    }

    .promoTextHeadline {
      letter-spacing: 2px;
      line-height: 1.2em;
      bottom: 80px;
      border-bottom: 0px;
    }

    .promoTextContent {
      white-space: nowrap;
      bottom: 2px;
      line-height: 1em;
    }

    .positionPromoText {
      position: relative;
      margin-top: -77px;
      bottom: 15px;
    }
  }
}

/*Tablet*/
@media (max-width: @tablet-max) {
  .page-wrapper {
    min-width: 0;
  }

  #wrapper {
    width: 100vw;
    margin-top: 0;
  }

  #homepage-slider-container {
    #homepage-flexslider {
      height: auto !important;

      .desktop-image {
        img {
          width: 100%;
        }
      }
    }
  }

  .underneath-slider-slot {
    padding: 0 23px;

    img {
      width: 100%;
      margin-left: 0;
    }
  }

  #home-page-bottom {
    #leftColumn,
    #rightColumn {
      width: 50%;

      div[class^="home-bottom-"] {
        a {
          text-decoration: none;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    #leftColumn {
      div[class^="home-bottom-"] {
        margin-left: 25px;
        margin-right: 12.5px;
      }
    }

    #rightColumn {
      div[class^="home-bottom-"] {
        margin-right: 25px;
        margin-left: 12.5px;
      }
    }

    #pcf_machine-washa-text-1 {
      font-size: 13px;
      top: 35%;
      right: 4%;
      line-height: 1.2em;
    }

    #companyStoryText {
      font-size: 20px;
      top: 24px;
    }

    #weRockText {
      font-size: 10px;
      top: 38px;
    }

    #pcf_down-text-1 {
      top: 40%;
      left: 10%;
      font-size: 25px;
    }

    #pcf_hyperclean-text-1,
    #pcf_hyperclean-text-2,
    #pcf_hyperclean-text-3 {
      font-size: 16px;
      left: 50%;
    }

    #pcf_hyperclean-text-1 {
      top: 18%;
    }

    #pcf_hyperclean-text-2 {
      font-size: 18px;
      top: 33%;
    }

    #pcf_hyperclean-text-3 {
      top: 53%;
    }
  }
}

/*Tablet Only*/
@media (min-width: @tablet-min) and (max-width: @tablet-max) {
  .home-middle-slots {
    padding: 12px 10px 0 30px;

    .home-middle-left,
    .home-middle-center,
    .home-middle-right {
      padding: 0;
      width: 33.33%;

      .contentWrapper {
        padding-right: 20px;
      }

      img {
        width: 100%;
      }
    }

    .textOverlayWrapper {
      .positionPromoText {
        margin-top: -20.5%;
        padding-left: 14px;
      }

      .promoTextHeadline {
        font-size: 15px;
        line-height: 1.7em;
      }

      .promoTextContent {
        font-size: 14px;
      }
    }
  }

  .home-bottom {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 30px 30px 30px;

    img {
      width: 100%;
    }
  }

  .homepage-static-hero {
    .background {
      margin-top: 42px;

      img {
        margin-left: .25%;
        width: 100%;
      }
    }
  }

  .homepage-static-hero,
  .home-page-bottom-asset {
    h2 {
      font-size: 22px;
    }

    p {
      font-size: 13px;
    }

    .button-learnmore {
      .arrow {
        font-size: 8px;
        margin-left: 1px;
      }
    }
  }

  .home-page-bottom {
    padding: 0 30px;
    margin-bottom: 0;

    .background,
    .image-block {
      img {
        width: 100%;
      }
    }

    .home-page-bottom-asset {
      .linkblock {
        padding: 8px;

        p {
          font-size: 12px;
        }
      }

      .leftbottom-text,
      .lefttop-text,
      .rightbottom-text {
        margin: 8%;
      }
    }

    .home-bottom-top {
      margin-top: 20px;
    }

    .home-bottom-center {
      padding-top: 125px;
    }

    .home-bottom-leftColumn {
      width: 31.69%;
    }

    .home-bottom-bottom {
      padding: 10px 0 30px;

      .home-bottom-bottomLeft,
      .home-bottom-bottomMiddle {
        padding-right: 10px;
      }
    }
  }

  .home-page-bottom-redesign2 {
    margin-top: 10px;

    .home-bottom-row2 {
      padding: 5px 0 0 0;

      .linkblock {
        min-height: 30px;
      }
    }

    .home-bottom-row3 {
      margin-top: 20px;
    }

    .home-bottom-row4 {
      margin-top: 16px;
    }
  }

  .home-page-bottom-redesign3 {
    .home-page-bottom-asset {
      .linkblock {
        padding: 8px 8px 5px 8px;
      }
    }

    .home-bottom-top {
      margin-top: 12px;
    }

    .home-bottom-center {
      padding-top: 112px;
    }

    .home-bottom-bottom {
      .linkblock {
        min-height: 30px;
      }
    }

    .home-bottom-leftColumn {
      bottom: 34.5%;
    }
  }
}

/*Mobile Only*/
@media (max-width: @mobile-max) {
  .mobile-top {
    padding-top: 70px
  }

  #wrapper {
    &.pt_storefront {
      #main {
        padding: 5px 9px 0 9px;
      }
    }
  }

  #homepage-slider-container {

    .flex-direction-nav {
      display: none;
    }

    img {
      width: 100%;
    }

    .homepage-static-hero {
      img.mobile-hide {
        display: none;
      }
    }
  }

  #homepage-flexslider {
    width: 100%;

    .mobile-image {
      display: block;

      img {
        width: 100%;
      }
    }

    .desktop-image {
      display: none !important;
    }
  }

  .pt_storefront {
    #home-page-bottom {
      #leftColumn {
        display: none;
      }

      #rightColumn {
        .home-bottom-rightTop,
        .home-bottom-rightMiddle,
        .home-bottom-rightBottom {
          display: block;
          padding: 0px;
          margin: 5px 0;
        }
      }

      .home-bottom {
        display: none;
      }
    }
  }

  .home-middle-slots {
    display: none;

    .textOverlayWrapper {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      .positionPromoText {
        margin-top: -56px;
        padding-left: 18px;
      }

      .promoTextHeadline {
        font-size: 20px;
      }

      .promoTextContent {
        font-size: 18px;
      }
    }
  }

  .mobileMenu-Homepage {
    ul.level-1 {
      padding: 20px 0;

      a {
        font-size: 1.6em;
      }
    }
  }

  .homepage-static-hero,
  .home-page-bottom-asset {
    h2 {
      font-size: 30px;
    }

    .script-heading {
      font-size: 54px;
    }

    .center-text {
      width: 100vw;

      h1 {
        max-width: 90vw;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .text-container {
      margin: 20px 0;
    }

    .button-learnmore {
      p {
        font-size: 13px;
      }
    }
  }

  .home-page-bottom {
    .background {
      background-size: 100% 100%;
    }

    .home-bottom-leftColumn {
      .home-bottom-leftTop {
        display: none;
      }
    }

    .home-bottom-leftColumn,
    .home-bottom-rightColumn {
      float: none;
      width: 100%;

      .home-page-bottom-asset {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .home-bottom-top {
      position: relative;
      margin: 20px 0;
    }

    .home-bottom-center {
      padding-top: 50px;
    }

    .home-bottom-bottom {
      display: none;
    }
  }

  .home-page-bottom-redesign2 {
    margin-top: 0;

    .home-page-bottom-asset {
      .linkblock {
        p {
          font-size: 12px;
        }
      }
    }

    .home-bottom-row2 {
      display: flex;
      padding-top: 12px;

      .home-bottom-bottomLeft {
        order: 1;
        width: 100%;

        .home-page-bottom-asset {
          .linkblock {
            position: absolute;
            background-color: transparent;
          }
        }
      }

      .home-bottom-bottomMiddle,
      .home-bottom-bottomRight {
        width: 43%;
        margin-top: 12px;

        .center-text {
          left: 0;
        }

        .linkblock {
          .linkblock-text {
            padding: 1px 0 2px 0;
          }
        }

        .image-block {
          display: flex;

          img {
            height: 100%;
          }
        }
      }

      .home-bottom-bottomMiddle {
        order: 3;
        padding-left: 5%;
      }

      .home-bottom-bottomRight {
        order: 4;
        padding-right: 5%;
      }

      .linkblock {
        .arrow {
          display: none;
        }
      }
    }

    .home-bottom-row3 {
      order: 2;
      width: 100%;
      position: relative;
      margin-top: 12px;
    }

    .home-bottom-row4 {
      margin-top: 12px;

      .home-bottom-bottomLeft,
      .home-bottom-bottomRight {
        float: none;
        width: 100%;
      }

      .home-bottom-bottomRight {
        margin-bottom: 10px;
      }
    }
  }

  .home-page-bottom-redesign3 {
    margin-top: 8px;

    .home-bottom-center {
      padding-top: 24px;
    }

    .home-bottom-bottom {
      display: block;
      padding: 12px 0 50px;

      .home-page-bottom-asset {
        .linkblock {
          p {
            font-size: 12px;
          }
        }
      }

      .home-bottom-top {
        margin: 12px 0 12px 0;
      }

      .home-bottom-bottomMiddle,
      .home-bottom-bottomRight {
        width: 43%;
      }

      .home-bottom-bottomMiddle {
        padding-left: 5%;
        float: left;
      }

      .home-bottom-bottomRight {
        padding-right: 5%;
        float: right;
      }

      .home-bottom-bottomLeft {
        .linkblock {
          display: none;
        }
      }

      .linkblock {
        .arrow {
          display: none;
        }
      }
    }

    .home-bottom-leftColumn {
      position: relative;
      display: flex;
      flex-wrap: wrap;

      .home-bottom-leftTop {
        width: 100%;
        display: block;
        padding-bottom: 0;
        order: 2;
      }

      .home-bottom-leftBottom {
        width: 100%;
        padding: 12px 0;
        order: 1;
      }
    }
  }
}

/*Tablet Up*/
@media (min-width: @tablet-min) {
  #homepage-flexslider {
    height: 400px;

    .mobile-image {
      display: none !important;
    }

    .desktop-image {
      display: block;
    }
  }
}

@media (max-width: 500px) {
  #homepage-slider-container {
    .banner {
      top: 22px;
      right: 11%;
    }

    .banner-heading {
      font-size: 45px;
    }

    .banner-title {
      font-size: 31px;
      padding-left: 30px;
    }

    .banner-callout {
      font-size: 14px;
      padding-left: 30px;
    }
  }
}

.home-primary {
  text-align: center;
}

.home-center {
  background-color: @white;
  @media (min-width: 769px) {
    padding: 0 40px 40px;
  }

  h2 span {
    float: none;
  }

  #home-center {
    @media (min-width: 769px) {
      position: relative;
    }
  }

  .home-center-grid {
    display: flex;
    flex-direction: column;
    padding: 0 10px 35px;
    @media (min-width: 769px) {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-evenly;
      height: 1300px;
      margin: 0 auto;
      padding: 0;
    }
  }

  .home-center-item {
    padding: 10px 0;
    margin: 0 auto;
    width: 100%;
    @media (min-width: 769px) and (max-width: 1024px) {
      max-width: 205px;
    }
    @media (min-width: 1025px) {
      max-width: 285px;
    }

    &:nth-child(3) {
      @media (min-width: 769px) and (max-width: 1024px) {
        max-width: 280px;
      }
      @media (min-width: 1025px) {
        max-width: 360px;
      }
    }

    &:nth-child(4) {
      @media (min-width: 769px) and (max-width: 1024px) {
        max-width: 280px;
      }
      @media (min-width: 1025px) {
        max-width: 360px;
      }
    }
  }

  .home-center-bottom {
    text-align: center;
  }

  .button-holder {
    width: 100%;
    text-align: center;
    @media (min-width: 769px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -100px;
    }
  }

  .tile-title {
    order: 1;
    padding: 0 30px 30px;
    @media (min-width: 769px) {
      padding: 10px 30px 0;
      order: 0;
    }
  }

  p {
    order: 3;
    padding: 0 30px 30px;
    @media (min-width: 769px) {
      order: 0;
      margin: 0;
      padding: 20px 30px;
    }
  }

  button {
    order: 4;
    @media (min-width: 769px) {
      order: 0;
    }
  }

  img {
    order: 2;
    width: 100%;
    @media (min-width: 769px) {
      order: 0;
    }
  }
}

#home-primary {
  display: flex;
  justify-content: center;
}

.home-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div {
    max-width: 150px;
    padding: 10px;

    @media (min-width: @desktop-min) {
      max-width: 330px;
    }
  }
}
