/*Header Styles*/



.site-header {
	display: grid;
	gap: 0 1em;
	grid-template-columns: 1fr;
	grid-template-rows: 50px 50px;
	grid-template-areas: "row-one"
						 "row-two";
}

.debug .header-item {
	outline: 1px dashed lightgray;
}


.site-header__banner {
	display: none;
    padding: 4px 15px;
}

.site-header__banner div,
.site-header__banner p {
    display: inline;
}

.site-logo {
	align-items: center;
	display: grid;
	grid-row: row-two;
	justify-content: center;
	text-align: center;
}
	.site-logo .site-logo__home-link {
		align-items: center;
		display: flex;
		height: 100%;
		padding-inline: 2em;
	}

.nav-main {
	align-items: center;
	display: none;
	text-align: center;
}

.nav-utility {
	grid-row: row-one;
}
	.nav-utility .menu-account {
		display: none;
	}

@media (min-width: 1024px) {

	.site-header {
		gap: 0 1em;
		grid-template-columns: 372px auto;
		grid-template-rows: 45px 45px 60px;
		grid-template-areas: "banner banner"
							 "logo utility"
							 "nav nav";
	}

	.site-logo {
		grid-area: logo;
		justify-content: start;
	}

	.site-header__banner {
		background-color: var(--color-accent);
		display: block;
		grid-area: banner;
	}

	.nav-main {
		display: grid;
		grid-area: nav;
		justify-content: center;
		padding-inline: 30px;
	}

		.nav-main > ul.menu-category {
			max-width: 1000px;
		}

	.nav-utility {
		align-items: center;;
		display: flex;
		grid-area: utility;
		justify-content: flex-end;
	}
	
		.nav-utility .menu-account {
			display: block;
			width: 60px;
		}
		.nav-utility .menu-account a.menu-trigger:hover img,
		.nav-utility .menu-account a.menu-trigger:focus img,
		.nav-utility .menu-account:hover a.menu-trigger img,
		.nav-utility .menu-account:focus-within a.menu-trigger img {
			background-color: black;
		}
		.nav-utility .menu-account a.menu-trigger.active img {
			background-color: inherit;
		}
			.menu-account a.menu-trigger {
				padding: 1em 2em 1em 1em;
                position: relative;
			}
            .menu-account a.menu-trigger::after {
                content: "";
                border: 4px solid transparent;
                border-top-color: #444;
                display: block;
                box-sizing: border-box;
                position: absolute;
                left: 33px;
                top: 16px;
            }
            .menu-account:hover a.menu-trigger::after {
                border-top-color: transparent;
                border-bottom-color: #444;
                top: 12px;
            }
            .menu-account a.menu-trigger img {
                min-width: 16px;
            }
			.menu-account .menu-utility {
				background: white;
				display: block;
				height: 0;
				margin-inline-start: -100px;
				overflow: hidden;
				position: absolute;
				width: 0;
				z-index: 1000;
			}
			.menu-account a:hover + .menu-utility,
			.menu-account a:focus + .menu-utility {
				border: 1px solid black;
				display: block;
				height: fit-content;
				margin-top: 6px;
				padding: 1em;
				transition: 0.25s height;
				width: 170px;
			}
			.menu-account .menu-utility:focus-within,
			.menu-account .menu-utility:hover {
				border: 1px solid black;
				display: block;
				height: fit-content;
				margin-top: 6px;
				padding: 1em;
				width: 170px;
			}

		.nav-utility .minicart {
			padding-inline-end: 1em;
		}

		.mini-cart-total {
			white-space: nowrap;
		}


}


@media (min-width: 1600px) {

	.site-header {
		grid-template-columns: 1fr min-content 1fr;
		grid-template-rows: 45px 100px;
		grid-template-areas: "banner banner banner"
							 "logo nav utility";
	}

	.site-logo {
		grid-area: logo;
		justify-content: end;
	}

	.site-header__banner {
		display: block;
		grid-area: banner;
	}

	.nav-main {
		grid-area: nav;
	}

	.nav-utility {
		grid-area: utility;
		justify-content: start;
		padding-inline: 1em;
	}

}




.header-promo-box a {
    color: @blue;
	text-decoration: none;

    &:hover {
        color: @gray;
    }
}